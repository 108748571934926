<app-infinite-scroll
    (scrolled)="scroll($event)"
    [countInvisibleElement]="5"
    [scaleAllow]="scaleAllow"
>
    <div
      class="infinity-scroll-decorator"
      [ngClass]="{'special-page white-theme': layoutTheme?.is_special_page}"
    >
        <div *ngIf="activatedRoute.snapshot.data.name === 'tag' && tagName" class="tag_name">#{{ tagName }}</div>
        <div *ngIf="layoutTheme?.is_special_page" class="category">
            <span>{{ getCategory() }}</span>
        </div>
        <div *ngIf="layoutTheme?.is_special_page && hasSubtitle()" class="subtitle">{{getSubtitle()}}</div>
        <div *ngIf="layoutTheme?.is_special_page && hasLead()" class="lead">{{getLead()}}</div>
        <a *ngIf='layoutTheme?.is_special_page && getLinkLogo()' [href]='getLinkLogo()'>
          <img *ngIf="hasLogo()" [src]="getLogo()" [class]="'logo logo-' + getLogoClass()">
        </a>
        <img *ngIf="layoutTheme?.is_special_page && hasLogo() && !getLinkLogo()" [src]="getLogo()" [class]="'logo logo-' + getLogoClass()">
        <app-theme
            *ngFor="let item of layoutData; let i = index"
            [class]="['infinity', 'infinity-' + i]"
            [class.spec-project-page]="layoutTheme?.is_special_page"
            [layoutData]="item"
            [themeIndex]="i"
            [layoutTheme]="layoutTheme"
            [currentRouter]="currentRouter"
        ></app-theme>
    </div>
    <div *ngIf='loading' class='loader-wrapper' [class]='{"loader-wrapper": true, "loader-wrapper-post": currentRouter === "post"}'>
      <img src='/assets/images/spinner.gif' width='40' height='40' alt='loading'>
    </div>
    <p  *ngIf="layoutData.length === 0 && activatedRoute.snapshot.data.name === 'search' && !loading"
        class="request-empty">
        Ничего не найдено
    </p>
</app-infinite-scroll>
