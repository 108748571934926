import {AfterViewInit, Directive, ElementRef} from '@angular/core';
import {EmbedEnum} from '@thebell/common/models/embeded';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appEmbedPluginEngine]',
})
export class EmbedPluginEngineDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private detectedScreenChangeService: DetectedScreenChangeService) {}

  ngAfterViewInit(): void {
    this.getEmbedded();
  }

  private getEmbedded() {
    const embedded = this.elementRef.nativeElement.querySelectorAll('[embed]');
    const embeddedTypes = new Set();
    const twitterBlocks = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < embedded.length; ++i) {
      const item = embedded[i];
      if (this.detectedScreenChangeService.getScreenType() === 'mobile') {
        const iframe = item.querySelector('iframe');
        iframe.setAttribute('width', 'auto');
      }
      const type = item.getAttribute('type');
      if (type === EmbedEnum.twitter) {
        twitterBlocks.push(item.firstElementChild);
      }
      embeddedTypes.add(type);
    }
    this.runScript(embeddedTypes, twitterBlocks);
  }

  private runScript(embeddedTypes, twitterBlocks) {
    for (const type of embeddedTypes) {
      switch (type) {
        case EmbedEnum.twitter:
          if (typeof window !== 'undefined' && (window as any).twttr) {
            twitterBlocks.forEach((el) => {
              const id = el.getAttribute('postid');
              const requested = el.getAttribute('requested');
              if (id && !requested) {
                el.setAttribute('requested', 'true');
                (window as any).twttr?.widgets.createTweet(id, el as HTMLElement);
              }
            });
          }
          break;
        // case EmbedEnum.facebook:
        //   if (typeof window !== 'undefined' && (window as any).FB) {
        //     (window as any).FB.init({
        //       xfbml: true,
        //       version: 'v10.0',
        //     });
        //   }
        //   break;
        // case EmbedEnum.instagram:
        //   if (typeof window !== 'undefined' && (window as any).instgrm) {
        //     (window as any).instgrm.Embeds.process();
        //   }
        //   break;
      }
    }
  }
}
