import {Pipe, PipeTransform} from '@angular/core';

/* [IZO] Для преобразования урла оригинала в кропнутый варинат
 *
 * Пример:
 *
 * https://thebell.itsumma.ru/storage_v2/images/074/66e/07466e0a-5239-4882-b63d-700af7be938b.png
 *
 * ->
 *
 * https://thebell.itsumma.ru/storage_v2/images/074/66e/07466e0a-5239-4882-b63d-700af7be938b_320_0.png */
@Pipe({
  name: 'imageCrop',
})
export class ImageCropPipe implements PipeTransform {
  transform(value: string, width: number, height: number): unknown {
    const url = new URL(value);
    const [path, ext] = url.pathname.split('.');
    if (ext === 'svg') {
      return value;
    }
    url.pathname = `${path}_${width}_${height}.${ext}`;
    return url;
  }
}
