<div *ngIf="contentType && show" class="content" [class]="contentType">
  <div (click)="onClose()" class="close" [class]="contentType">
    <svg width="15" height="15" fill="none" stroke="black" opacity="0.5">
      <use xlink:href="/assets/images/icons.svg#cross"></use>
    </svg>
  </div>
  <div [ngSwitch]="contentType">
 <app-subscription-content *ngSwitchCase="'subscription'" (hide)="onHide()"></app-subscription-content>
    <app-poll-content *ngSwitchCase="'poll'" (hide)="onHide()"></app-poll-content>
    <app-sticky-banner (close)='onClose()' *ngSwitchCase="'banner'"></app-sticky-banner>
  </div>
</div>
