<a
    *ngIf="type !== 'external_post'"
    class="grid-container"
    [routerLink]="inAdmin() ? [] : [isVstrechiBellclub ? '/vstrechi-bellclub' : '/', slug]"
    (click)="onClick()"
>
    <div [class]="textClass">{{ title }}</div>
</a>

<a *ngIf="type === 'external_post'" class="grid-container" [attr.href]="inAdmin() ? null : slug" (click)="onClick()">
    <div [class]="textClass">{{ title }}</div>
</a>
