<div class="post-wrap">
  <div class="post">
    <app-inoagent-banner [post]="data.data.content"></app-inoagent-banner>
    <div
      class="post_content textarena-container"
      [class.newsletter]="isNewsletter"
      appRunScripts
      appPostAd
      appEmbedPluginEngine
      appGalleryPluginEngine
      appIncutParser
      appPostInnerAdvertising
      postDonateBanner
      [no_ad]="no_ad"
      [open]="true"
      [html]="data.data.content | postDonateBanner | async"
      [widget_style_data]="data.data.widget_style_data"
      #innerHTML
    >
      <img src="" alt="" />
    </div>
    <!--<div *ngIf='quiz'>
            <app-quiz [quiz]='quiz' [quizSlug]='data.data.slug'></app-quiz>
        </div>-->
    <div class="post_bottom" [class.newsletter]="isNewsletter" *ngIf="!quiz">
      <div *ngIf="data.data.tags" class="post_tags">
        <a *ngFor="let tag of tags" routerLink="/tag/{{ tag.slug }}" class="post_tags_item">#{{ tag.title }}</a>
      </div>
      <div class="post_share">
        <div class="post_share_links">
          <a
            class="post_share_copy"
            [cdkCopyToClipboard]="currentUrl"
            title="Скопировать ссылку в буфер обмена"
            (click)="onClickCopy()"
          >
            Скопировать ссылку
          </a>
          <app-share-buttons [postSlug]="data.data.slug" [text]="data.data.subtitle"></app-share-buttons>
        </div>
      </div>
    </div>

<!--    <div class="post_subscribe" *ngIf="isNewsletter">-->
<!--      <div class="post_subscribe_shadow"></div>-->
<!--      <div class="post_subscribe_cross" (click)="hide()"></div>-->
<!--      <div class="post_subscribe_title">-->
<!--        <img src="assets/images/logo.svg" />-->
<!--      </div>-->
<!--      <div class="post_subscribe_subtitle">-->
<!--        Подпишитесь на email-рассылку The Bell и узнайте, что стоит за новостями. Без цензуры, в самом удобном формате.-->
<!--        Или читайте наши письма в-->
<!--        <a href="https://t.me/thebell_io" target="_blank" (click)="onGoTelegram()">Telegram</a>.-->
<!--      </div>-->
<!--      <div class="post_subscribe_block">-->
<!--      <a-->
<!--        class="post_subscribe_link"-->
<!--        href="/newsletters"-->
<!--        target="_blank"-->
<!--      >Подписаться</a>-->
<!--      </div>-->

<!--      <form class="post_subscribe_form" [formGroup]="subscriptionForm">-->
<!--        <input placeholder="E-mail" type="email" name="email" (keyup.enter)="openModal()" formControlName="email" />-->
<!--        <app-button-->
<!--          buttonClass="default subscribe_form"-->
<!--          [class.disabled]="subscriptionForm.invalid"-->
<!--          [disabled]="subscriptionForm.invalid"-->
<!--          buttonText="Подписаться"-->
<!--          (buttonClick)="openModal()"-->
<!--        ></app-button>-->
<!--      </form>-->
<!--    </div>-->
  </div>
</div>
