import {gql} from 'apollo-angular';

export const SET_CLIENT_AUTH = gql`
  mutation loginClient($credentials: Credentials!) {
    loginClient(credentials: $credentials) {
      access_token
      expires_in
    }
  }
`

export const SET_CLIENT_AD_KEY = gql`
  mutation getClientAd($credentials: Credentials!) {
    getClientAd(credentials: $credentials) {
      ad_key
      expires_in
    }
  }
`

export const SET_CLIENT_PRO_KEY = gql`
  mutation getClientPro($credentials: Credentials!) {
    getClientPro(credentials: $credentials) {
      pro_key
      expires_in
    }
  }
`
