import {Injectable} from '@angular/core';
import {Asset} from '@thebell/common/models/asset';

@Injectable({
  providedIn: 'root',
})
export class SrcSetService {
  readonly sizes = {
    'mobile-s': 320,
    'mobile-m': 375,
    'mobile-x': 480,
    'tablet-s': 640,
    'tablet-m': 768,
    'tablet-x': 1024,
    desktop: 1280,
  };

  readonly parser;

  constructor() {
    this.parser = new DOMParser();
  }

  public toNginxSizes(htmlContent: Document | HTMLElement | string, assets: Asset[]): Document {
    const html = typeof htmlContent === 'string' ? this.parser.parseFromString(htmlContent, 'text/html') : htmlContent;
    const images = html.querySelectorAll('img');
    const sources = html.querySelectorAll('source')
    if(sources.length) return html
    images.forEach((image) => {
      const src = image.getAttribute('src');
      if (assets) {
        const assetVersion = assets.find((asset) => asset.src === this.getOriginalSrc(src))?.version || 1;
        if (assetVersion > 1) return;
      }
      let srcset = '';
      // eslint-disable-next-line guard-for-in
      for (const alias in this.sizes) {
        srcset += `${src}?w=${alias} ${this.sizes[alias]}w, `;
      }

      image.setAttribute('srcset', srcset);
    });

    return html;
  }

  private getOriginalSrc(src: string): string {
    const regex = /^(.*)_[0-9]+_[0-9]+.(.*)/;
    if (!regex.test(src)) return src;
    const [, name, ext] = src.match(regex);
    return `${name}.${ext}`;
  }
}
