import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss'],
})
export class SubscriptionDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {email: string},
    public dialogRef: MatDialogRef<SubscriptionDialogComponent>
  ) {}
}
