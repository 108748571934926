import {BaseWidget} from '../base';
import {Asset} from '@thebell/common/models/asset';
import {additionalCategory} from '@thebell/common/models/additional-category';

export class News extends BaseWidget {
  title: string;
  background: string;
  background_image_id: number; // asset.id
  background_image: Asset;
  background_assets: Asset[];
  main_image_as_background = false;
  // eslint-disable-next-line id-blacklist
  number?: string;
  text?: string;
  author?: string;
  author_description?: string;
  quote?: string;
  category: any;
  type: string;
  additionalCategory?: additionalCategory;
  advertising?: boolean;
  advertising_image?: Asset;
  advertising_link?: string;
  advertising_image_id?: string;
}
