<div *ngIf="layoutTheme?.items.length > 0">
    <ng-container *ngIf="needStylize()" >
        <div class="category">
            <span>{{getCategory()}}</span>
        </div>
        <div class='flex-header'>
            <div *ngIf="hasSubtitle()" class="title">
                <a (click)="readMoreSpecClick()" [routerLink]="getHeaderLink()">
                    {{getSubtitle()}}
                </a>
            </div>
            <a *ngIf='getLinkLogo()' [routerLink]='getLinkLogo()' aria-label="Logo">
                <img *ngIf="hasLogo()" [src]="getLogo()" [class]="'logo logo-' + getLogoClass()" alt="">
            </a>
            <img *ngIf="hasLogo() && !getLinkLogo()" [src]="getLogo()" [class]="'logo logo-' + getLogoClass()" alt="">
        </div>
    </ng-container>
    <div *ngIf="isCollapses(); else elseBlock">
        <app-line
            [lineIndex]="1"
            [layoutTheme]="layoutTheme"
            [lineData]="longLineData"
            [themeIndex]="themeIndex"
            [themeItem]="layoutTheme.items[0]"
            [isOverflowXScroll]="true"
            [class]="'overflowXScroll'"
            [currentRouter]="currentRouter"
        ></app-line>
    </div>
    <ng-template #elseBlock>
        <ng-template ngFor [ngForOf]="layoutTheme.items" let-item let-i="index">
            <div [ngSwitch]="item.model"
                 [ngClass]="['index-' + i]"
                 [ngStyle]="styling(item)"
                 class="flex-growable"
            >
                <app-line
                    [lineIndex]="i"
                    [layoutTheme]="layoutTheme"
                    *ngSwitchCase="'LayoutLine'"
                    [lineData]="getLineData(item.layout_theme_layout_theme_item_id)"
                    [themeItem]="item"
                    [themeIndex]="themeIndex"
                    [currentRouter]="currentRouter"
                ></app-line>
                <div *ngSwitchCase="'LayoutThemes'" class="themes-container" [class]="item.weight">
                    <ng-template ngFor [ngForOf]="item.item" let-theme let-j="index">
                        <app-theme
                            [lineIndex]="i"
                            [layoutData]="getLineData(theme.layout_theme_layout_theme_item_id)"
                            [themeIndex]="themeIndex"
                            [layoutTheme]="theme"
                            [ngStyle]="styling(theme)"
                            class="flexible"
                            [class.single-in-line]="item.item.length === 1"
                            [ngClass]="{'styled white-theme': theme.style?.enabled}"
                        ></app-theme>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </ng-template>
    <div *ngIf="needStylize() && hasLink()" class="read-more_block">
        <a (click)="readMoreSpecClick()" [href]="getLink()" class="read-more_link">Читать ещe
            <span class="read-more_link_arrow">
              <svg width="9" height="17" fill="none">
                <use xlink:href="/assets/images/icons.svg#chevron-right"></use>
              </svg>
            </span>
        </a>
    </div>
</div>
