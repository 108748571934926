<div [class.post-quiz]="isQuiz" [class]="'post-page post-page-id-' + data.id" #post>
  <div class="post" [class.new-style]="isNewStyle()" appOnScreen [data]="data" [first]="isFirstPost">
    <div class="wrapper" [class.open]="isOpen">
      <div class="post_top" [class.with-lead]="leadImg?.innerHTML" #postTop>
        <div class="post_categories">
          <div class="post_category" *ngFor="let category of categories">
            <a [routerLink]="'/category/' + category.slug">{{ category.title }}</a>
          </div>
          <div
            class="post_category post_category__red"
            *ngIf="additionalCategoryResolver(additionalCategory) === 'tag'"
          >
            <span class="link exclusive">{{ getText(additionalCategory) }}</span>
          </div>
          <a
            *ngIf="additionalCategoryResolver(additionalCategory) === 'asset'"
            [href]="getLink(additionalCategory)"
            class="additional-category"
          >
            <img [src]="getImgPath(additionalCategory)" />
          </a>
        </div>
        <h1 class="post_title">{{ title }}</h1>
        <div class="post_date_and_source_wrapper">
          <div class="post_date">{{ date }}</div>
          <div *ngIf="source" class="post_source">
            <span class="post_source__boilerplate">Источник:&nbsp;</span>
            <a *ngIf="source.text !== 'The Bell'; else noLink" target="_blank" href="{{ source_link }}" class="post_source__text">{{ source?.text }}</a>
            <ng-template #noLink>
              <p class="post_source__text"> {{ source?.text }}</p>
            </ng-template>
          </div>
        </div>
        <div class="post_authors" *ngIf="isOpen && !isQuiz">
          <app-widget-author
            *ngFor="let author of authors"
            [author]="author"
            [showEmail]="true"
            [showDescription]="false"
            [nameClass]="'name name_small'"
            [emailClass]="'email email_small'"
          ></app-widget-author>
          <!--
            [avatarClass]="'author__content_image author__content_image_small'"
            [imageResizable]="authorAvatarIsResizable"
            -->
        </div>
        <div class="textLead" *ngIf="leadText && !isOpen" [innerHTML]="leadText.innerHTML"></div>
        <div #placeForButton class="place-for-button" *ngIf="!isOpen">
          <app-button
            (buttonClick)="open()"
            [spanClass]="'read_more_text'"
            [buttonText]="'Читать дальше'"
            [buttonClass]="'default read_more'"
          >
            <svg width="16" height="9" fill="none" style="margin-left: 5px">
              <use xlink:href="/assets/images/icons.svg#chevron-down"></use>
            </svg>
          </app-button>
        </div>
      </div>
      <div class="lead" *ngIf="leadImg && !isOpen" [innerHTML]="leadImg.innerHTML"></div>
    </div>
    <ng-container #outlet *ngComponentOutlet="postComponent; injector: postInjector"></ng-container>
    <div class="ad_addfox_under_post" [id]="'ad_addfox_under_post_' + uuid"></div>
  </div>
  <div class="ad sticky-wrapper">
    <div
      class="sticky"
      *ngIf="!no_ad"
      [isOpen]="isOpen"
      [uuid]="uuid"
      [adFoxEl]="ad_sidebar"
      [zenEl]="widget_container"
      [smi2El]="ads_1"
      [needAdsSub]="postAdsNeeded"
      appPostAds
    >
      <div [id]="'ad-sidebar-' + uuid" #ad_sidebar></div>
      <!-- <div class='ads-1' #ads_1></div> -->
      <div [class]="['widget-container', 'widget-container-' + uuid]" #widget_container></div>
      <app-ad-thetruestory *ngIf="isOpen"></app-ad-thetruestory>
    </div>
  </div>
</div>
