import {ConsentWidgetConfig} from '../consent-widget';
import {Controller} from './controller';
import {InjectionToken} from '@angular/core';

export const PRIVACY_SCRIPT_MANAGER_CONFIG: InjectionToken<ManagerConfig> = new InjectionToken<ManagerConfig>(
  'PrivacyScriptManagerConfig'
);

export interface ManagerConfig {
  consentWidget: ConsentWidgetConfig | any;
  scripts: Controller[];
}

export interface ConsentChangeEvent {
  changedCategories?: string[];
}
