import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageCropPipe} from '../pipes/image-crop.pipe';

@NgModule({
  declarations: [ImageCropPipe],
  imports: [CommonModule],
  exports: [ImageCropPipe],
})
export class SimplePipesModule {}
