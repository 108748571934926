import {Component, Input} from '@angular/core';
import {HelperService} from '@thebell/common/services/utils/helper';

@Component({
  selector: 'app-widget-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyComponent {
  @Input() basis: string;
  constructor(private helperService: HelperService) {}

  inAdmin = () => this.helperService.inAdmin();
}
