import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentryService } from './sentry.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    SentryService
  ]
})
export class SentryModule { }
