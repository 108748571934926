import {AfterViewInit, Directive, ElementRef, Input, ViewChild, ViewContainerRef} from '@angular/core';

import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Directive({
  selector: '[appPostInnerAdvertising]',
})
export class PostInnerAdvertisingDirective implements AfterViewInit {
  @Input() widget_style_data;
  constructor(private el: ElementRef) {}
  ngAfterViewInit() {
    if (!(this.widget_style_data.advertising && this.widget_style_data.advertising_image)) return;
    const leadImg = this.getLastSnippetArticlePreview();
    if (!leadImg) return;
    const html = `<div  class = "advertising-wrapper inner">
      <p>На правах рекламы</p>
      <a target="_blank" href='${this.widget_style_data.advertising_link || '#'}'>
       <div class='logo-wrapper'><img src=${this.widget_style_data.advertising_image.src || this.widget_style_data.advertising_image}></div>
      </a>
    </div>`;
    const prevElement = leadImg.previousElementSibling;
    if (prevElement) {
      prevElement.insertAdjacentHTML('beforeend', `${html}`);
    }
  }

  isTextArenaPost(html: Document) {
    return !!html.querySelector('.textarena-container');
  }

  /* isTextArenaPost(html: Document) {
    return !!html.querySelector('.textarena-container');
  }*/

  isNewStyle(html: Document) {
    const newStyle = html.querySelector('.stk-theme_42625');
    return !!newStyle;
  }

  private getLastSnippetArticlePreview() {
    const html = this.el.nativeElement;
    // Забираю только картинку, без лида будет ещё и текст
    return html.querySelector('figure.lead') || html.querySelector('.end-article-preview .lead');
  }
}
