import {Deserializable} from '@thebell/common/models/deserializable';

export class SubscriptionData extends Deserializable {
  source: string;
  email: string;
  newsletterTypes: NewsletterType;
}

export class NewsletterTypes {
  all: boolean;
  morning: boolean;
  weekly: boolean;
  evening: boolean;
  tech: boolean;
  en: boolean;
}

export interface NewsletterType {
  all?: boolean;
  morning?: boolean;
  weekly?: boolean;
  evening?: boolean;
  tech?: boolean;
  en?: boolean;
}

export class LastNewsletterUrls extends Deserializable {
  all: string;
  morning: string;
  weekly: string;
  evening: string;
  tech: string;
  en: string;
}
