import {Category, PrivacyConsents} from '../contracts/types';
import {SimpleThirdParty} from './simple-third-party';

declare global {
  interface Window {
    dataLayer: undefined | any[];
    gtag: undefined | any;
  }
}

export class GoogleAnalytics extends SimpleThirdParty {
  constructor() {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag =
        window.gtag ||
        function () {
          window.dataLayer.push(arguments);
        };
    }

    super('gtm', [Category.Analytics, Category.Ads], null);
  }

  init(consents: PrivacyConsents) {
    window.gtag('consent', 'update', this.makeConsents(consents));
    // window.console.debug('consent', 'current', this.makeConsents(consents));
  }

  onUpdatePrivacyConsents(consents: PrivacyConsents) {
    window.gtag('consent', 'update', this.makeConsents(consents));
    // window.console.debug('consent', 'update', this.makeConsents(consents));
  }

  private makeConsents(consents: PrivacyConsents): Record<string, string> {
    return {
      ad_storage: consents.ads ? 'granted' : 'denied',
      ad_user_data: consents.ads ? 'granted' : 'denied',
      ad_personalization: consents.ads ? 'granted' : 'denied',
      analytics_storage: consents.analytics ? 'granted' : 'denied',
      functionality_storage: consents.analytics ? 'granted' : 'denied',
      personalization_storage: consents.analytics ? 'granted' : 'denied',
      security_storage: 'granted', // necessary
    };
  }
}
