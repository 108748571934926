<div class="author">
    <div class="author__content">
        <!-- <div [class]="avatarClass" *ngIf="avatars?.length">
            <a [routerLink]="['/author', author.slug]">
                <app-image
                    [imageClass]="'author-photo'"
                    [image]="avatars"
                    imageType="background"
                    [isResizable]="imageResizable"
                ></app-image>
            </a>
        </div> -->
        <div class="author__content_contacts">
            <a [class]="nameClass" [routerLink]="['/author', author.slug]">{{ author.name_ru }}</a>
            <div [class]="emailClass" *ngIf="showEmail">
                <a href="mailTo:{{ email }}">{{ email }}</a>
            </div>
            <div class="social_media">
                <a class="image" [href]="i[1]" target="_blank" *ngFor="let i of author.contacts | socialIconsFilter">
                    <app-author-social [slug]='i[0]'></app-author-social>
                </a>
            </div>
        </div>
    </div>
    <div class="description" *ngIf="showDescription">
        <span [innerHTML]="description_html"><a href=""></a></span>
    </div>
</div>
