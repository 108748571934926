import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  HostListener,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {Subject} from 'rxjs';
import {HelperService} from '@thebell/common/services/utils/helper';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';

interface IAnchoredElement {
  anchorClass: string;
  element: Element;
}

interface IIntersectedElement {
  anchorClass: string;
  entry: IntersectionObserverEntry;
}

/*
 * Компонент отвечает за подзагрузку новых постов
 * Следим за количеством просмотренных постов
 * Смотрим, сколько остлось непросмотренных
 * Эмитим событие, что нужно загрузить новые темы с постами
 * */

@Component({
  selector: 'app-infinite-scroll',
  template: `
    <div #infinite_scroll class="infinite-scroll" [ngStyle]="style">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./infinite-scroll.component.scss'],
})
export class InfiniteScrollComponent implements OnInit, AfterViewInit {
  @Input() countInvisibleElement;
  @Input() scaleAllow: boolean;
  @Output() scrolled = new EventEmitter<number>();
  @Output() scrolledPosition = new EventEmitter<string>();
  @ViewChild('infinite_scroll', {static: false}) root: ElementRef;

  style = {} as {[klass: string]: any};
  private anchoringClass = 'infinity';
  private isLoading = true;
  private intersectionObserver: IntersectionObserver;
  private mutationObserver: MutationObserver;
  private subjectObserverElements = new Subject<IAnchoredElement[]>();
  private oldHeight: number;
  private thresholdPart = 0.25;
  private intersectionOptions = {
    root: null,
    threshold: [0.05, 0.1, 0.15, 0.2, 0.25],
  };
  private anchoringElements: IAnchoredElement[] = [];
  private intersectedElements: IIntersectedElement[] = [];
  private currentTopAnchor: IIntersectedElement;
  constructor(
    private detectedScreenChangeService: DetectedScreenChangeService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    private helperService: HelperService
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  initObservableEvents() {
    this.addIntersectionObserver();
    this.addIntersectionObserverElements();
    this.addMutationObserver();
    this.setObservableElements();
    this.isLoading = false;
  }

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    if (this.scaleAllow) {
      this.setStyle(window.innerWidth);
    }
    if (this.countInvisibleElement === 0) return;
    this.detectedScreenChangeService.windowResize.subscribe((a) => {
      if (this.scaleAllow) {
        this.setStyle(a.target.innerWidth);
      }
      this.setRootHeightAfterScale(true); // +
    });

    this.helperService.getFromHeap('currentRoute').subscribe((currentRoute) => {
      if (currentRoute) {
        switch (currentRoute.snapshot.data.name) {
          case 'post':
            this.anchoringClass = 'post-page';
            this.countInvisibleElement = 3;
            break;
          default:
            break;
        }
        this.addIntersectionObserver();
        this.addIntersectionObserverElements();
        this.addMutationObserver();
      }
    });
  }

  // на случай, если обсервер так и не получит инфу о том, что надо грузить новые посты
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const element = document.documentElement;
    const currentScroll = Math.floor(element.scrollHeight - element.scrollTop);
    if (currentScroll <= 10 + element.clientHeight) {
      if (!this.isLoading) this.needMoreElements(this.countInvisibleElement);
    }
  }
  /*
   * Ищем все блоки с классом anchoringClass
   */
  setObservableElements() {
    this.anchoringElements = [];
    [...this.root.nativeElement.querySelectorAll('.' + this.anchoringClass)].map((element) => {
      const anchorClass = [...element.classList].find(
        // чтобы блок был anchoring надо чтоб classList включал "anchoringClass anchoringClass${some}"
        (className) => className !== this.anchoringClass && className.includes(this.anchoringClass)
      );
      if (anchorClass) this.anchoringElements.push({anchorClass, element});
    });
    this.subjectObserverElements.next(this.anchoringElements);
  }

  setRootHeightAfterScale(isScrollEvent = true) {
    const windowWight = window.innerWidth;
    if (windowWight >= 768 && windowWight < 1024) {
      if (isScrollEvent) this.style.height = 'min-content';
      const contentHeight: number = Math.round(this.root.nativeElement.getBoundingClientRect().height);
      const offsetHeight: number = this.root.nativeElement.offsetHeight;
      if (this.oldHeight === undefined || this.oldHeight !== offsetHeight) {
        this.style.height = contentHeight + 'px';
        this.oldHeight = contentHeight;
      }
    }
  }
  /*
   * Тут наблюдаем за всеми постами и темами из setObservableElements()
   */
  addIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.map((entry) => {
        const anchorClass = entry.target.classList.value
          .split(' ')
          // чтобы блок был anchoring надо чтоб classList включал "anchoringClass anchoringClass${some}"
          .find((className) => className !== this.anchoringClass && className.includes(this.anchoringClass));
        if (anchorClass) {
          let isBlockViewed = false;
          const entryTop = entry.boundingClientRect.top;
          const entryBottom = entry.boundingClientRect.bottom;
          const viewedHeight = window.innerHeight / 2;
          if (entry.intersectionRatio >= this.thresholdPart) {
            isBlockViewed = true;
          } else if (
            (entryTop < viewedHeight && entryTop >= 0) ||
            (entryBottom > viewedHeight && entryBottom <= window.innerHeight)
          ) {
            isBlockViewed = true;
          }
          if (isBlockViewed) {
            // если блок на экране и ещё не находится intersectedElements
            if (!this.intersectedElements.find((iE) => iE.anchorClass === anchorClass)) {
              this.intersectedElements.push({anchorClass, entry});
            }
            // если блок пропадает с экарана, удаляем его из intersectedElements
          } else {
            this.intersectedElements = this.intersectedElements.filter((iE) => {
              return iE.anchorClass !== anchorClass;
            });
          }
        }
      });
      // ищем самый верхний блок, который сейчас на экране
      let topEl;
      for (const anchorClass of this.anchoringElements.map((aE) => aE.anchorClass)) {
        if (topEl === undefined) {
          topEl = this.intersectedElements.find((iE) => iE.anchorClass === anchorClass);
        }
      }
      // ищем самый нижний блок, который сейчас на экране
      let bottomEl;
      for (const anchorClass of this.anchoringElements.map((aE) => aE.anchorClass).reverse()) {
        if (bottomEl === undefined) {
          bottomEl = this.intersectedElements.find((iE) => iE.anchorClass === anchorClass);
        }
      }
      // Загружаем, если непросмотреных !постов! осталось меньше, чем countInvisibleElement
      if (!this.isLoading && bottomEl) {
        const currentNotViewedCount =
          this.anchoringElements.length -
          (this.anchoringElements.map((aE) => aE.anchorClass).indexOf(bottomEl.anchorClass) + 1);
        if (currentNotViewedCount < this.countInvisibleElement) {
          this.needMoreElements(this.countInvisibleElement - currentNotViewedCount);
        }
      }
      // тут отрпавляем самый верхний блок
      // исключаем рефернс блоки, отправляем только посты
      if (
        topEl &&
        this.currentTopAnchor?.anchorClass !== topEl.anchorClass &&
        !topEl.anchorClass.includes('refernce')
      ) {
        this.currentTopAnchor = topEl;
        const id = topEl.anchorClass.split('-').reverse()[0];
        this.scrolledPosition.emit(id);
      }
    }, this.intersectionOptions);
  }

  addMutationObserver() {
    this.mutationObserver = new MutationObserver((records) => {
      this.isLoading = false;
      const childListChanged = undefined !== records.find((record) => record.type === 'childList');
      if (childListChanged) {
        this.setObservableElements();
      }
      this.setRootHeightAfterScale(childListChanged);
    });

    let root = this.root.nativeElement;
    if (root.children[0]?.className.includes('infinity-scroll-decorator')) {
      root = root.children[0];
    }

    this.mutationObserver.disconnect();
    this.mutationObserver.observe(root, {
      childList: true,
      attributes: true,
    });
    this.isLoading = false;
  }

  addIntersectionObserverElements() {
    this.subjectObserverElements.subscribe((observerElements) => {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
        observerElements.map((observerElement) => {
          this.intersectionObserver.observe(observerElement.element);
        });
      }
    });
  }

  needMoreElements(count) {
    this.isLoading = true;
    this.scrolled.emit(count);
  }

  setStyle(elemWidth) {
    let scale = 1;
    if (elemWidth >= 768 && elemWidth < 1024) {
      // вычитаются падинги класса wrap
      scale = Math.ceil(((elemWidth - 32) / 992) * 10000) / 10000;
    }
    this.style = {
      transform: `scale(${scale})`,
      transformOrigin: 'left top',
      width: `calc(100% / ${scale})`,
    };
  }
}
