import {Post} from '@thebell/common/models/post';
import {WidgetStyle} from '@thebell/common/models/widget-style';
import {Deserializable} from '@thebell/common/models/deserializable';
import {WidgetTypes} from '@thebell/common/models/widget-types';
import {WidgetComponent} from '@thebell/common/ui/widgets';

export class LayoutThemeData extends Deserializable {
  item: Post;
  item_model: string;
  widget_style: WidgetStyle;
  widget_style_data: WidgetTypes = {};
  position: number;
  component: WidgetComponent;
}
