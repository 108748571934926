import * as Sentry from '@sentry/angular';
import {ExtraErrorData as ExtraErrorDataIntegration} from '@sentry/integrations';

export interface ISentryConfig {
  dsn: string;
  tracingOrigins: string;
}

export const sentryInit = (config: ISentryConfig) => {
  Sentry.init({
    dsn: config.dsn,
    attachStacktrace: true,
    integrations: [
      new Sentry.BrowserTracing({
        beforeNavigate: (context) => {
          return {
            ...context,
            name: location.pathname.replace(/\/[a-f0-9]{32}/g, '/<hash>').replace(/\/\d+/g, '/<digits>'),
          };
        },
        tracePropagationTargets: [config.tracingOrigins], routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new ExtraErrorDataIntegration({depth: 10}),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
        stickySession: true,
      }),
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 11,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
