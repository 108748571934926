import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable, of, Subject} from 'rxjs';
import {Deserializable} from '@thebell/common/models/deserializable';
import {HelperService} from '@thebell/common/services/utils/helper';
import {LayoutThemeItem} from '@thebell/common/models/layout-theme-item';
import {DialogComponent} from '@thebell/common/ui/utils';
import {ApiAdminService} from '@thebell/admin/services/api/api-admin';
import {tap} from 'rxjs/operators';

class Selected extends Deserializable {
  element: any;
  item: any;
  modelName: string;
}

@Injectable({
  providedIn: 'root',
})
export class ManagePresetsService {
  public subject = new Subject<any>();
  public intentionSwapLine = new Subject<any>();
  public intentionDeleteLine = new Subject<any>();
  public intentionCheckDataActive = new Subject<any>();
  public updateTheme = new Subject<any>();
  public selected: Selected = null;

  constructor(
    private helperService: HelperService,
    private apiAdminService: ApiAdminService,
    private dialog: MatDialog
  ) {}

  get($event: any, item, modelName) {
    let element;
    let data;
    for (const elem of $event.path) {
      if (elem.className && elem.className.split(' ').includes('preset-item')) {
        element = elem;
        data = {
          element,
          item,
          modelName,
        };
        if (this.selected !== null && element.id === this.selected.element.id) {
          this.selected = null;
          return;
        } else {
          this.setSelected(data);
          return;
        }
      }
    }
    if (this.selected === null) {
      this.setSelected(data);
      return;
    }
  }

  set(widgetItem, layoutThemeLayoutThemeItemId) {
    if (this.selected === null) {
      return;
    }
    if (!this.helperService.inAdmin()) {
      return;
    }
    if (this.selected === null) {
      return;
    }
    if (!this.helperService.inAdmin()) {
      return;
    }
    this.subject.next({
      layout_preset_id: widgetItem.data.layout_preset_id,
      fixed_model_id: this.selected.item.id,
      fixed_model_name: this.selected.modelName,
      layout_theme_layout_theme_item_id: layoutThemeLayoutThemeItemId,
      position: widgetItem.data.position,
      repeatable: 0,
    });
  }

  setSelected(data: any) {
    this.selected = new Selected().deserialize(data);
  }

  detachThemeItemFromTheme(lineIndex: number, themeItem: LayoutThemeItem): void {
    this.openDialog(themeItem)
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.apiAdminService
            .removeLayoutThemeLayoutThemeItemId(themeItem.layout_theme_layout_theme_item_id)
            .subscribe((res) => {
              if (res.status === 'ok') {
                this.updateTheme.next({});
                // TODO   решить проблему с пересчётом индекса сортировки (line.sort)
                // this.intentionDeleteLine.next({lineIndex});
              }
            });
        }
      });
  }

  repeatablePresetItem(item): void {
    const params = {id: item.data.preset_item_id, repeatable: item.data.repeatable ? 0 : 1};
    this.apiAdminService.repeatablePresetItem(params).subscribe((res) => {
      item.data.repeatable = params.repeatable;
      this.intentionCheckDataActive.next({});
    });
  }

  detachPresetItem(item, layoutThemeLayoutThemeItemId): void {
    this.openDialogDetachPreset()
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.apiAdminService.detachPresetItem(item.data.preset_item_id).subscribe((res) => {
            this.updateTheme.next({});
          });
        }
      });
  }

  toggleRepeatable(themeItem: LayoutThemeItem): void {
    this.apiAdminService
      .toggleLayoutThemeLayoutThemeItemRepeatable(themeItem.layout_theme_layout_theme_item_id)
      .subscribe((res) => {
        themeItem.repeatable = !themeItem.repeatable;
        this.intentionCheckDataActive.next({});
      });
  }

  toggleSmall(themeItem: LayoutThemeItem): void {
    this.apiAdminService
      .toggleLayoutThemeLayoutThemeItemSmall(themeItem.layout_theme_layout_theme_item_id)
      .subscribe((res) => {
        themeItem.small = !themeItem.small;
        this.intentionCheckDataActive.next({});
      });
  }

  openDialog(themeItem: LayoutThemeItem): MatDialogRef<DialogComponent> {
    let text = '';
    switch (themeItem.model) {
      case 'LayoutLine':
        text = 'Вы уверены, что хотите убрать эту линию из темы?';
        break;
      case 'LayoutTheme':
        text = 'Вы уверены, что хотите удалить эту вложенную тему?';
        break;
    }
    return this.dialog.open(DialogComponent, {
      width: '220px',
      data: text,
    });
  }

  openDialogDetachPreset(): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      width: '220px',
      data: 'Вы уверены что хотите открепить этот элемент?',
    });
  }

  openDialogResetAdminData(): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      width: '220px',
      data: 'Вы уверены что хотите сбросить все изменения?',
    });
  }

  swapLine(aIndex: number, bIndex: number) {
    this.intentionSwapLine.next({aIndex, bIndex});
  }

  update() {
    this.updateTheme.next({});
  }
}
