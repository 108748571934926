<div
  *ngIf="image"
  [ngClass]="imageClass"
  appImageSrc
  (setSrcSet)="setSrcSet($event)"
  (setImgSrc)="setImgSrc($event)"
  [img]="image"
  [addition]="addition"
  [isResizable]="isResizable"
  [type]="imageType"
>
  <picture
    appLazyImg
    *ngIf="(imageType.includes('background') || imageType === 'image') && !imageType.includes('color') && srcSetData"
  >
    <ng-container *ngFor="let item of srcSetData; let i = index">
      <source [srcset]="item.image" [media]="item.media" [class]="imageClass" />
    </ng-container>
    <img class="picture-box" [src]="defaultSrc" alt="" [class]="imageClass" />
  </picture>
  <img [width]="width" [height]="height" *ngIf="imageType === 'image' && (width && height)" [src]="imgSrc" alt="" />
  <img *ngIf="imageType === 'image' && (!width || !height)" [src]="imgSrc" alt="" />
  <svg *ngIf="imageType === 'svg'">
    <linearGradient id="inst_gradient" x1="50%" y1="50%" x2="-50%" y2="-50%" gradientTransform="rotate(-90)">
      <stop offset="8.15%" stop-color="#5832E2" />
      <stop offset="51.8%" stop-color="#E24A8C" />
      <stop offset="98.27%" stop-color="#FFAC26" />
    </linearGradient>
    <use [attr.xlink:href]="imgSrc"></use>
  </svg>
  <div *ngIf="imageType.includes('shadow')" class="shadow"></div>
</div>
