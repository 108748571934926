import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateConverterService {
  /**
   *
   *
   * Получаем строковое представление относительного времени
   *
   *
   * @param type - час | минута
   * @param value - значение
   * @param a - до какого числа будет замена цифры словом
   * @returns
   */
  num_word(type: 'h' | 'min', value: number, a = 0) {
    let res;
    // тут числительные записанные словами
    let digits = [];
    // тут склонения числительных
    let words = [];
    if (type === 'h') {
      digits = ['', '', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять', 'десять'];
      words = ['час', 'часа', 'часов'];
    }
    if (type === 'min') {
      digits = ['', '', 'две', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять', 'десять'];
      words = ['минуту', 'минуты', 'минут'];
    }
    if (value === 0) value = 1;
    let current_word = '';
    value = Math.abs(value) % 100;
    const num = value % 10;
    if (value > 10 && value < 20) current_word = words[2];
    else if (num > 1 && num < 5) current_word = words[1];
    else if (num === 1) current_word = words[0];
    else current_word = words[2];

    if (value <= a) {
      res = `${digits[value]} ${current_word} назад`;
    } else res = `${value} ${current_word} назад`;
    return res;
  }
}
