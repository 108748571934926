import {Injectable} from '@angular/core';
import {ApolloError} from '@apollo/client';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {}

  transformErrorFromApi(errors) {
    throw new ApolloError({
      errorMessage: errors[0].message,
      extraInfo: errors[0].extensions.errors,
    });
  }

  parseErrorsFromApi(err) {
    const res = {};
    err.extraInfo.forEach((err) => {
      const key = err.body.property;
      res[key] = Object.values(err.body.constraints).join(' ');
    });
    return res;
  }
}
