import {Inject, Injectable} from '@angular/core';
import {loadScript as _loadScript, getUserPreferences, showPreferences} from 'vanilla-cookieconsent';
import {ConsentChangeEvent, ManagerConfig, PRIVACY_SCRIPT_MANAGER_CONFIG} from '../contracts/manager';
import {Category, PrivacyConsents} from '../contracts/types';
import {Controller} from '../contracts/controller';

@Injectable({
  providedIn: 'root',
})
export class ManagerService {
  private loaded: Set<string> = new Set();

  constructor(@Inject(PRIVACY_SCRIPT_MANAGER_CONFIG) public readonly config: ManagerConfig) {
    this.config.consentWidget.onConsent = this.init.bind(this);

    this.config.consentWidget.onChange = this.onConsentChange.bind(this);
    this.config.consentWidget.onFirstConsent = this.onConsentChange.bind(this);
  }

  public showConsentPreferences() {
    showPreferences();
  }

  protected init(): void {
    const consents: PrivacyConsents = this.getConsents();

    this.config.scripts.forEach((script) => {
      if (this.hasUserConsent(script, consents)) {
        if (script.isConsentResponsive) {
          script.init(consents);
        }

        this.loadScript(script);
      }
    });
  }

  protected onConsentChange(params: ConsentChangeEvent): void {
    const consents: PrivacyConsents = this.getConsents();

    this.config.scripts.forEach((script) => {
      if (params.changedCategories && !script.hasAnyCategory(params.changedCategories)) return;

      if (script.isConsentResponsive) {
        script.onUpdatePrivacyConsents(consents);
      } else {
        this.toggleScript(script, consents);
      }
    });
  }

  protected toggleScript(script: Controller, consents: PrivacyConsents): void {
    if (this.hasUserConsent(script, consents)) {
      this.loadScript(script);
    } else if (this.loaded.has(script.src)) {
      location.reload(); // Перезагружаем страницу, если скрипт уже загружен, но согласие отозвано
    }
  }

  protected loadScript(script: Controller) {
    if (script.src && !this.loaded.has(script.src)) {
      _loadScript(script.src).then((wasLoad) => {
        wasLoad && typeof script.onLoad === 'function' && script.onLoad();
      });

      this.loaded.add(script.src);
    }
  }

  protected getConsents(): PrivacyConsents {
    const acceptedCategories = getUserPreferences().acceptedCategories;

    return Object.values(Category).reduce((consents, category) => {
      consents[category] = acceptedCategories.includes(category);
      return consents;
    }, {} as PrivacyConsents);
  }

  protected hasUserConsent(script: Controller, consents: PrivacyConsents): boolean {
    return script.isConsentResponsive || script.categories.every((category) => consents[category]);
  }
}
