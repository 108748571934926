import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {FormArray, FormBuilder} from '@angular/forms';
import {Quiz, QuizResult, Answer} from '@thebell/common/models/quizzes';
import {AssetRoles} from '@thebell/common/models/asset';
import {QuizService} from '@thebell/common/services/api/quiz';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {Environment, EnvironmentService} from '@thebell/common/services/core/environment';
import {isPlatformBrowser} from '@angular/common';
import * as dayjs from 'dayjs';
import 'dayjs/locale/ru';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('quizTop') quizTop: ElementRef;
  questionIndex = -1;
  questionAnswered = -1;
  points = 0;
  maxPoints = 0;
  result: QuizResult;
  environment: Environment;
  intersectionObserver: IntersectionObserver;
  isQuizViewed = false;

  @Input() started = false;
  @Input() quiz: Quiz;
  @Input() quizSlug = '';
  @Output() updatedQuiz = new EventEmitter<Quiz>();

  quizForm = this.fb.group({
    questions: this.fb.array([]),
  });

  quizFormUrl: SafeResourceUrl;

  socials = [
    {icon: 'vk', href: ''},
    {icon: 'twitter', href: ''},
    {icon: 'odnoklassniki', href: ''},
  ];

  constructor(
    private quizService: QuizService,
    private fb: FormBuilder,
    private envService: EnvironmentService,
    public sanitizer: DomSanitizer,
    public detectedScreenChangeService: DetectedScreenChangeService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>
  ) {
    this.environment = this.envService.getEnvironment();
    if (this.environment.production) {
      this.quizFormUrl = sanitizer.bypassSecurityTrustResourceUrl('https://www.abbyy.com/ru/the-bell-form/');
    } else {
      this.quizFormUrl = sanitizer.bypassSecurityTrustResourceUrl('https://www.abbyy.com/ru-ru/test/the-bell-form/');
    }
  }

  ngOnInit(): void {
    this.maxPoints = this.getMaxPoints();
    const shareUrl = this.environment.baseUrl + this.quizSlug;
    const title = this.quiz.title;
    const description = this.quiz.description;

    this.socials = [
      {
        icon: 'vk',
        href: 'https://vk.com/share.php?url=' + shareUrl + '&title=' + title,
      },
      {
        icon: 'twitter',
        href: 'http://twitter.com/share?&url=' + shareUrl + '&text=' + title,
      },
      {
        icon: 'odnoklassniki',
        href: 'https://connect.ok.ru/offer?url=' + shareUrl + '&title=' + title + '&description=' + description,
      },
    ];
    if (this.started) {
      // setTimeout(this.startQuiz.bind(this), 200);
      this.startQuiz(false);
    }
  }

  ngAfterViewInit() {
    if (this.quiz.id === 9) {
      this.createObservable();
    }
  }

  private createObservable() {
    if (isPlatformBrowser(this.platformId)) {
      this.intersectionObserver = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && !this.isQuizViewed) {
          ga('send', 'event', 'quiz', 'view', this.quiz.id);
          dataLayer.push({
            'event': 'event', 'eventCategory': 'quiz', 'eventAction': 'view', 'eventLabel': this.quiz.id
          });
          this.isQuizViewed = true;
        }
      });
      this.intersectionObserver.observe(this.quizTop.nativeElement);
    }
  }

  private unsubscribeObservable() {
    if (isPlatformBrowser(this.platformId)) {
      this.intersectionObserver && this.intersectionObserver.disconnect();
    }
  }

  ngOnDestroy() {
    if (this.quiz.id === 9) {
      this.unsubscribeObservable();
    }
  }

  getDate() {
    return dayjs(this.quiz.created_at).locale('ru').format('D MMMM YYYY');
  }

  getCoverImage() {
    const asset = this.quiz.assets.find((elem) => elem.role === AssetRoles.quiz_cover);
    let coverImage = '';
    if (asset) {
      if (asset.src) {
        coverImage = asset.src;
      } else if (asset.path) {
        coverImage = this.environment.baseUrl + asset.path;
      }
    }
    return coverImage;
  }

  getAdditionalImage() {
    const asset = this.quiz.assets.find((elem) => elem.role === AssetRoles.quiz_additional);
    let addImage = '';
    if (asset) {
      if (asset.src) {
        addImage = asset.src;
      } else if (asset.path) {
        addImage = this.environment.baseUrl + asset.path;
      }
    }
    return addImage;
  }

  getResultImage() {
    const asset = this.result.assets.find((elem) => elem.role === AssetRoles.quiz_cover);
    let resultImage = '';
    if (asset) {
      if (asset.src) {
        resultImage = asset.src;
      } else if (asset.path) {
        resultImage = this.environment.baseUrl + asset.path;
      }
    }
    return resultImage;
  }

  setQuizForm() {
    this.quizForm = this.fb.group({
      questions: this.fb.array([]),
    });
    if (this.quiz.questions.length > 0) {
      this.quiz.questions.map((question) => {
        // eslint-disable-next-line
        const variants = this.fb.array([]);
        question.variants.map((variant) => {
          variants.push(
            this.fb.group({
              id: [''],
              variant: [''],
              weight: [0],
              comment: [''],
              recommendation: [''],
              selected: [false],
            })
          );
        });
        this.questions.push(
          this.fb.group({
            id: [''],
            question: [''],
            multiple: [],
            required: [],
            variants,
          })
        );
      });
    }
    this.quizForm.patchValue(this.quiz);
  }

  get questions() {
    return this.quizForm.get('questions') as FormArray;
  }

  getVariants(question) {
    return question.get('variants') as FormArray;
  }

  startQuiz(scroll = true) {
    if (scroll) {
      this.quizTop.nativeElement.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
    this.setQuizForm();
    localStorage.removeItem('quiz_' + this.quiz.id);
    this.questionIndex = 0;
    this.points = 0;
  }

  respondToQuestion(index) {
    // #918
    if (this.quiz.id === 9 && this.questionIndex === 0) {
      ga('send', 'event', 'quiz', 'start', this.quiz.id);
      dataLayer.push({
        'event': 'event', 'eventCategory': 'quiz', 'eventAction': 'start', 'eventLabel': this.quiz.id
      });
    }

    this.quizTop.nativeElement.scrollIntoView({block: 'start', behavior: 'smooth'});
    this.questionAnswered = index;
  }

  checkMultipleAnswer(variants) {
    let result = false;
    variants.map((variant) => {
      result = result || variant.selected;
    });
    return result;
  }

  nextQuestion(index) {
    this.quizTop.nativeElement.scrollIntoView({block: 'start', behavior: 'smooth'});
    this.questionIndex = index + 1;
    if (this.questionIndex === this.quiz.questions.length) {
      this.countPoints();
      this.setResult();
      this.saveAnswers();
    }
  }

  countPoints() {
    this.points = 0;
    this.questions.value.map((question) => {
      question.variants.map((variant) => {
        if (variant.selected) this.points += variant.weight;
      });
    });
  }

  setResult() {
    let resMin;
    let resMax;
    this.quiz.results.map((result) => {
      if (this.points >= result.point_min && this.points <= result.point_max) {
        if (!resMax || (resMax && resMin <= result.point_min && resMax <= result.point_max)) {
          resMin = result.point_min;
          resMax = result.point_max;
          this.result = result;
        }
      }
    });
  }

  getMaxPoints() {
    let max = 0;
    this.quiz.questions.map((question) => {
      if (question.multiple) {
        max += question.variants.map((item) => (item.weight > 0 ? item.weight : 0)).reduce((a, b) => a + b);
      } else {
        max += Math.max.apply(
          null,
          question.variants.map((item) => item.weight)
        );
      }
    });
    return max;
  }

  saveAnswers() {
    const answers = this.aggregateAnswers();
    this.quizService.saveQuizAnswers(this.quiz.id, {points: this.points, answers}).subscribe(
      (res) => {
        // localStorage.setItem('quiz_' + res.quiz.id, String(res.report.id));
        this.updatedQuiz.emit(res.quiz);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  aggregateAnswers() {
    // eslint-disable-next-line
    const answers: Answer[] = [];
    this.questions.value.map((question) => {
      // eslint-disable-next-line
      const answer = new Answer();
      answer.question_id = question.id;
      answer.answer_ids = [];
      question.variants.map((variant) => {
        if (variant.selected) {
          answer.answer_ids.push(variant.id);
        }
      });
      answers.push(answer);
    });
    return answers;
  }
}
