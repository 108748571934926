<div class="additional">
    <div class="additional_top" *ngIf='contentHtml'></div>
    <div
        [class]="
            (content.includes('stk-post') ? ' setka_content' : ' wp_content') +
            (content.includes('stk-theme_37074') ? ' s37074' : '')
        "
        [innerHTML]="contentHtml"
    ></div>
</div>
