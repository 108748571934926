import {Directive, ElementRef, Input, OnChanges, Renderer2} from '@angular/core';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';

@Directive({
  selector: '[appLayoutLineHeight]',
})
export class LayoutLineHeightDirective implements OnChanges {
  @Input() basis: number;
  @Input() data: any;
  @Input() currentRouter = '';
  @Input() layoutTheme?;
  @Input() small?;
  constructor(
    public renderer: Renderer2,
    public hostElement: ElementRef,
    private screenService: DetectedScreenChangeService
  ) {}
  setHeight(): void {
    let height;
    if (this.layoutTheme.type === 2) {
      switch (this.basis) {
        case 100:
          height = 200;
          break;
        case 50:
          height = 250;
          break;
        case 25:
          height = 190;
          break;
        case 33:
          height = 300;
          if (this.small) {
            height = 190;
          }
          break;
        case 0:
        case -Infinity:
          height = 0;
          break;
        default:
          height = 300;
          break;
      }
    } else {
      switch (this.basis) {
        case 100:
          height = 500;
          break;
        case 25:
          height = 175;
          break;
        case 0:
        case -Infinity:
          height = 0;
          break;
        default:
          height = 300;
          break;
      }
    }

    const componentName = (() => {
      try {
        return this.data[0].data.widget_style.slug;
      } catch (err) {
        return '';
      }
    })();
    if (this.data.length === 1) {
      if (componentName === 'LightningComponent') {
        height = 200;
      }
    }
    if (this.data.length > 0 && componentName !== 'PostComponent' && !this.isAdRow()) {
      this.renderer.setStyle(this.hostElement.nativeElement, 'min-height', height + 'px');
    }
    if (this.data.length > 0 && componentName === 'AdvertisementComponent') {
      // eslint-disable-next-line camelcase
      const provider = this.data[0].data?.item?.advertisement_provider_id;
      if (provider === 2) {
        this.renderer.addClass(this.hostElement.nativeElement, 'adfox');
      }
      if (provider === 1) {
        this.renderer.addClass(this.hostElement.nativeElement, 'zen');
      }
      if (this.currentRouter === 'post' && this.isAdRow()) {
        this.renderer.addClass(this.hostElement.nativeElement, 'row-adverts');
      }
    }
    this.renderer.addClass(this.hostElement.nativeElement, 'basis-' + this.basis);
  }

  ngOnChanges(): void {
    this.setHeight();
  }

  // Если все виджеты в линнии рекламные, то линия рекламная
  private isAdRow(): boolean {
    for (let i = 0; this.data.length > i; i++) {
      if (this.data[i].data.widget_style.slug !== 'AdvertisementComponent') {
        return false;
      }
    }
    return true;
  }
}
