<a
    *ngIf="type !== 'external_post'"
    class="grid-container"
    [routerLink]="inAdmin() ? [] : [isVstrechiBellclub ? '/vstrechi-bellclub' : '/', slug]"
    (click)="onClick()"
>
    <app-image
        [imageClass]="'widget-background'"
        [image]="image"
        [imageType]="imageType"
        [isResizable]="false"
        [addition]="{basis:basis}"

    ></app-image>
    <div [class]="categoryClass">цифра</div>
    <div [class]="textClass">
        <div [class]="numberClass">{{ number }}</div>
        <div [class]="innerTextClass">{{ text }}</div>
    </div>
    <div [class]="timeClass">{{ date }}</div>
</a>

<a *ngIf="type === 'external_post'" class="grid-container" [attr.href]="inAdmin() ? null : slug">
    <app-image
        [imageClass]="'widget-background'"
        [image]="image"
        [imageType]="imageType"
        [isResizable]="false"
        [addition]="{basis:basis}"
    ></app-image>
    <div [class]="categoryClass">цифра</div>
    <div [class]="textClass">
        <div [class]="numberClass">{{ number }}</div>
        <div [class]="innerTextClass">{{ text }}</div>
    </div>
    <div [class]="timeClass">{{ date }}</div>
</a>
