export interface IAdditionalCategory {
  name: string;
  svg: string;
  link: string;
}

export interface IAdditionalCategoryAmp extends IAdditionalCategory{
  sizes: {
    width: number;
    height: number;
  }
}

// eslint-disable-next-line no-shadow
export enum additionalCategory {
  bellClub = 'bellClub',
  bellClubLive = 'bellClubLive',
  russiansAreCool = 'russiansAreCool',
  inSchool = 'inSchool',
  exclusive = 'exclusive', //не имеет ассета, дизайн категории
}
