import {GalleryData, SliderData, TypeApproveUrl} from '@thebell/common/models/gallery';

export type Rect = {
  width: number;
  height: number;
};

export type ImgSizeInfo = {
  mediaWidth: number;
  rations: Array<Rect & {ratio: number}>;
};

export const calculatedSrc = (srcset: ImgSizeInfo[] | any, src: string) => {
  const set = new Set();
  srcset.forEach((item) => {
    item.rations.forEach((r) => {
      const res = `${prepareImageSrc(src, r.width, r.height)} ${r.width}w`;
      set.add(res);
    });
  });
  return Array.from(set);
};

export const prepareImageSrc = (src: string, width?: number, height?: number): string => {
  if (!width) {
    return src;
  }
  const arr = src.split('.');
  if (arr.length < 2) {
    return src;
  }
  arr[arr.length - 2] += `_${width}_${height || '0'}`;
  return arr.join('.');
};

export function getLargestOptimazedImageSrc(src: string, sizes: Array<ImgSizeInfo>): string {
  return prepareImageSrc(src, sizes[sizes.length - 1]?.rations[0].width, sizes[sizes.length - 1]?.rations[0].height);
}

export function getSmallestOptimazedImageSrc(src: string): string {
  return prepareImageSrc(src, 64, 64);
}

export function colorResolver(color) {
  if (color === '#FFFFFF') return '#000';
  else return '#fff';
}

export const newImagesUpload = (payload: TypeApproveUrl[], data: GalleryData | null) => {
  if (!data) {
    data = {
      pictures: payload,
      mainId: 0,
      sizes: [],
    };
  } else {
    data.pictures = [...data.pictures, ...payload];
  }
  return data;
};

export const newImageUpload = (payload: TypeApproveUrl, data: GalleryData | null) => {
  if (!data) {
    data = {
      pictures: [payload],
      mainId: 0,
      sizes: [],
    };
  } else {
    data.pictures.push(payload);
  }
  return data;
};

/* export const drawThumbnail = (pic: Picture, sizes: ImgSizeInfo[]): TemplateResult => {
  const thumbnail = html`<img class="thumbnail" src="${getSmallestOptimazedImageSrc(pic.src, sizes)}" />`;
  return thumbnail;
};*/

// Логика скольжения нижнего блока с маленькими картинками
export function moveThumbsLine(sliderData: SliderData, direct: 'l' | 'r', count: number): SliderData {
  if (sliderData.meta.fullyVisible) return sliderData;
  const {shift, delta, slideWidth} = sliderData;
  const shiftDeltaDif = direct === 'r' ? delta - shift : 0 - shift;
  let move = shiftDeltaDif - slideWidth > 1 ? slideWidth : shiftDeltaDif;
  move *= direct === 'l' ? 1 : -1;
  if (direct === 'r') {
    const step = count - sliderData.meta.deadZone.start;
    if (count >= sliderData.meta.deadZone.finish) {
      sliderData.shift = -1 * sliderData.delta;
    } else if (step < 0) {
      sliderData.shift = 0;
    } else if (step > 0) {
      sliderData.shift = step * move;
    }
  } else {
    const step = sliderData.meta.deadZone.finish - count;
    if (count <= sliderData.meta.deadZone.start) {
      sliderData.shift = 0;
    } else if (step < 0) {
      sliderData.shift = -1 * sliderData.delta;
    } else if (step > 0) {
      sliderData.shift = -1 * (sliderData.delta - step * move);
    }
  }
  return sliderData;
}

export function computedValues(root: ShadowRoot, mainId: number, sliderData?: SliderData): SliderData {
  const rowEl = root.querySelector('.bot-blocks-wrapper');
  if (!rowEl) {
    return null;
  }
  const rowWidth = rowEl.clientWidth; // width vidimoy chasti bloka s claiderom
  const slides = rowEl.querySelectorAll('.thumbnail-wrapper');
  const margin =
    slides.length === 1
      ? 0
      : Math.round(slides[1].getBoundingClientRect().left - slides[0].getBoundingClientRect().right);
  const slideQuantity = slides.length; // kol-vo slidov
  const slideWidth = slides[0].clientWidth + margin; // width 1 slaida
  const rowWidthFull = slideQuantity * slideWidth - margin;
  const delta = rowWidthFull - rowWidth;
  const shift = sliderData ? sliderData.shift : 0;
  const visibleSlides = Math.floor(rowWidth / slideWidth);
  const fullyVisible = slideQuantity < rowWidth / slideWidth;
  const middle = rowWidth / slideWidth / 2;
  const _sliderData = {
    delta,
    shift,
    slideWidth,
    slideQuantity,
    visibleSlides,
    meta: {
      fullyVisible,
      middle,
      deadZone: {start: middle, finish: slideQuantity - 1 - middle},
    },
  };
  return moveThumbsLine(_sliderData, 'r', mainId);
}
