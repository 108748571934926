import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InfiniteScrollModule} from '../infinite-scroll';
import {ThemeModule} from '../theme';
import {WidgetScrollerComponent} from './widget-scroller.component';

@NgModule({
  imports: [CommonModule, ThemeModule, InfiniteScrollModule],
  declarations: [WidgetScrollerComponent],
  exports: [WidgetScrollerComponent],
})
export class WidgetScrollerModule {}
