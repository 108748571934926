import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ClientAuthService} from './client-auth.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientAuthGuard implements CanActivate {
  constructor(private auth: ClientAuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (this.auth.isAuthenticated()) {
      return true;
    } else {
      ClientAuthService.logout();
      this.router.navigate(['account/login'], {
        queryParams: {
          loginAgain: true,
        },
      });
      return false
    }
  }
}
