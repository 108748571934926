import {Component, Inject, Optional, PLATFORM_ID} from '@angular/core';
import {RESPONSE, REQUEST} from '@nguniversal/express-engine/tokens';
import {Response} from 'express';
import {isPlatformServer} from '@angular/common';
import {makeStateKey, TransferState} from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(
    @Optional() @Inject(RESPONSE) private response: Response,
    @Inject(PLATFORM_ID) private platformId: any,
    private transferState: TransferState
  ) {
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(makeStateKey('is404'), true as any);
      this.response.statusCode = 404;
    }
  }
}
