import {AfterViewInit, Directive, Inject, Input, PLATFORM_ID, ViewContainerRef} from '@angular/core';
import {PostOnScreenResolverService} from './post-on-screen-resolver.service';
import {IEl, IElOnScreenState} from './types';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';

@Directive({
  selector: '[appOnScreen]',
})
export class OnScreenDirective implements AfterViewInit {
  @Input() data: any;
  // if true init with that
  @Input() first: boolean;
  constructor(
    private vcRef: ViewContainerRef,
    private postOnScreenResolverService: PostOnScreenResolverService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>
  ) {}
  ngAfterViewInit() {
    if (this.first) {
      const el: IElOnScreenState = {
        onScreen: true,
        element: this.vcRef.element.nativeElement as HTMLElement,
        item: this.mapData(),
      };
      this.postOnScreenResolverService.subject.next(el);
    }
    if (isPlatformServer(this.platformId)) return;
    const observedElement = this.vcRef.element.nativeElement.parentElement;
    const observer = new IntersectionObserver(([entry]) => {
      const el: IElOnScreenState = {
        onScreen: entry.isIntersecting,
        element: entry.target as HTMLElement,
        item: this.mapData(),
      };
      this.postOnScreenResolverService.subject.next(el);
    });
    observer.observe(observedElement);
  }

  private mapData(): IEl {
    return {
      id: this.data.id,
      data: {
        slug: this.data.slug,
        published_at: this.data.published_at?.toString(),
        updated_at: this.data.updated_at?.toString(),
        categorySlug: this.data.categories[0]?.slug,
        title: this.data.title,
      },
    };
  }
}
