<div *ngIf="visible" class="content">
    <span class="subtitle">
        Подпишитесь <br>на рассылку The&nbsp;Bell!
    </span>
    <div class="subscription" [formGroup]="subscriptionForm">
        <input
            class="email"
            placeholder="E-mail" type="email" name="email" (keyup.enter)="openModal()" formControlName="email"
            [ngClass]="{warn: subscriptionForm.value.email.invalid && subscriptionForm.value.email.value}"
            required
            minlength="3"
            email
        />
<!--        ToDo переделать ниже. херня какая-то. зачем из 2? почему с класами такое вместо [class.something] -->

        <app-button
            class='no-tablet'
            buttonText="Отправить"
            (buttonClick)="openModal()"
            [disabled]="subscriptionForm.invalid"
            [buttonClass]="subscriptionForm.valid ? 'default subscription-popup subscribe_form' : 'default subscription-popup subscription-disabled'"
        ></app-button>
        <app-button
            class='only-tablet h100'
            buttonText=""
            (buttonClick)="openModal()"
            [disabled]="subscriptionForm.invalid"
            [buttonClass]="subscriptionForm.valid ? 'default h100 subscribe_form center' : 'default h100 subscription-disabled center'"
        >
            <svg width="6" height="14" fill="none" stroke="white">
              <use xlink:href="/assets/images/icons.svg#chevron-right"></use>
            </svg>
        </app-button>
    </div>
</div>
