export const COOKIE_CONSTANTS = {
  BOTTOM_STICKY: 'bottom-sticky',
};

export interface BOTTOM_STICKY_COOKIE {
  subscribe: boolean;
  banner: boolean;
}

export class StickyBannerCookie {
  //private subscribe: boolean;
  private banner: boolean;
  constructor(cookie: string) {
    const parsed = JSON.parse(cookie || null);
    //this.subscribe = parsed?.subscribe;
    this.banner = parsed?.banner;
  }

  show() {
    // if (!this.subscribe && !this.banner) return 'random';
    // else if (this.subscribe && !this.banner) return 'banner';
    // else if (!this.subscribe && this.banner) return 'subscribe';
    // else if (this.subscribe && this.banner) return 'none';
    if (!this.banner) return 'banner';
    else return 'none';
  }

  save(key: 'banner' | 'subscribe') {
    switch (key) {
      // case 'subscribe':
      //   this.subscribe = true;
      //   return;
      case 'banner':
        this.banner = true;
        return;
    }
  }

  toString() {
    return JSON.stringify({banner: this.banner})
    //return JSON.stringify({subscribe: this.subscribe, banner: this.banner})
  }
}
