import {LayoutTheme} from '@thebell/common/models/layout-theme';
import {LayoutLine} from '@thebell/common/models/layout-line';
import {Deserializable} from '@thebell/common/models/deserializable';

export class LayoutThemeItem extends Deserializable {
  model: string;
  layout_theme_layout_theme_item_id: number;
  item: LayoutTheme | LayoutTheme[] | LayoutLine;
  repeatable: boolean;
  small?: boolean;
}
