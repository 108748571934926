import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SimplePipesModule} from '@thebell/frontend/simple-pipes';
import {AdvertisingBoxComponent} from './advertising-box.component';

@NgModule({
  imports: [CommonModule, SimplePipesModule],
  declarations: [AdvertisingBoxComponent],
  exports: [AdvertisingBoxComponent],
})
export class AdvertisingBoxModule {}
