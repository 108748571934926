import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {EnvironmentService} from '@thebell/common/services/core/environment';
import {map} from 'rxjs/operators';
import {SET_CLIENT_AD_KEY, SET_CLIENT_AUTH, SET_CLIENT_PRO_KEY} from './client-auth-query';
import {Observable} from 'rxjs';
import {
  IClientAuthAdKeyResponse,
  IClientAuthProKeyResponse,
  IClientAuthResponse,
  ICredentials,
} from '@thebell/common/models/credentials';

@Injectable({
  providedIn: 'root',
})
export class ClientNestAuthService {
  api: string;
  constructor(private apollo: Apollo, envService: EnvironmentService) {
    const env = envService.getEnvironment();
  }

  auth(credentials: ICredentials): Observable<IClientAuthResponse> {
    return this.apollo
      .mutate<IClientAuthResponse>({
        mutation: SET_CLIENT_AUTH,
        variables: {credentials},
      })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  adKey(credentials: ICredentials): Observable<IClientAuthAdKeyResponse> {
    return this.apollo
      .mutate<IClientAuthAdKeyResponse>({
        mutation: SET_CLIENT_AD_KEY,
        variables: {credentials},
      })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  proKey(credentials: ICredentials): Observable<IClientAuthProKeyResponse> {
    return this.apollo
      .mutate<IClientAuthProKeyResponse>({
        mutation: SET_CLIENT_PRO_KEY,
        variables: {credentials},
      })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }
}
