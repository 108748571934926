import {LayoutPresetItem} from '@thebell/common/models/layout-preset-item';
import {Deserializable} from '@thebell/common/models/deserializable';
import {LayoutTheme} from '@thebell/common/models/layout-theme';

export class LayoutPreset extends Deserializable {
  id: number;
  name: string;
  slug: string;
  route: any;
  description: string;
  layout_theme: LayoutTheme;
  items: LayoutPresetItem[];
}
