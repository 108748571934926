import {Component, OnInit, Output, EventEmitter, Inject, PLATFORM_ID, Renderer2} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {SubscriptionDialogComponent} from '@thebell/common/ui/subscription';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {EnvironmentService} from '@thebell/common/services/core/environment';
@Component({
  selector: 'app-subscription-content',
  templateUrl: './subscription-content.component.html',
  styleUrls: ['./subscription-content.component.scss'],
})
export class SubscriptionContentComponent implements OnInit {
  @Output() hide = new EventEmitter<boolean>();

  visible = false;
  screenType: string;

  subscriptionForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private envService: EnvironmentService,
    private dialog: MatDialog,
    private detectedScreenChangeService: DetectedScreenChangeService,
    private r: Renderer2,
    @Inject(DOCUMENT) private dom: Document,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>
  ) {}

  ngOnInit() {
    const {baseUrl} = this.envService.getEnvironment();
    this.screenType = this.detectedScreenChangeService.getScreenType();
    this.detectedScreenChangeService.screenTypeChanged.subscribe((screenType) => {
      this.screenType = screenType;
    });
    if (!localStorage.getItem('subscription_banner_shown')) {
      ga('send', 'event', 'mail_popup', 'mail_popup_main_page_show');
      dataLayer.push({
        'event': 'event', 'eventCategory': 'mail_popup', 'eventAction': 'mail_popup_main_page_show'
      });
      this.visible = true;
    }
  }

  openModal() {
    if (this.subscriptionForm.valid) {
      this.hide.emit(true);
      ga('send', 'event', 'mail_popup', 'mail_popup_subscribe');
      dataLayer.push({
        'event': 'mail_popup_subscribe', 'category': 'newsletter', 'label': this.subscriptionForm.value.email
      });
      localStorage.setItem('subscription_banner_shown', 'true');
      const dialogRef = this.dialog.open(SubscriptionDialogComponent, {
        data: {email: this.subscriptionForm.value.email},
        height: '100%',
        maxHeight: 'calc(100vh - 100px)',
        maxWidth: 'calc(100vw - 16px)',
        backdropClass: 'subscription-backdrop',
        panelClass: 'subscription-panel',
      });
      this.r.setStyle(this.dom.body, 'overflow-y', 'hidden');

      dialogRef.afterClosed().subscribe(() => {
        this.subscriptionForm.patchValue({email: ''});
        this.r.removeStyle(this.dom.body, 'overflow-y');
      });
    }
  }
}
