<div *ngIf='firstPostLoading' class='loader-wrapper'>
  <img src='/assets/images/spinner.gif' width='40' height='40' alt='loading'>
</div>
<div *ngIf='!firstPostLoading' [ngStyle]="style" class="post-page">
  <div #firstPost style="margin-top: 5px" class="container-block">
    <div *ngIf="post">
      {{ isSubscriber }}
      <app-post-view [post]="post"></app-post-view>
    </div>

  </div>
  <app-widget-scroller [scaleAllow]="false" [load]='next'></app-widget-scroller>
</div>
