import {LayoutThemeItem} from '@thebell/common/models/layout-theme-item';
import {Deserializable} from '@thebell/common/models/deserializable';

export class LayoutTheme extends Deserializable {
  id: number;
  name: string;
  slug: string;
  type: number;
  title: string;
  description: string;
  routes: any;
  items: LayoutThemeItem[];
  isInner = false;
  style: any = {};
  position: number = undefined;
  basis: number = undefined;
  is_special = false;
  is_special_page = false;
  widgetCapacity: {
    first: number;
    others: number;
  };
}
