import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {EnvironmentService} from '@thebell/common/services/core/environment';

@Component({
  selector: 'app-poll-content',
  templateUrl: './poll-content.component.html',
  styleUrls: ['./poll-content.component.scss'],
})
export class PollContentComponent implements OnInit {
  @Output() hide = new EventEmitter<boolean>();

  visible = false;

  constructor(private envService: EnvironmentService) {}

  ngOnInit() {
    const {baseUrl} = this.envService.getEnvironment();
    if (!localStorage.getItem('inschool_sticky_dialog_shown_')) {
      ga('send', 'event', 'popup', 'inschool_popup');
      dataLayer.push({
        'event': 'event', 'eventCategory': 'popup', 'eventAction': 'inschool_popup'
      });
      this.visible = true;
    }
  }

  onButtonClick() {
    this.hide.emit(true);
    ga('send', 'event', 'popup', 'inschool_button');
    dataLayer.push({
      'event': 'event', 'eventCategory': 'popup', 'eventAction': 'inschool_button'
    });
    window &&
      window.open(
        'https://docs.google.com/forms/d/e/1FAIpQLSfhfHY36U6eq4Y-uMDQ2EVEiPhBw6mhPtAqe98XcnPCMjbG3A/viewform'
      );
  }
}
