const ADS_TYPE = {
  ADFOX_FULLSCREEN: {
    DESKTOP: 'adfox_fullscreen_desktop',
    MOBILE: 'adfox_fullscreen_mobile',
  },
};
export class FullscreenAdService {
  constructor() {}
  init(type: 'desktop' | 'mobile') {
    let ad;
    switch (type) {
      case 'desktop':
        ad = ADS_TYPE.ADFOX_FULLSCREEN.DESKTOP;
        break;
      case 'mobile':
        ad = ADS_TYPE.ADFOX_FULLSCREEN.MOBILE;
        break;
      default:
        ad = ADS_TYPE.ADFOX_FULLSCREEN.MOBILE;
    }
    addAdFox(undefined, undefined, ad, 254948);
  }
}
