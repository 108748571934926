import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import stringify from 'safe-stable-stringify';
import * as objectHash from 'object-hash';
import {AuthService} from '@thebell/common/services/api/auth';
import {EnvironmentService} from '@thebell/common/services/core/environment';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  heapSubjects = {};
  heapHash = {};

  constructor(private envService: EnvironmentService, private auth: AuthService, private router: Router) {}

  inAdmin(): boolean {
    return (this.isAdminRoute() && this.auth.isAuthenticated()) || this.router.url.includes('/wp/');
  }

  authorized() {
    return (
      new Date() <= new Date(localStorage.getItem('token-expired')) && localStorage.getItem('access_token') !== null
    );
  }

  isAdminRoute() {
    return !!this.router.config[this.router.config.length - 1]?.data?.admin;
  }

  showLayout() {
    // const {adminLayoutUser} = this.envService.getEnvironment();
    // return (
    //   new Date() <= new Date(localStorage.getItem('token-expired')) &&
    //   localStorage.getItem('laravel_access_token') !== null &&
    //   JSON.parse(localStorage.getItem('currentUser'))?.email === adminLayoutUser
    // );
    return false;
  }

  setInHeap(partName, value): void {
    if (!this.heapSubjects[partName]) {
      this.heapSubjects[partName] = new BehaviorSubject(value);
      this.heapHash[partName] = objectHash.sha1(stringify(value));
      this.heapSubjects[partName].next(value);
    } else {
      if (objectHash.sha1(stringify(value)) !== this.heapHash[partName]) {
        this.heapHash[partName] = objectHash.sha1(stringify(value));
        this.heapSubjects[partName].next(value);
      }
    }
  }

  getFromHeap(partName): BehaviorSubject<any> {
    if (!this.heapSubjects[partName]) {
      this.heapSubjects[partName] = new BehaviorSubject(null);
    }
    return this.heapSubjects[partName];
  }

  isMainPage(url: string) {
    return url === '/' || url.startsWith('/category/') || url.startsWith('/tag/') || url.startsWith('/search/');
  }
}
