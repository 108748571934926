import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Quiz, QuizReport} from '@thebell/common/models/quizzes';
import {BaseModel} from '@thebell/common/models/base-model';
import {Environment, EnvironmentService} from '@thebell/common/services/core/environment';

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  private environment: Environment;
  constructor(private envService: EnvironmentService, private http: HttpClient) {
    this.environment = this.envService.getEnvironment();
  }

  getQuiz(id: number): Observable<Quiz> {
    return this.http.get(`${this.environment.apiUrl}quiz/${id}`).pipe(
      map((res: BaseModel<Quiz>) => {
        return new Quiz().deserialize(res.data);
      })
    );
  }

  saveQuizAnswers(id, data): Observable<{report: QuizReport; quiz: Quiz}> {
    return this.http.post(`${this.environment.apiUrl}quiz`, {id, data}).pipe(
      map((res: BaseModel<{report: QuizReport; quiz: Quiz}>) => {
        return {
          report: new QuizReport().deserialize(res.data.report),
          quiz: new Quiz().deserialize(res.data.quiz),
        };
      })
    );
  }
}
