import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ApiClientService} from '@thebell/common/services/api/api-client';
import {SubscriptionData, LastNewsletterUrls} from '@thebell/common/models/subscription-data';
import {SubscriptionDialogComponent} from '../subscription-dialog';

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.scss'],
})
export class SubscriptionFormComponent implements OnInit {
  @Input() data = {email: ''};
  @Input() dialogRef: MatDialogRef<SubscriptionDialogComponent>;

  loading = false;

  subscribed = false;
  lastNewsletterUrls: LastNewsletterUrls;
  subscriptionTypes = [
    {
      slug: 'all',
      title: 'Утро, вечер и итоги недели',
      description:
        'Оставайтесь в курсе самых важных событий на протяжении 24 часов. Два раза в день о самых ' +
        'важных событиях и о том, что они значат для вас. По пятницам — пять дней за пять минут ' +
        'в итоговой рассылке.',
      bottom: 'Последнее письмо',
    },
    {
      slug: 'morning',
      title: 'Утренняя рассылка',
      description:
        'Самые важные новости и события в России и мире, которые случились, пока вы спали. ' +
        'Идеально, чтобы начать рабочий день.',
      bottom: 'Последнее письмо',
    },
    {
      slug: 'weekly',
      title: 'Итоги недели',
      description: 'Пять дней в одном письме. Объясняем, что это было и что это значит.',
      bottom: 'Последнее письмо',
    },
    {
      slug: 'evening',
      title: 'Вечерняя рассылка',
      description:
        'Вся картина прошедшего дня за пять минут. Не тратьте время на новости, мы расскажем все ' +
        'действительно важное и объясним, что это значит для вас.',
      bottom: 'Последнее письмо',
    },
    {
      slug: 'tech',
      title: 'The Bell Tech',
      description:
        'Разбор главных тем для инвесторов и перспективных инвестиционных идей. Авторы рассылки — профессиональные финансовые аналитики.' +
        'Выходит по субботам.',
      bottom: 'Последнее письмо',
    },
    {
      slug: 'en',
      title: 'The Bell in English. Weekly',
      description:
        'The Bell in English is your essential source for an insider view and fair analysis on ' +
        'Russian politics, business and more. Sign up for the week’s most important stories from Russia ' +
        'as selected by our top editors.',
      bottom: 'Last newsletter',
    },
  ];

  subscriptionData = new SubscriptionData().deserialize({
    source: 'stripe',
    email: '',
    newsletterTypes: {
      all: true,
      morning: false,
      weekly: false,
      evening: false,
      tech: false,
      en: false,
    },
  });
  constructor(private apiClientService: ApiClientService, public snackbar: MatSnackBar) {}

  ngOnInit(): void {
    this.apiClientService.lastNewsletterUrls().subscribe((response) => (this.lastNewsletterUrls = response));
    this.subscriptionData.email = this.data.email;
  }

  subscriptionTypesChecker() {
    // проверка, что хотябы одна из рассылок отмечена
    return (
      Object.values(this.subscriptionData.newsletterTypes).filter((type) => {
        return type;
      }).length === 0
    );
  }

  subscribe() {
    this.loading = true;
    const trueKeys = Object.keys(this.subscriptionData.newsletterTypes).filter(key => this.subscriptionData.newsletterTypes[key] === true);
    const resultString = trueKeys.join(',');
    ga('send', 'event', 'mail_page', 'mail_page_subscribe');
    dataLayer.push({
      'event': 'newsletters_page_subscribe', 'category': 'newsletter', 'type': resultString, 'label': this.subscriptionData.email
    });

    this.apiClientService.emailSubscription(this.subscriptionData).subscribe(
      () => {
        if (this.subscriptionData.newsletterTypes.all) {
          localStorage.setItem('mc_sub_morning', '1');
          localStorage.setItem('mc_sub_evening', '1');
          localStorage.setItem('mc_sub_weekly', '1');
        }
        if (this.subscriptionData.newsletterTypes.morning) localStorage.setItem('mc_sub_morning', '1');
        if (this.subscriptionData.newsletterTypes.weekly) localStorage.setItem('mc_sub_weekly', '1');
        if (this.subscriptionData.newsletterTypes.evening) localStorage.setItem('mc_sub_evening', '1');
        if (this.subscriptionData.newsletterTypes.tech) localStorage.setItem('mc_sub_ok_future', '1');
        if (this.subscriptionData.newsletterTypes.en) localStorage.setItem('mc_sub_en', '1');

        this.subscriptionData = new SubscriptionData().deserialize({
          email: '',
          newsletterTypes: {
            all: true,
            morning: false,
            weekly: false,
            evening: false,
            tech: false,
            en: false,
          },
        });

        this.subscribed = true;

        this.loading = false;

        setTimeout(() => {
          this.onModalClose();
        }, 2000);
      },
      (err) => {
        this.loading = false;
        this.snackbar.open('Ошибка\n' + (err.error.error as string), 'Закрыть');
      }
    );
  }

  onModalClose(): void {
    if (this.dialogRef) this.dialogRef.close(this.subscribed ? 'subscribed' : '');
  }

  clickSubscription($event: any, slug) {
    if ((slug === 'morning' || slug === 'evening' || slug === 'weekly') && $event) {
      this.subscriptionData.newsletterTypes.all = false;
    }
    if (slug === 'all' && $event) {
      this.subscriptionData.newsletterTypes.morning = false;
      this.subscriptionData.newsletterTypes.evening = false;
      this.subscriptionData.newsletterTypes.weekly = false;
    }
    this.subscriptionData.newsletterTypes[slug] = $event;
  }
}
