import {BaseWidget} from '../base';

export class NumberOfDay extends BaseWidget {
  // eslint-disable-next-line id-blacklist
  number: string;
  text: string;
  title?: string;
  background: string;
  author?: string;
  author_description?: string;
  quote?: string;
  type: string;
}
