import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRippleModule} from '@angular/material/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {LayoutLineHeightModule} from '@thebell/common/directives/layout-line-height';
import {WidgetHeightModule} from '@thebell/common/directives/widget-height';
import {CommonUiUtilsModule} from '@thebell/common/ui/utils';
import {CommonUiWidgetsModule} from '@thebell/common/ui/widgets';
import {
  CommonUiSubscriptionModule,
  PostSubscriptionComponent,
  SubscriptionDialogComponent,
} from '@thebell/common/ui/subscription';
import {InfiniteScrollModule} from './infinite-scroll';
import {ThemeModule} from './theme';
import {LineModule} from './theme/line';
import {AppendThemeItemModule} from './theme/append-theme-item';
import {WidgetScrollerModule} from './widget-scroller';
import {UploadImageModule} from './upload-image';
import {POST_SUBSCRIPTION_COMPONENT} from '@thebell/common/injection-tokens/post-subscription-component';
import {SUBSCRIPTION_DIALOG_COMPONENT} from '@thebell/common/injection-tokens/subscription-dialog-component';
import {PostUpdateButFakeComponent} from './post-update-but-fake';
import {POST_UPDATE_BUT_COMPONENT} from '@thebell/common/injection-tokens/post-update-but-component';
import {UploadImageOldModule} from './upload-image-old';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DragDropModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatInputModule,
    MatCardModule,
    CommonUiUtilsModule,
    CommonUiWidgetsModule,
    CommonUiSubscriptionModule,
    InfiniteScrollModule,
    ThemeModule,
    LineModule,
    AppendThemeItemModule,
    WidgetScrollerModule,
    UploadImageModule,
    UploadImageOldModule,
    LayoutLineHeightModule,
    WidgetHeightModule,
  ],
  exports: [
    InfiniteScrollModule,
    ThemeModule,
    WidgetScrollerModule,
    LayoutLineHeightModule,
    WidgetHeightModule,
    UploadImageModule,
    UploadImageOldModule,
    CommonUiUtilsModule,
    CommonUiWidgetsModule,
    CommonUiSubscriptionModule,
  ],
  // FixMe: Kostyl' for break circular dependency
  providers: [
    {provide: SUBSCRIPTION_DIALOG_COMPONENT, useValue: SubscriptionDialogComponent},
    {provide: POST_SUBSCRIPTION_COMPONENT, useValue: PostSubscriptionComponent},
    {provide: POST_UPDATE_BUT_COMPONENT, useValue: PostUpdateButFakeComponent},
  ],
})
export class CommonUiModule {}
