import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LayoutTheme} from '@thebell/common/models/layout-theme';
import {Observable} from 'rxjs';
import {Author} from '@thebell/common/models/author';
import {map, shareReplay} from 'rxjs/operators';
import {DeserializeWidgetDataService} from '@thebell/common/services/utils/deserialize-widget-data';
import {Post} from '@thebell/common/models/post';
import {LayoutThemeData} from '@thebell/common/models/layout-theme-data';
import {ThemeDataRequestOptions} from '@thebell/common/models/theme-data-request-options';
import {Category} from '@thebell/common/models/category';
import {PaginateModel} from '@thebell/common/models/paginate-model';
import {Quiz} from '@thebell/common/models/quizzes';
import {BaseModel} from '@thebell/common/models/base-model';
import {LayoutLine} from '@thebell/common/models/layout-line';
import {LayoutPreset} from '@thebell/common/models/layout-preset';
import {LayoutPresetItem} from '@thebell/common/models/layout-preset-item';
import {WidgetStyle} from '@thebell/common/models/widget-style';
import {Advertisement} from '@thebell/common/models/widget-style-data';
import {EnvironmentService} from '@thebell/common/services/core/environment';

interface ICaches {
  categories?: Observable<any>;
  layout_lines?: Observable<any>;
  widgetStyles?: Observable<any>;
  advertisement_providers?: Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class ApiAdminService {
  private excludedStyles = ['empty', 'subscription', 'post', 'bellclub', 'theme'];
  private caches$: ICaches = {};

  private readonly apiAdminUrl: string;
  private readonly apiUrl: string;
  private readonly apiTmpUrl: string;

  constructor(
    private envService: EnvironmentService,
    private http: HttpClient,
    private deserializeWidgetDataService: DeserializeWidgetDataService
  ) {
    const env = this.envService.getEnvironment();
    this.apiAdminUrl = env.apiAdminUrl;
    this.apiUrl = env.apiUrl;
    this.apiTmpUrl = env.apiTmpUrl;
  }

  addLayoutTheme(layoutTheme: any): Observable<LayoutTheme> {
    return this.http.post<LayoutTheme>(`${this.apiAdminUrl}layout_theme`, layoutTheme);
  }

  addLine(object): Observable<any> {
    return this.http.post<any>(`${this.apiAdminUrl}layout_theme/add_item`, object);
  }

  removeLayoutThemeLayoutThemeItemId(id: number): Observable<any> {
    return this.http.delete<any>(`${this.apiAdminUrl}layout_theme/remove_item/${id}`);
  }

  toggleLayoutThemeLayoutThemeItemRepeatable(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiAdminUrl}layout_theme/toggle_repeatable/${id}`);
  }
  // только для референс тем и для базиса 33
  toggleLayoutThemeLayoutThemeItemSmall(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiAdminUrl}layout_theme/toggle_small/${id}`);
  }

  // upload image
  upload(formData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}assets/upload`, formData);
  }

  // authors api
  getAuthors(): Observable<Author[]> {
    return this.http.get<Author[]>(`${this.apiAdminUrl}authors`);
  }

  getAuthor(id: number): Observable<{data: Author}> {
    return this.http.get<{data: Author}>(`${this.apiAdminUrl}authors/${id}`);
  }

  deleteAuthor(id: number): Observable<any> {
    return this.http.delete<Author>(`${this.apiAdminUrl}authors/${id}`);
  }

  saveAuthor(formData): Observable<any> {
    return this.http.post<any>(`${this.apiAdminUrl}authors`, formData);
  }

  paginatedPost(options: any, page: number = null): Observable<PaginateModel<Post>> {
    let params = new HttpParams();
    if (page) {
      params = params.append('page', String(page));
    }
    for (const option in options) {
      // eslint-disable-next-line no-prototype-builtins
      if (options.hasOwnProperty(option)) {
        params = params.append(option, String(options[option]));
      }
    }
    return this.http
      .get<PaginateModel<Post>>(this.apiAdminUrl + 'posts', {params})
      .pipe(shareReplay());
  }

  getPost(id: number): Observable<BaseModel<LayoutThemeData>> {
    return this.http.get<BaseModel<LayoutThemeData>>(`${this.apiAdminUrl}posts/${id}`);
  }

  autoSavePost(post): Observable<BaseModel<any>> {
    //после того как появится автосохранение поста на беке
    return this.http.get<BaseModel<any>>(`${this.apiAdminUrl}posts/${post.id}`);
  }

  getTags(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.apiTmpUrl}post-tags`);
  }

  paginatedExternalPost(options: any, page = 1): Observable<PaginateModel<Post>> {
    let params = new HttpParams();
    for (const option in options) {
      // eslint-disable-next-line no-prototype-builtins
      if (options.hasOwnProperty(option)) {
        params = params.append(option, String(options[option]));
      }
    }
    return this.http
      .get<PaginateModel<Post>>(this.apiAdminUrl + 'external_posts' + (page ? '?page=' + page : ''), {
        params,
      })
      .pipe(shareReplay());
  }

  paginatedPage(options: any, page = 1): Observable<PaginateModel<Post>> {
    let params = new HttpParams();
    if (page) {
      params = params.append('page', String(page));
    }
    for (const option in options) {
      // eslint-disable-next-line no-prototype-builtins
      if (options.hasOwnProperty(option)) {
        params = params.append(option, String(options[option]));
      }
    }
    return this.http
      .get<PaginateModel<Post>>(this.apiAdminUrl + 'pages', {params})
      .pipe(shareReplay());
  }

  categories(): Observable<Category[]> {
    if (!this.caches$.categories) {
      // eslint-disable-next-line
      this.caches$.categories = this.http.get<any>(this.apiAdminUrl + 'categories').pipe(
        map((response) => response.data.map((category_info) => new Category().deserialize(category_info))),
        shareReplay()
      );
    }
    // eslint-disable-next-line
    return this.caches$.categories;
  }

  layoutLines(cache = true): Observable<LayoutLine[]> {
    // eslint-disable-next-line
    if (!this.caches$.layout_lines || !cache) {
      // eslint-disable-next-line
      this.caches$.layout_lines = this.http.get(this.apiAdminUrl + 'layout_lines').pipe(
        map((layoutLines: BaseModel<LayoutLine[]>) =>
          layoutLines.data.map((layoutLine) => new LayoutLine().deserialize(layoutLine))
        ),
        shareReplay()
      );
    }
    // eslint-disable-next-line
    return this.caches$.layout_lines;
  }

  layoutThemes(): Observable<LayoutTheme[]> {
    const url = this.apiAdminUrl + 'layout_themes';
    return this.http.get(url).pipe(
      map((layoutThemes: BaseModel<LayoutTheme[]>) =>
        layoutThemes.data.map((layoutTheme) => new LayoutTheme().deserialize(layoutTheme))
      ),
      shareReplay()
    );
  }

  layoutPresets(): Observable<LayoutPreset[]> {
    const url = this.apiAdminUrl + 'layout_presets';
    return this.http.get(url).pipe(
      map((presets: BaseModel<LayoutPreset[]>) => {
        return presets.data.map((preset) => {
          preset.items.map((item) =>
            new LayoutPresetItem().deserialize(this.deserializeWidgetDataService.deserialize(item))
          );
          return new LayoutPreset().deserialize(preset);
        });
      }),
      shareReplay()
    );
  }

  setAsPreset(options: any): Observable<any> {
    return this.http.post(this.apiAdminUrl + 'set_as_preset', options);
  }

  updatePreset(options: any): Observable<any> {
    return this.http.post(this.apiAdminUrl + 'update_preset_item', options);
  }

  repeatablePresetItem(params) {
    return this.http.post(this.apiAdminUrl + 'repeatable_preset_item', params);
  }

  detachPresetItem(id) {
    return this.http.get(this.apiAdminUrl + 'detach_preset_item/' + id);
  }

  layoutThemeData(options: ThemeDataRequestOptions): Observable<BaseModel<LayoutThemeData[][]>> {
    let params = new HttpParams();
    for (const param of Object.keys(options)) {
      params = params.set(param, options[param] ? options[param] : '');
    }

    return this.http.get(this.apiAdminUrl + 'layout_theme_data/', {params}).pipe(
      map((themeDatas: BaseModel<LayoutThemeData[][]>) => {
        themeDatas.data = themeDatas.data.map((themeData) =>
          themeData.map((item) =>
            new LayoutThemeData().deserialize(this.deserializeWidgetDataService.deserialize(item))
          )
        );
        return themeDatas;
      }),

      shareReplay()
    );
  }

  updateLayoutTheme(layoutTheme: any) {
    return this.http.put<any>(this.apiAdminUrl + 'layout_theme/' + layoutTheme.id, {
      title: layoutTheme.title,
      description: layoutTheme.description,
      style: layoutTheme.style,
    });
  }

  updateReferenceTheme(layoutTheme: any) {
    return this.http.put<any>(this.apiAdminUrl + 'layout_theme/' + layoutTheme.id, {
      title: layoutTheme.title,
      description: layoutTheme.description,
      style: layoutTheme.style,
      name: layoutTheme.name,
    });
  }

  saveTheme4Clients(themeId: number) {
    return this.http.post(`${this.apiAdminUrl}save_theme_4_client`, {layout_theme_id: themeId});
  }

  resetAdminData(layoutTheme: LayoutTheme): Observable<any> {
    return this.http.post(`${this.apiAdminUrl}layout_presets/reset`, {
      id: layoutTheme.id,
      name: layoutTheme.name,
      slug: layoutTheme.slug,
    });
  }

  getQuizzes(): Observable<Quiz[]> {
    return this.http.get(`${this.apiAdminUrl}quizzes`).pipe(
      map((res: BaseModel<Quiz[]>) => {
        return res.data.map((e) => new Quiz().deserialize(e));
      })
    );
  }

  getQuiz(id: number): Observable<Quiz> {
    return this.http.get(`${this.apiAdminUrl}quizzes/${id}`).pipe(
      map((res: BaseModel<Quiz>) => {
        return new Quiz().deserialize(res.data);
      })
    );
  }

  saveQuiz(data: Quiz): Observable<Quiz> {
    if (data.id) {
      return this.http.put(`${this.apiAdminUrl}quizzes/${data.id}`, {data}).pipe(
        map((res: BaseModel<Quiz>) => {
          return new Quiz().deserialize(res.data);
        })
      );
    } else {
      return this.http.post(`${this.apiAdminUrl}quizzes`, {data}).pipe(
        map((res: BaseModel<Quiz>) => {
          return new Quiz().deserialize(res.data);
        })
      );
    }
  }

  deleteQuiz(id: number): Observable<any> {
    return this.http.delete<Quiz>(`${this.apiAdminUrl}quizzes/${id}`).pipe();
  }

  widgetStyles(): Observable<WidgetStyle[]> {
    // eslint-disable-next-line
    if (!this.caches$.widgetStyles) {
      // eslint-disable-next-line
      this.caches$.widgetStyles = this.http.get<BaseModel<WidgetStyle[]>>(`${this.apiAdminUrl}widget_styles`).pipe(
        map(({data}) => data.filter((style: WidgetStyle) => !this.excludedStyles.includes(style.name))),
        shareReplay()
      );
    }
    // eslint-disable-next-line
    return this.caches$.widgetStyles;
  }

  saveStyleable(styleable): Observable<LayoutThemeData> {
    return this.http
      .post<BaseModel<LayoutThemeData>>(`${this.apiAdminUrl}stylable`, styleable)
      .pipe(map(({data}) => new LayoutThemeData().deserialize(data)));
  }

  endlessScroll(): Observable<BaseModel<Post[]>> {
    return this.http.get<BaseModel<Post[]>>(`${this.apiAdminUrl}endless_scroll`);
  }

  endlessScrollStore(body: any): Observable<BaseModel<Post[]>> {
    return this.http.post<BaseModel<Post[]>>(`${this.apiAdminUrl}endless_scroll`, body);
  }

  endlessScrollDelete(id): Observable<BaseModel<Post[]>> {
    return this.http.delete<BaseModel<Post[]>>(`${this.apiAdminUrl}endless_scroll/${id}`);
  }

  getAdvertisements(options: any, page = 1): Observable<any> {
    let params = new HttpParams();
    for (const option in options) {
      // eslint-disable-next-line no-prototype-builtins
      if (options.hasOwnProperty(option)) params = params.append(option, String(options[option]));
    }
    return this.http
      .get<PaginateModel<Advertisement>>(`${this.apiAdminUrl}ads` + (page ? '?page=' + page : ''), {
        params,
      })
      .pipe(shareReplay());
  }

  addAdvertisement(data): Observable<any> {
    if (data.id) {
      return this.http.put<any>(`${this.apiAdminUrl}ads/${data.id}`, {data});
    }
    return this.http.post<any>(`${this.apiAdminUrl}ads`, data);
  }

  delAdvertisement(id): Observable<any> {
    return this.http.delete<any>(`${this.apiAdminUrl}ads/${id}`);
  }

  toggleAd(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiAdminUrl}posts/toggle_noad/${id}`);
  }

  createAsset(role: string, src: string): Observable<any> {
    return this.http.post<any>(`${this.apiAdminUrl}assets`, {role, src});
  }
}
