import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PostAdGetterService {
  private adRotate = ['adfox', 'zen', 'smi2'];
  private defaultAdRotate = ['adfox', 'zen', 'smi2'];

  getNextAd() {
    const currentAd = this.adRotate.shift();
    this.adRotate.push(currentAd);
    return currentAd;
  }
  resetAdRotate() {
    this.adRotate = [].concat(this.defaultAdRotate);
  }
}
