import {Observable, of, throwError} from 'rxjs';
import {delay, mergeMap, retryWhen} from 'rxjs/operators';

const getErrorMessage = (maxRetry: number) =>
  `Tried to load Resource over XHR for ${maxRetry} times without success. Giving up.`;

const DEFAULT_MAX_RETRIES = 5;
const excludedErrors = [404, 500];

export function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES) {
  let retries = maxRetry;

  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors) =>
        errors.pipe(
          delay(delayMs),
          mergeMap((error) => {
            if (excludedErrors.includes(error.status)) {
              return throwError(error);
            } else {
              return retries-- > 0 ? of(error) : throwError(getErrorMessage(maxRetry));
            }
          })
        )
      )
    );
}
