import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonUiUtilsModule} from '@thebell/common/ui/utils';

import {SubscriptionDialogComponent} from './subscription-dialog';
import {SubscriptionFormComponent} from './subscription-form';
import {PostSubscriptionComponent} from './post-subscription';
import {SubscriptionStickyDialogComponent} from './subscription-banner';
import {SubscriptionHeaderFormComponent} from './subscription-header-form';

@NgModule({
  declarations: [
    SubscriptionDialogComponent,
    SubscriptionFormComponent,
    PostSubscriptionComponent,
    SubscriptionStickyDialogComponent,
    SubscriptionHeaderFormComponent,
  ],
  imports: [CommonModule, CommonUiUtilsModule, RouterModule, FormsModule, ReactiveFormsModule, MatDialogModule],
  exports: [
    SubscriptionDialogComponent,
    SubscriptionFormComponent,
    PostSubscriptionComponent,
    SubscriptionStickyDialogComponent,
    SubscriptionHeaderFormComponent,
  ],
})
export class CommonUiSubscriptionModule {}
