import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-author-social',
  templateUrl: './author-social.component.html',
  styleUrls: ['./author-social.component.scss'],
})
export class AuthorSocialComponent {
  @Input() slug: string;
}
