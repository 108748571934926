import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {ContentLoadingService} from '@thebell/common/services/others/event-bus';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Output() linkClick = new EventEmitter();
  downloadUrl = `${environment.apiUrl}advertisement`;
  public dataIsLoad: Observable<boolean>;

  constructor(private eRef: ElementRef, private contentLoadingService: ContentLoadingService) {
    this.dataIsLoad = this.contentLoadingService.get$().asObservable();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.linkClick.emit();
    }
  }

  ngOnInit(): void {}
}
