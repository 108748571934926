import {AfterViewInit, Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2} from '@angular/core';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {UuidUtil} from '@thebell/common/services/utils/uuid';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Directive({
  selector: '[appIncutParser]',
})
export class IncutParserDirective implements AfterViewInit {
  private incutIds = [];
  get html(): SafeHtml {
    return this._html;
  }
  @HostBinding('innerHTML')
  @Input()
  set html(html: SafeHtml) {
    let html2 = html.toString();
    html2 = html2.replace(/stk-container incut/gi, (_) => {
      const id = new UuidUtil().generator();
      this.incutIds.push(id);
      return `stk-container incut incut_collapsed incut_collapsed_${id}`;
    });
    this._html = this.sanitizer.bypassSecurityTrustHtml(html2);
  }
  private _html: SafeHtml;
  constructor(private sanitizer: DomSanitizer) {}
  ngAfterViewInit() {
    this.incutIds.forEach((incutID) => {
      const button = document.querySelector(`.incut_collapsed_${incutID}`);
      if (button) {
        button.querySelector('.incut_header').addEventListener('click', (event) => {
          if (button.classList.contains('incut_collapsed')) {
            button.classList.remove('incut_collapsed');
          } else {
            button.classList.add('incut_collapsed');
          }
        });
      }
    });
  }
}
