import {Injectable} from '@angular/core';
import {ADDSMI2, ADDFOX, ADDZEN, ADDRTB} from './ad-providers';
@Injectable({
  providedIn: 'root',
})
export class AdProviderService {
  adProvidersMap = {
    ADDSMI2,
    ADDFOX,
    ADDZEN,
    ADDRTB,
  };

  getScriptBySlug(slug) {
    switch (slug) {
      case 'smi2':
        return this.adProvidersMap.ADDSMI2;
      case 'adfox':
        return this.adProvidersMap.ADDFOX;
      case 'zen':
        return this.adProvidersMap.ADDZEN;
      case 'rtb':
        return this.adProvidersMap.ADDRTB;
    }
  }
}
