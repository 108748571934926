import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {UploadImageComponent} from './upload-image.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatProgressSpinnerModule],
  declarations: [UploadImageComponent],
  exports: [UploadImageComponent],
})
export class UploadImageModule {}
