import {
  Component,
  Injector,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {DataMapperService} from '@thebell/common/services/utils/data-mapper';
import {ManagePresetsService} from '@thebell/common/services/others/manage-presets';
import {WIDGET_DATA} from '@thebell/common/injection-tokens/widget-data';
import {ActivatedRoute} from '@angular/router';
import {WidgetItem} from '@thebell/common/models/widget-item';
import {LayoutThemeItem} from '@thebell/common/models/layout-theme-item';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineComponent implements OnInit {
  @Input() lineIndex: number;
  @Input() lineData: WidgetItem[] = [];
  @Input() themeItem: LayoutThemeItem;
  @Input() layoutTheme;
  @Input() themeIndex: number;
  @Input() isOverflowXScroll: boolean;
  @Input() currentRouter = '';

  @Output() move = new EventEmitter<any>();

  injectors = [];
  maxBasis: number;
  isPost = false;
  isEmpty;
  ADVERTISEMENT_MODEL = 'App\\Models\\Advertisement';

  constructor(
    private injector: Injector,
    public managePresetsService: ManagePresetsService,
    private dataMapperService: DataMapperService,
    public route: ActivatedRoute,
    public detectedScreenChangeService: DetectedScreenChangeService,
    private cdr: ChangeDetectorRef
  ) {
    this.managePresetsService.intentionCheckDataActive.subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  setPresetItem = (a, b) => this.managePresetsService.set(a, b);

  ngOnInit(): void {
    this.isEmpty =
      this.lineData.filter((widget) => widget.data.widget_style.slug === 'EmptyComponent').length ===
      this.lineData.length;

    this.isPost = this.lineData.filter((widget) => widget.data.widget_style.slug === 'PostComponent').length > 0;

    this.maxBasis = this.getMaxBasis();
  }

  get classes() {
    return {
      postData: this.route?.snapshot.data.name === 'post',
      overflowXScroll: this.isOverflowXScroll,
      line_special_post: this.layoutTheme.is_special && this.route.snapshot.data.name !== 'post',
      line_special_page: this.layoutTheme.is_special_page,
    };
  }

  get styling() {
    let columnSize = '';
    if (
      this.lineData.length &&
      (this.maxBasis > 25 || this.lineData.length < 4 || this.detectedScreenChangeService.getScreenType() !== 'mobile')
    ) {
      columnSize = this.lineData.map((widgetItem) => widgetItem.basis).join('fr ') + 'fr ';
    }
    if (this.route.snapshot.data.name === 'post') {
      columnSize = columnSize.replace(/(?:^|\s)0fr/g, ' 0');
    }
    return {
      gridTemplateColumns: columnSize,
      marginTop: this.route.snapshot.data.name === 'post' ? '5px' : '10px',
      width:
        this.route.snapshot.data.name === 'post' &&
        this.detectedScreenChangeService.getScreenType() !== 'tablet' &&
        !this.isPost
          ? '75%'
          : '',
    };
  }

  getMaxBasis(): number {
    return Math.max(
      ...this.lineData.map((widget) => {
        if (widget.data.widget_style.slug === 'EmptyComponent') {
          return 0;
        }
        if (widget.data.widget_style.slug === 'LightningComponent') {
          return 0;
        }
        return +widget.basis;
      })
    );
  }

  smallAbility() {
    return this.lineData.length < 4 && this.themeItem.small;
  }

  createInjector(item, index): Injector {
    let newInjector = false;
    if (item.data.refresh) {
      newInjector = true;
      item.data.refresh = false;
    }

    if (newInjector || !this.injectors[index]) {
      this.injectors[index] = Injector.create({
        providers: [
          {
            provide: WIDGET_DATA,
            useValue: {
              data: this.dataMapperService.map(item),
              is_special: this.layoutTheme.is_special,
              srcData: item,
              basis: item.basis,
              lineMaxBasis: this.maxBasis,
              themeIndex: this.themeIndex,
              lineIndex: this.lineIndex,
              small: this.smallAbility(),
              authorAvatarIsResizable: true,
            },
          },
        ],
        parent: this.injector,
      });
    }
    return this.injectors[index];
  }

  generateItem(item) {
    return Object.assign(item, {slug: this.layoutTheme.slug});
  }
}
