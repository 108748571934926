import {NewsComponent} from './news';
import {BellClubComponent} from './bell-club';
import {NumberOfDayComponent} from './number-of-day';
import {QuoteComponent} from './quote';
import {EmptyComponent} from './empty';
import {LightningComponent} from './lightning';
import {SubscriptionComponent} from './subscription/subscription.component';
import {AdvertisementComponent} from './advertisement';
import {CollapsePostComponent} from './collapse-post/collapse-post.component';

export const widgetsMap = {
  NewsComponent,
  BellClubComponent,
  NumberOfDayComponent,
  QuoteComponent,
  EmptyComponent,
  SubscriptionComponent,
  LightningComponent,
  PostComponent: CollapsePostComponent,
  AdvertisementComponent,
};
type ValueOf<T> = T[keyof T];

export type WidgetComponent = ValueOf<typeof widgetsMap>;
