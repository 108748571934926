<div
  [ngClass]="[layoutTheme.isInner ? 'inner' : 'root']"
  *ngIf='!isEmpty'
  [class.flexible]="layoutTheme.is_special"
>
  <div
    class="line"
    [ngClass]="classes"
    [ngStyle]="styling"
    appLayoutLineHeight
    [small]='smallAbility()'
    [layoutTheme]='layoutTheme'
    [basis]='maxBasis'
    [data]='lineData'
    [currentRouter]="currentRouter"
  >
    <ng-template ngFor let-item [ngForOf]='lineData' let-i='index'>
      <div
        class='line-widget'
        [class.empty]="item.data.widget_style?.slug === 'EmptyComponent'"
        [class.advertisement_line]="item.data.fixed_model_name === ADVERTISEMENT_MODEL"
        [id]="'dd_line_' + themeItem.layout_theme_layout_theme_item_id + '_' + (i + 1)"
        (click)='setPresetItem(item, themeItem.layout_theme_layout_theme_item_id)'
        [class.basis-50]='lineData.length === 2'
      >
        <ng-container
          *ngComponentOutlet="item.component; injector: createInjector(generateItem(item), i)">
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>
