/* eslint-disable @angular-eslint/directive-selector */
import {isPlatformBrowser, Location} from '@angular/common';
import {AfterViewInit, Directive, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {PostBannerService} from '@thebell/common/services/api/post-banner';

@Directive({
  selector: '[postDonateBanner]',
})
export class PostDonateBannerDirective implements AfterViewInit, OnInit, OnDestroy {
  private observer: MutationObserver;
  private _bannerObserver: IntersectionObserver;
  private banner: any;
  private bannerElements: HTMLElement[] = [];
  private isInit = false;

  constructor(
    private elRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    private bannerService: PostBannerService,
    private location: Location
  ) {}

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId) && this.isInit && this.bannerElements && this.bannerElements.length) {
      this.removeOnClickEvents();
      this.removeOnViewEvents();
    }
  }

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.banner = await this.bannerService.actualBanner$.toPromise();
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach(this.addEvents.bind(this));
      });
      const config = {attributes: true, childList: true, characterData: true};

      this.observer.observe(this.elRef.nativeElement, config);
    }
  }

  get bannerObserver() {
    if (!this._bannerObserver) {
      this._bannerObserver = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            if (entry.intersectionRatio > 0.6 && entry.isIntersecting && !(entry.target as any).isViewed) {
              this.onBannerView(entry.target);
            }
          }
        },
        {threshold: 0.6}
      );
    }
    return this._bannerObserver;
  }

  private addEvents(mutation) {
    if (!this.isInit) {
      this.bannerElements = this.elRef.nativeElement.querySelectorAll('.arena-banner');
      if (this.bannerElements && this.bannerElements.length && this.banner) {
        for (const bannerElement of this.bannerElements) {
          const buttonElement = bannerElement.querySelector('.textarena-btn') as HTMLElement
          if (buttonElement) {
            // console.log('DONATION BANNER ', bannerElement);
            this.addOnClickEvent(buttonElement);
            this.addOnViewEvent(bannerElement);
          }
        }
        this.isInit = true;
      }
    }
  }

  private addOnClickEvent(banner: HTMLElement) {
    banner.addEventListener('click', this.onBannerClick.bind(this));
  }

  private addOnViewEvent(banner: HTMLElement) {
    this.bannerObserver.observe(banner);
  }

  private removeOnClickEvents() {
    for (const bannerElement of this.bannerElements) {
      bannerElement.removeEventListener('click', this.onBannerClick.bind(this));
    }
  }

  private removeOnViewEvents() {
    this.bannerObserver.disconnect();
  }

  private onBannerClick(banner: HTMLElement, event: MouseEvent) {
    const path = this.location.path();
    // console.log('CLICK TO BANNER', path);
    ga('send', 'event', 'donate', 'click', path);
    dataLayer.push({
      'event': 'donate_snippet_click', 'category': 'donate'
    });
  }

  private onBannerView(banner: any) {
    const path = this.location.path();
    banner.isViewed = true;
    // console.log('BANNER VIEW', path);
    ga('send', 'event', 'donate', 'view', path);
    dataLayer.push({
      'event': 'donate_snippet_show', 'category': 'donate'
    });
  }
}
