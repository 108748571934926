import {Post} from '@thebell/common/models/post';
import {WidgetStyle} from '@thebell/common/models/widget-style';
import {Deserializable} from '@thebell/common/models/deserializable';
import {WidgetTypes} from '@thebell/common/models/widget-types';

export class LayoutPresetItem extends Deserializable {
  id: number;
  layout_preset_id: number;
  item: Post;
  widget_style: WidgetStyle;
  widget_style_data: WidgetTypes;
  fixed_model_name: string;
  fixed_model_id: number;
  layout_theme_layout_theme_item_id: number;
  position: number;
  repeatable: boolean;
}
