import {Inject, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, fromEvent, merge, Subject, Subscription} from 'rxjs';
import {debounceTime, map, withLatestFrom} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {IEl, IElOnScreenState} from './types';

@Injectable({
  providedIn: 'root',
})
export class PostOnScreenResolverService implements OnDestroy {
  subject = new Subject<IElOnScreenState>();
  set box(value: IElOnScreenState[]) {
    this._box = value;
  }
  private _newItemEmitter = new BehaviorSubject<IEl | null>(null);
  private _box: IElOnScreenState[];
  private _scrollPos = 0;
  private _subs: Subscription[] = [];
  constructor(@Inject(DOCUMENT) private document: Document) {
    const dummyScroll$ = fromEvent(window, 'scroll');
    const scrollDirection$ = dummyScroll$.pipe(
      map(() => {
        let direction: 'UP' | 'DOWN';
        if (this.document.body.getBoundingClientRect().top > this._scrollPos) direction = 'UP';
        else direction = 'DOWN';
        this._scrollPos = this.document.body.getBoundingClientRect().top;
        return direction;
      })
    );
    const scrollWithInitialValue$ = merge(new BehaviorSubject(null), scrollDirection$);
    const dummyScrollWithInitialValue$ = merge(new BehaviorSubject(null), dummyScroll$);
    const onScreen$ = this.subject.pipe(
      withLatestFrom(scrollWithInitialValue$),
      map(([el, direction]) => {
        if (!this._box) this.init(el);
        let clone = [...this._box];
        const current = el;
        const found = clone.find((_el) => _el.item.id === current.item.id);
        if (direction === 'DOWN' && el.onScreen && !found) {
          clone.push(current);
        }
        if (direction === 'UP' && el.onScreen && !found) {
          clone.splice(0, 0, current);
        }
        if (!el.onScreen) {
          if (found) clone = clone.filter((_el) => _el.item.id !== current.item.id);
        }
        this.box = [...clone];
        return [...clone];
      })
    );
    const sub$ = combineLatest([dummyScrollWithInitialValue$, onScreen$])
      .pipe(
        debounceTime(10),
        map(([_, data]) => {
          if (data.length === 1) return [{...data[0], percentage: 100}];
          return data.map((el, i, box) => {
            // если элемента на экране 3 и более, какой то может быть слишком маленьким, чтобы быть хотя бы когда то показанным
            // В таком случае даем фору второму
            const allowance = box.length > 2 && i === 1 ? 1000 : 0;
            return {...el, percentage: allowance + this.percentageSeen(el.element)};
          });
        })
      )
      .subscribe((data) => {
        data = data.sort((first, second) => {
          return second.percentage - first.percentage;
        });
        if (data[0]?.item) this._newItemEmitter.next(data[0].item);
      });
    this._subs.push(sub$);
  }

  ngOnDestroy(): void {
    this.box = null;
    this._subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
  init(first: IElOnScreenState) {
    this.box = [first];
  }

  get newItemEmitter() {
    return this._newItemEmitter.asObservable();
  }
  // calculate how much proc element takes on viewPort
  private percentageSeen(element: HTMLElement) {
    if (!element) return 0;
    const viewportHeight = window.innerHeight;
    const elementOffsetTop = element.getBoundingClientRect().top;
    const elementHeight = element.offsetHeight;
    let val = 0;
    if (elementOffsetTop < 0) {
      val = elementHeight + elementOffsetTop;
    } else if (elementOffsetTop >= 0) {
      if (elementOffsetTop + elementHeight > viewportHeight) val = viewportHeight - elementOffsetTop;
      else if (elementOffsetTop + elementHeight <= viewportHeight) val = elementHeight;
    }
    const intersect = Math.round((val / viewportHeight) * 100);
    return Math.min(100, intersect);
  }
}
