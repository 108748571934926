import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Meta, MetaDefinition} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(
    private meta: Meta,
    @Inject(DOCUMENT) private dom: Document,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>
  ) {}

  addCanonical(href: string): void {
    const element: HTMLLinkElement = this.dom.querySelector('link[rel="canonical"]');
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    const url = this.rebuildLink(href);
    link.setAttribute('href', url.href);
    if (element) {
      this.dom.head.replaceChild(link, element);
    } else {
      this.dom.head.appendChild(link);
    }
  }

  rebuildLink(href: string): URL {
    const currentDomain = window.location.host;
    const currentUrl = new URL(window.location.href);
    const url = new URL(href, currentUrl);
    if (url.host !== '') {
      url.host = currentDomain;
    }
    return url;
  }

  addAmpLink(href: string): void {
    const element: HTMLLinkElement = this.dom.querySelector('link[rel="amphtml"]');
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'amphtml');
    const url = this.rebuildLink(href);
    link.setAttribute('href', url.origin + '/amp' + url.pathname);
    if (element) {
      this.dom.head.replaceChild(link, element);
    } else {
      this.dom.head.appendChild(link);
    }
  }

  push(data: Array<MetaDefinition>): void {
    for (const item of data) {
      // ищет метаэлемент и вставляет, если не найден
      this.meta.updateTag(item);
    }
  }

  delete(data: Array<MetaDefinition>): void {
    for (const item of data) {
      const tag = `${Object.keys(item)[0]} = "${Object.values(item)[0]}"`;
      this.meta.removeTag(tag);
    }
  }
}
