import {EventEmitter, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';

interface ScreenChangeTarget extends EventTarget {
  innerWidth: number;
}

interface ScreenChange extends Event {
  target: ScreenChangeTarget;
}

@Injectable({
  providedIn: 'root',
})
export class DetectedScreenChangeService {
  screenTypeChanged: EventEmitter<string> = new EventEmitter();
  private screenType = '';
  public screenTypes = {
    sMobile: 375,
    mobile: 768,
    tablet: 1024,
    smallDesktop: 1130,
    desktop: 1280,
    desktopRetina: 2560,
  };
  public windowResize: Observable<any>;
  screenType$ = new BehaviorSubject(null);
  constructor(@Inject(PLATFORM_ID) private platformId: Record<string, any>) {
    if (isPlatformBrowser(this.platformId)) {
      this.updateScreenType(window.innerWidth);

      this.windowResize = fromEvent(window, 'resize').pipe(shareReplay());
      this.windowResize.subscribe((x: ScreenChange) => {
        this.updateScreenType(x.target.innerWidth);
      });
    }
  }

  updateScreenType(innerWidth) {
    const oldScreen = this.screenType;
    this.screenType = this.getScreenTypeByWidth(innerWidth);
    if (oldScreen !== this.screenType) {
      this.screenTypeChanged.emit(this.screenType);
      this.screenType$.next(this.screenType);
    }
  }

  getScreenType(): string {
    return this.screenType;
  }

  getScreenWidth(screenType): number {
    switch (true) {
      case screenType === 'mobile':
        return this.screenTypes.mobile;
      case screenType === 'tablet':
        return this.screenTypes.tablet;
      case screenType === 'smallDesktop':
        return this.screenTypes.smallDesktop;
      case screenType === 'desktop':
        return this.screenTypes.desktop;
      default:
        return this.screenTypes.desktop;
    }
  }

  getScreenTypeByWidth(width) {
    switch (true) {
      case width < this.screenTypes.mobile:
        return 'mobile';
      case width <= this.screenTypes.tablet:
        return 'tablet';
      case width <= this.screenTypes.smallDesktop:
        return 'smallDesktop';
      case width > this.screenTypes.smallDesktop:
        return 'desktop';
      default:
        return 'desktop';
    }
  }
}
