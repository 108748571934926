import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {LayoutTheme} from '@thebell/common/models/layout-theme';
import {WidgetItem} from '@thebell/common/models/widget-item';
import {LayoutLine} from '@thebell/common/models/layout-line';
import {
  widgetsMap,
  AdvertisementComponent,
  NewsComponent,
  NumberOfDayComponent,
  QuoteComponent,
} from '@thebell/common/ui/widgets';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeComponent implements OnInit, OnChanges {
  @Input() layoutTheme: LayoutTheme;
  @Input() layoutData: WidgetItem[][] = [[]];
  @Input() lineIndex?: number;
  @Input() themeIndex: number;
  @Input() currentRouter = '';
  layoutThemeLayoutThemeItemIds: number[] = [];
  themeItemIds2Render: string[] = [];
  private coof: number;
  private basisSet = [25, 33, 50, 66, 75, 100];
  private lineData = {};
  longLineData = [];

  constructor(
    public route: ActivatedRoute,
    public detectedScreenChangeService: DetectedScreenChangeService,
    private cdr: ChangeDetectorRef,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    const vw100: number = window.innerWidth;
    const maxWidth: number = Math.min(1280, vw100);
    const themeWidth: number = this.el.nativeElement.parentElement.offsetWidth;
    this.coof = themeWidth / maxWidth;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.layoutData || (changes.layoutTheme && this.layoutTheme.items.length > 0)) {
      this.layoutThemeLayoutThemeItemIds = this.layoutTheme.items
        .filter((item) => this.themeIndex === 0 || item.repeatable)
        .map((item) => item.layout_theme_layout_theme_item_id);
      this.longLineData = this.buildLongLineData();

      this.addThemeItemToRender();
    }
  }

  getLineData(id): WidgetItem[] {
    if (this.lineData[id] !== undefined) return this.lineData[id];

    let result = [];

    if (id in this.layoutData) {
      let themeItem = this.layoutTheme.items.find((item) => item.layout_theme_layout_theme_item_id === id);
      // TODO
      if (!themeItem) {
        try {
          this.layoutTheme.items.forEach((elem) => {
            if (elem.model !== 'LayoutThemes' || themeItem) return;
            themeItem = (elem.item as Array<any>).find((item) => item.layout_theme_layout_theme_item_id === id);
          });
        } catch (ignore) {
          console.log(ignore);
        }
      }
      let widgetInLine;
      if (themeItem.model === 'LayoutLine') {
        widgetInLine = (themeItem.item as LayoutLine).widgets;
        if (widgetInLine) {
          for (let i = 0; i < widgetInLine.length; i++) {
            const basis = this.route.snapshot.data.name === 'post' ? '0' : widgetInLine[i].basis;
            if (undefined === this.layoutData[id][i]) {
              this.layoutData[id][i] = new WidgetItem(
                widgetsMap.EmptyComponent,
                {
                  component: widgetsMap.EmptyComponent,
                  widget_style: {
                    name: 'empty',
                    slug: 'EmptyComponent',
                  },
                  position: i + 1,
                },
                basis
              );
            }
          }
        }
        if (this.coof) {
          this.layoutData[id].forEach((widgetItem) => {
            const newBasis = +widgetItem.basis * this.coof;
            const tmp = {...widgetItem};
            tmp.basis = this.roundBasis(newBasis, this.basisSet);
            result.push(tmp);
          });
          this.lineData[id] = result;
          return this.lineData[id];
        }
      }
      result = this.layoutData[id];
    }
    return result;
  }

  show(item) {
    const key = this.themeIndex.toString() + '.' + item.layout_theme_layout_theme_item_id.toString();
    if (this.themeItemIds2Render.indexOf(key) === -1) return false;
    return this.themeIndex === 0 || item.repeatable;
  }

  styling(themeItem) {
    const style: any = {};
    style.overflowY =
      this.detectedScreenChangeService.getScreenType() === 'mobile' ||
      this.detectedScreenChangeService.getScreenType() === 'sMobile'
        ? 'auto'
        : 'unset';
    if (themeItem.model !== 'LayoutTheme' || !themeItem.is_special) return style;
    if (themeItem?.style?.enabled && themeItem?.style?.background_color) {
      style.backgroundColor = themeItem?.style?.background_color;
    }
    return style;
  }

  roundBasis(num: number, set: number[]) {
    let closest = set[0];
    let prev = Math.abs(set[0] - num);
    for (let i = 1; i < set.length; i++) {
      const diff = Math.abs(set[i] - num);
      if (diff < prev) {
        prev = diff;
        closest = set[i];
      }
    }
    return `${closest}`;
  }

  getCategory(): string {
    return this.layoutTheme.style?.is_story ? 'сюжет' : 'спецпроект';
  }

  hasSubtitle(): boolean {
    return this.layoutTheme.style?.subtitle?.length > 0;
  }

  getSubtitle(): string {
    return this.layoutTheme.style?.subtitle;
  }

  hasLink(): boolean {
    return this.layoutTheme.style?.link?.length > 0;
  }

  getLink(): boolean {
    return this.layoutTheme.style?.link;
  }

  getHeaderLink(): string {
    return 'spec/' + this.layoutTheme.slug;
  }

  needStylize(): boolean {
    return this.layoutTheme.style?.enabled && this.layoutTheme.is_special && !this.layoutTheme.is_special_page;
  }

  hasLogo(): boolean {
    return this.layoutTheme.style?.logo?.src?.length > 0;
  }

  getLogo(): string {
    return this.layoutTheme.style?.logo?.src;
  }

  getLogoClass(): string {
    return this.layoutTheme.style?.logoSize;
  }

  getLinkLogo(): string {
    return this.layoutTheme.style?.linkLogo;
  }

  isCollapses(): boolean {
    return (
      (this.layoutTheme.is_special &&
        !this.layoutTheme.is_special_page &&
        this.layoutTheme.style?.enabled &&
        this.detectedScreenChangeService.getScreenType() === 'mobile' &&
        this.longLineData.length > 0) ||
      (this.layoutTheme.type === 2 &&
        this.detectedScreenChangeService.getScreenType() === 'mobile' &&
        this.longLineData.length > 0)
    );
  }

  readMoreSpecClick() {
    ga('send', 'event', 'main_page', 'read_more_special');
    dataLayer.push({
      'event': 'event', 'eventCategory': 'main_page', 'eventAction': 'read_more_special'
    });
  }

  private buildLongLineData() {
    if (!this.layoutTheme?.items) return [];
    let longLineData = [];
    this.layoutTheme.items.forEach((e) => {
      longLineData = longLineData.concat(this.getLineData(e.layout_theme_layout_theme_item_id));
    });
    longLineData = JSON.parse(JSON.stringify(longLineData));
    longLineData.map((e) => {
      e.basis = longLineData.length > 1 ? '33' : '100';
      try {
        e.data.refresh = true;
        // eslint-disable-next-line no-empty
      } catch (ignore) {} // TODO   remove
      e.component = this.typeOfPost(e);
      return e;
    });
    return longLineData;
  }
  private typeOfPost(component: any) {
    const type = component.data.widget_style.name;
    switch (type) {
      case 'day number':
        return NumberOfDayComponent;
      case 'quote':
        return QuoteComponent;
      case 'advertisement':
        return AdvertisementComponent;
      default:
        return NewsComponent;
    }
  }

  addThemeItemToRender() {
    this.themeItemIds2Render = this.layoutThemeLayoutThemeItemIds
      .slice(0, this.themeItemIds2Render.length + 1)
      .map((themeItemId) => this.themeIndex.toString() + '.' + themeItemId.toString());
  }
}
