import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GaEventsService {
  constructor() {}

  mainPageWidgetClickCommon(slug: string) {
    ga('send', 'event', 'main_page', 'main_page_widget_click', `${slug}%`);
    dataLayer.push({
      'event': 'event', 'eventCategory': 'main_page', 'eventAction': 'main_page_widget_click', 'eventLabel': `${slug}%`
    });
  }
  // ToDo поянть, почему 2 слага
  mainPageWidgetClickSpecial(slug1: string, slug2: string, basis: string | number) {
    ga('send', 'event', `special_${slug1}`, slug2, `${basis}%`);
    dataLayer.push({
      'event': 'event', 'eventCategory': `special_${slug1}`, 'eventAction': slug2, 'eventLabel': `${basis}%`
    });
  }

  mainPageWidgetClickLightning(slug: string) {
    ga('send', 'event', 'main_page', 'main_page_widget_click', 'lightning', `${slug}%`);
    dataLayer.push({
      'event': 'event', 'eventCategory': 'main_page', 'eventAction': 'main_page_widget_click', 'eventLabel': 'lightning', 'eventAdditionalInfo': `${slug}%`
    });
  }
  mainPageWidgetClickNumbOfDay(slug: string) {
    ga('send', 'event', 'main_page', 'main_page_widget_click', 'number_of_day', `${slug}%`);
    dataLayer.push({
      'event': 'event', 'eventCategory': 'main_page', 'eventAction': 'main_page_widget_click', 'eventLabel': 'number_of_day', 'eventAdditionalInfo': `${slug}%`
    });
  }
  mainPageWidgetClickQuote(slug: string) {
    ga('send', 'event', 'main_page', 'main_page_widget_click', 'quote', `${slug}%`);
    dataLayer.push({
      'event': 'event', 'eventCategory': 'main_page', 'eventAction': 'main_page_widget_click', 'eventLabel': 'quote', 'eventAdditionalInfo': `${slug}%`
    });
  }
  externalPostShown(title: string) {
    ga('send', 'event', 'tiles', 'view', title);
    dataLayer.push({
      'event': 'event', 'eventCategory': 'tiles', 'eventAction': 'view', 'eventLabel': title
    });
  }
}
