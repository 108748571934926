import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'socialIconsFilter',
})
export class SocialIconsPipe implements PipeTransform {
  transform(value: {[k: string]: string}, args?: any) {
    if (!value) return value;
    return Object.entries(value).filter((val) => {
      if (val[0] === 'instagram' || val[0] === 'fb') return false;
      return val[0] !== '__typename' && val[1] && val[0] !== 'email' && val[0] !== 'etc_contacts';
    });
  }
}
