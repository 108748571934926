import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {LayoutLineHeightModule} from '@thebell/common/directives/layout-line-height';
import {AppendThemeItemModule} from '../append-theme-item';
import {LineComponent} from './line.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, AppendThemeItemModule, LayoutLineHeightModule],
  declarations: [LineComponent],
  exports: [LineComponent],
})
export class LineModule {}
