import {AfterViewInit, Directive, Inject, Input, PLATFORM_ID, ViewContainerRef} from '@angular/core';
import {GaEventsService} from '@thebell/common/services/others/ga-events';
import {isPlatformServer} from '@angular/common';

@Directive({
  selector: '[isVisible]',
})

/**
 * IS VISIBLE DIRECTIVE
 * --------------------
 * Mounts a component whenever it is visible to the user
 * Usage: <div *isVisible>I'm on screen!</div>
 */
export class IsVisibleDirective implements AfterViewInit {
  wasShown = false;
  @Input() id: number;
  @Input() title: string;
  constructor(
    private vcRef: ViewContainerRef,
    private ga: GaEventsService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>
  ) {}

  ngAfterViewInit() {
    if (isPlatformServer(this.platformId)) return;
    if (this.id !== 61) return;
    const observedElement = this.vcRef.element.nativeElement.parentElement;
    const observer = new IntersectionObserver(([entry]) => {
      this.renderContents(entry.isIntersecting);
    });
    observer.observe(observedElement);
  }

  renderContents(isIntersecting: boolean) {
    if (!this.wasShown && isIntersecting) {
      this.wasShown = true;
      this.ga.externalPostShown(this.title);
    }
  }
}
