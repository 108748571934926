<form *ngIf="!isThanksModal" [formGroup]="form" (submit)='submit($event)'>
  <div class="modal-title">Введите email
    <div *ngIf="textError" class="error">{{textError}}</div>
  </div>
  <div class="input-container">
    <input
      type="email"
      [formControlName]="'email'"
      placeholder="Email"
      [ngClass]="{'input-error': textError}">
  </div>
  <button type="submit" [disabled]="formIsInvalid || inProgress" class="modal-button">
    Авторизоваться
  </button>
  <re-captcha
    #captchaRef='reCaptcha'
    (resolved)='$event && callAuth($event)'
    [ngStyle]="{margin: '-10px auto'}"
    [siteKey]='siteKey'
    size='invisible'
  ></re-captcha>
</form>

<div *ngIf="isThanksModal" class="modal-wrapper" (click)="closeModal($event)">
  <div class="body">
    <div class="modal-title">Спасибо!</div>
    <p>
      Если указанный адрес зарегистрирован, на него придет письмо с инструкцией
    </p>
    <button class="modal-button" (click)="closeDialog()">
      Назад
    </button>
  </div>
</div>
