import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ImageModule} from '../../../../../ui/utils/src/lib/image/image.module';
import {NewsComponent} from './news.component';
import {IsVisibleModule} from '@thebell/common/directives/is-visible';
import {AdvertisingBoxModule} from '../../../../ui-utils/src/lib/advertising-box/advertising-box.module';

@NgModule({
  imports: [CommonModule, RouterModule, ImageModule, IsVisibleModule, AdvertisingBoxModule],
  declarations: [NewsComponent],
  exports: [NewsComponent],
})
export class NewsModule {}
