const ADS_TYPE = {
  ADFOX_SUPERFEATURE: {
    DESKTOP: 'adfox_superfeature_desktop',
    MOBILE: 'adfox_superfeature_mobile',
  },
};
export class SuperfeatureAdService {
  constructor() {}
  init(type: 'desktop' | 'mobile') {
    let ad;
    switch (type) {
      case 'desktop':
        ad = ADS_TYPE.ADFOX_SUPERFEATURE.DESKTOP;
        break;
      case 'mobile':
        ad = ADS_TYPE.ADFOX_SUPERFEATURE.MOBILE;
        break;
      default:
        ad = ADS_TYPE.ADFOX_SUPERFEATURE.MOBILE;
    }
    addAdFox(undefined, undefined, ad, 388377);
  }
}
