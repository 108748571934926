import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';

import {DeserializeWidgetDataService} from '@thebell/common/services/utils/deserialize-widget-data';
import {ThemeDataRequestOptions} from '@thebell/common/models/theme-data-request-options';
import {BaseModel} from '@thebell/common/models/base-model';
import {LayoutTheme} from '@thebell/common/models/layout-theme';
import {LayoutPreset} from '@thebell/common/models/layout-preset';
import {LayoutPresetItem} from '@thebell/common/models/layout-preset-item';
import {Post} from '@thebell/common/models/post';
import {Asset} from '@thebell/common/models/asset';
import {Author} from '@thebell/common/models/author';
import {LayoutThemeData} from '@thebell/common/models/layout-theme-data';
import {LastNewsletterUrls, SubscriptionData} from '@thebell/common/models/subscription-data';
import {EnvironmentService} from '@thebell/common/services/core/environment';
import {handleError} from '@thebell/common/utils';

@Injectable({
  providedIn: 'root',
})
export class ApiClientService {
  private apiUrl: string;
  private caches$: Observable<any>[] = [];
  // Запоминаем acceptedThemeId и acceptedPresetId для того чтобы если тему меняют во время просмотра страници,
  // данные выдывались по теме которюя в данный момент просматривают
  private acceptedThemeId = null;
  private acceptedPresetId = null;

  constructor(
    private envService: EnvironmentService,
    private http: HttpClient,
    private deserializeWidgetDataService: DeserializeWidgetDataService
  ) {
    this.apiUrl = envService.getEnvironment().apiUrl;
  }

  layoutThemes(): Observable<LayoutTheme[]> {
    const url = this.apiUrl + 'client_layout_themes';
    return this.http.get(url).pipe(
      catchError(handleError),
      tap((e) => {
        this.acceptedThemeId = (e as any).id;
      }),
      map((layoutThemes: BaseModel<LayoutTheme[]>) =>
        layoutThemes.data.map((layoutTheme) => new LayoutTheme().deserialize(layoutTheme))
      ),
      shareReplay()
    );
  }

  layoutPresets(): Observable<LayoutPreset[]> {
    const url = this.apiUrl + 'client_layout_presets';
    return this.http.get(url).pipe(
      catchError(handleError),
      tap((e) => {
        this.acceptedPresetId = (e as any).id;
      }),
      map((presets: BaseModel<LayoutPreset[]>) => {
        return presets.data.map((preset) => {
          preset.items.map((item) =>
            new LayoutPresetItem().deserialize(this.deserializeWidgetDataService.deserialize(item))
          );
          return new LayoutPreset().deserialize(preset);
        });
      }),
      shareReplay()
    );
  }

  layoutThemeData(options: ThemeDataRequestOptions): Observable<BaseModel<LayoutThemeData[][]>> {
    options.accepted_data_theme_id = this.acceptedThemeId;
    options.accepted_data_preset_id = this.acceptedPresetId;

    let params = new HttpParams();
    for (const param of Object.keys(options)) {
      params = params.set(param, options[param] ? options[param] : '');
    }
    return this.http
      .get(this.apiUrl + 'layout_theme_data/', {
        params,
      })
      .pipe(
        catchError(handleError),
        map((themeDatas: BaseModel<LayoutThemeData[][]>) => {
          const data = themeDatas.data.map((themeData) => {
            const res = themeData.map((item) => {
              return new LayoutThemeData().deserialize(this.deserializeWidgetDataService.deserialize(item));
            });
            return [...res];
          });
          return {...themeDatas, data};
        }),
        shareReplay()
      );
  }

  layoutThemePage(slug: string): Observable<any> {
    return this.http.get(this.apiUrl + `layout_theme_data/page/${slug}`).pipe(catchError(handleError));
  }

  setkaAssets() {
    return this.http.get<{data: Asset[]}>(`${this.apiUrl}setka_config`).pipe(
      catchError(handleError),
      map(({data}) => data),
      shareReplay()
    );
  }

  postByWpId(wpId, lang = 'ru'): Observable<Post> {
    const key = 'post_by_wp_id_' + lang + '_' + wpId;
    let params = new HttpParams();
    params = params.append('lang', lang);
    if (!this.caches$[key]) {
      this.caches$[key] = this.http.get(this.apiUrl + 'post_by_wp_id/' + wpId, {params}).pipe(
        catchError(handleError),
        map((res: BaseModel<Post>) => new Post().deserialize(res.data)),
        shareReplay()
      );
    }
    return this.caches$[key];
  }

  lastNewsletter(): Observable<LayoutThemeData> {
    return this.http.get(this.apiUrl + 'last_newsletter').pipe(
      map((lastNewsletter: BaseModel<LayoutThemeData>) =>
        new LayoutThemeData().deserialize(this.deserializeWidgetDataService.deserialize(lastNewsletter.data))
      ),
      catchError(handleError)
    );
  }

  lastNewsletterUrls(): Observable<LastNewsletterUrls> {
    return this.http.get(this.apiUrl + 'last_newsletter_urls').pipe(
      catchError(handleError),
      map((urls: BaseModel<LastNewsletterUrls>) => new LastNewsletterUrls().deserialize(urls.data))
    );
  }

  emailSubscription(data: SubscriptionData) {
    return this.http.post(this.apiUrl + 'email_subscription', data).pipe(catchError(handleError));
  }

  author(slug): Observable<BaseModel<Author>> {
    return this.http.get<BaseModel<Author>>(this.apiUrl + `authors/${slug}`).pipe(catchError(handleError));
  }

  features(): Observable<BaseModel<any>> {
    return this.http.get<BaseModel<any>>(this.apiUrl + 'public-features').pipe(catchError(handleError));
  }
}
