<div class='adfox-container'>
  <div class='adfox-container__banner'>
    <div class='adfox-preloader' *ngIf='adblock === undefined || !bannerLoaded'>
      <img width='40' height='40' src='/assets/images/spinner.gif' alt='loading'>
    </div>
    <div *ngIf='adblock' class='stab-wrapper'>
      <a href='/together'>
        <img src='/assets/images/banners/top-banner-stab-mob-new-clean.png' alt=''
             *ngIf='detectedScreenChangeService.getScreenType() === "mobile"; else elseBlock'>
      </a>
      <ng-template #elseBlock><img src='/assets/images/banners/top-banner-stab-desk-new-clean.png' alt=''></ng-template>
    </div>
    <div id='adfox-top-content'>
    </div>
  </div>
</div>
