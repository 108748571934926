export const blockedRoutes = [
  '/u-tezki-vedushhej-rossii-1-naili-asker-zade-nashli-uchastok-na-rublevke',
  '/proekt-rasskazal-ob-imushhestve-vtoroj-zheny-kadyrova-i-vtoroj-lichnosti-pervoj',
  '/proekt-rasskazal-o-sovladelitse-banka-rossiya-s-fenomenalno-pohozhej-na-putina-docheryu',
  '/proekt-zhena-kuma-putina-poluchila-rossijskij-npz-na-granitse-s-ukrainoj',
  '/proekt-nashel-svyaz-mezhdu-kvartiroj-vyacheslava-fetisova-v-nyu-jorke-i-loterej-gosloto',
  '/izdanie-proekt-podschitalo-zatraty-prigozhina-v-afrike',
  '/struktury-banka-rossiya-kupili-priglyanuvshuyusya-putinu-dachu-brezhneva-proekt',
  '/tezka-vedushhej-rossii-1-naili-asker-zade-prodala-uchastok-na-rublevke',
  '/proekt-nashel-sovladeltsa-ofshora-iz-panamskogo-arhiva-cherez-kotoryj-proshlo-2-mlrd',
  '/kak-glava-svr-sergej-naryshkin-polzovalsya-bassejnom-milliardera-goda-nisanova-v-razgar-vojny-v-karabahe-proekt',
  '/alkogol-i-avtoritety-iz-90-h-glavnoe-iz-rassledovaniya-proekta-o-ministre-vnutrennih-del',
  '/millioner-kadyrova-proekt-rasskazal-kak-moskovskij-rieltor-pomogaet-zarabatyvat-rukovodstvu-chechni',
  '/alkogol-i-avtoritety-iz-90-h-glavnoe-iz-rassledovaniya-proekta-o-ministre-vnutrennih-del',
  '/za-kogo-ugodno-golosujte-no-ne-za-nego-fbk-opublikoval-rassledovanie-o-seme-prezidenta-tatarstana',
  '/fbk-pokazal-ogromnyj-spa-kompleks-na-valdajskoj-dache-putina',
  '/fbk-opublikoval-rassledovanie-o-dvortse-putina-stoimostyu-100-mlrd-rublej',
  '/fbk-opublikoval-rassledovanie-navalnogo-pered-ego-otravleniem',
  '/hotel-zarabotat-nemnogo-deneg-chto-ne-tak-s-dohodami-mishustina',
];
