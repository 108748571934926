import {BaseWidget} from '../base';
import {Asset} from '@thebell/common/models/asset';

export class Quote extends BaseWidget {
  author: string;
  author_description: string;
  quote: string;
  title?: string;
  background: string;
  // eslint-disable-next-line id-blacklist
  number?: string;
  text?: string;
  type: string;
}
