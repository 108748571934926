import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class Error404Interceptor implements HttpInterceptor {
  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Record<string, any>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestingUrl = this.router.url;
    return next.handle(request).pipe(
      catchError((evt) => {
        if (evt instanceof HttpErrorResponse) {
          if (evt.status === 404) {
            if (this.router.url === requestingUrl) {
              this.router.navigate(['/404'], {skipLocationChange: true});
            }
          }
        }
        if (isPlatformBrowser(this.platformId)) {
          return throwError(evt);
        } else {
          // return throwError(`${request.url} not found, redirect to /404`);
        }
      })
    );
  }
}
