import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {User} from '@thebell/common/models/user';
import {BaseModel} from '@thebell/common/models/base-model';
import {Environment, EnvironmentService} from '@thebell/common/services/core/environment';
import {ILaravelCredentials} from '@thebell/common/models/credentials';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public $error$: Subject<string> = new Subject<string>();
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private environment: Environment;
  private authUrl: string;
  static logoutTimeout: number;
  static logoutObs = new Subject<'logout'>();
  private static tokenPrefix: string;
  private tokenPrefix: string;

  constructor(private envService: EnvironmentService, private http: HttpClient) {
    this.environment = this.envService.getEnvironment();
    this.authUrl = `${this.environment.baseUrl}oauth/token`;
    this.tokenPrefix = 'auth_'; // Префикс для ключей токенов
  }

  get laravelToken(): string {
    const expDate = new Date(localStorage.getItem(`${this.tokenPrefix}token-expired`));
    if (new Date() > expDate) {
      AuthService.logout();
      return null;
    }
    return localStorage.getItem(`${this.tokenPrefix}laravel_access_token`);
  }

  get nestToken(): string {
    const expDate = new Date(localStorage.getItem(`${this.tokenPrefix}token-expired`));
    if (new Date() > expDate) {
      AuthService.logout();
      return null;
    }
    return localStorage.getItem(`${this.tokenPrefix}nest_access_token`);
  }

  get user() {
    return localStorage.getItem(`${this.tokenPrefix}currentUser`);
  }
  static logoutTimer(expires_in: number | null): Subject<'logout'> {
    if (expires_in) {
      AuthService.logoutTimeout = window.setTimeout(() => {
        AuthService.logoutObs.next('logout');
      }, expires_in * 1000);
    } else {
      clearTimeout(AuthService.logoutTimeout);
    }
    return AuthService.logoutObs;
  }

  static setToken(
    response: {
      laravel_access_token: string;
      nest_access_token: string;
      expires_in: number;
    } | null
  ) {
    this.tokenPrefix = 'auth_';
    if (response) {
      const expDate = new Date(new Date().getTime() + response.expires_in * 1000);
      localStorage.setItem(`${this.tokenPrefix}laravel_access_token`, response.laravel_access_token);
      localStorage.setItem(`${this.tokenPrefix}nest_access_token`, response.nest_access_token);
      localStorage.setItem(`${this.tokenPrefix}token-expired`, expDate.toString());
    } else {
      localStorage.removeItem(`${this.tokenPrefix}laravel_access_token`);
      localStorage.removeItem(`${this.tokenPrefix}nest_access_token`);
      localStorage.removeItem(`${this.tokenPrefix}token-expired`);
      localStorage.removeItem(`${this.tokenPrefix}currentUser`);
      AuthService.logoutTimer(null);
      const lastPage = localStorage.getItem('history');
      if (lastPage !== null && lastPage !== '') {
        localStorage.setItem('history', lastPage);
      }
    }
  }

  static logout() {
    AuthService.setToken(null);
  }

  login(user: User): Observable<ILaravelCredentials> {
    const body = {
      grant_type: 'password',
      client_id: this.environment.oauth_client_id,
      client_secret: this.environment.oauth_client_secret,
      username: user.email,
      password: user.password,
      scope: '*',
    };

    return this.http.post<ILaravelCredentials>(this.authUrl, body, this.httpOptions);
  }

  isAuthenticated(): boolean {
    const laravelToken = localStorage.getItem(`${this.tokenPrefix}laravel_access_token`);
    const nestToken = localStorage.getItem(`${this.tokenPrefix}nest_access_token`);
    const currentUser = localStorage.getItem(`${this.tokenPrefix}currentUser`);
    return !!laravelToken && !!nestToken && !!currentUser;
  }

  isLogin;

  changePassword(body: {
    email: string;
    old_password: string;
    new_password: string;
  }): Observable<{error: boolean; msg: string}> {
    return this.http.post<{error: boolean; msg: string}>(`${this.environment.baseUrl}api/v1/passwd/change`, body);
  }

  resetPass(body: {email: string}) {
    return this.http.post<{error: boolean; msg: string}>(`${this.environment.baseUrl}api/v1/passwd/reset`, body);
  }

  restorePass(body: {password: string; token: string}) {
    return this.http.post<{error: boolean; msg: string}>(`${this.environment.baseUrl}api/v1/passwd/set`, body);
  }

  getUser(): Observable<BaseModel<User>> {
    return this.http.get<BaseModel<User>>(`${this.environment.apiAdminUrl}user`);
  }
}
