import {Widget} from '@thebell/common/models/widget';
import {Deserializable} from '@thebell/common/models/deserializable';

export class LayoutLine extends Deserializable {
  id: number;
  name: string;
  slug: string;
  description: string;
  widgets: Widget[];
  sort: number;
}
