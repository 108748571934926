import {Injectable} from '@angular/core';
import {ApiClientService} from '@thebell/common/services/api/api-client';
import {HelperService} from '@thebell/common/services/utils/helper';
import {Observable} from 'rxjs';
import {LayoutPreset} from '@thebell/common/models/layout-preset';
import {ApiAdminService} from '@thebell/admin/services/api/api-admin';

interface ICaches {
  adminPresets?: Observable<LayoutPreset[]>;
  clientPresets?: Observable<LayoutPreset[]>;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutPresetsService {
  private caches$: ICaches = {};

  constructor(
    private apiClientService: ApiClientService,
    private apiAdminService: ApiAdminService,
    private helperService: HelperService
  ) {}

  public getLayoutPresets(forceRefresh = false): Observable<LayoutPreset[] | null> {
    if (!this.caches$.clientPresets || forceRefresh) this.caches$.clientPresets = this.apiClientService.layoutPresets();
    return this.caches$.clientPresets;
  }

  public getAdminLayoutPresets(forceRefresh = this.helperService.inAdmin()): Observable<LayoutPreset[] | null> {
    if (!this.caches$.adminPresets || forceRefresh) this.caches$.adminPresets = this.apiAdminService.layoutPresets();
    return this.caches$.adminPresets;
  }
}
