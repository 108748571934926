import {Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {UuidUtil} from '@thebell/common/services/utils/uuid';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {WIDGET_DATA} from '@thebell/common/injection-tokens/widget-data';
import {Advertisement} from '@thebell/common/models/widget-style-data';
import {HelperService} from '@thebell/common/services/utils/helper';
import {AdScriptService} from '@thebell/common/services/others/ad-script';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-widgets-for-money',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss'],
})
export class AdvertisementComponent implements OnInit {
  @ViewChild('widget_container') zenEl: ElementRef;
  @ViewChild('ads_1') ads_1: ElementRef;
  @ViewChild('ad_sidebar') addFoxEl: ElementRef;

  @Input() data: {data: Advertisement};
  name = '';
  props: any = {};
  advertisement_provider_id: number;
  uuid = new UuidUtil().generator();
  adminPreview: boolean;

  inAdmin = () => this.helperService.inAdmin();

  constructor(
    private detectedScreenChangeService: DetectedScreenChangeService,
    @Inject(WIDGET_DATA) private dataInject: {data: Advertisement},
    private helperService: HelperService,
    private adScriptService: AdScriptService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.name = this.dataInject.data.title;
      this.advertisement_provider_id = this.dataInject.data.advertisement_provider_id;
      this.props = JSON.parse(this.dataInject.data.json);
      this.adminPreview = this.dataInject.data.adminPreview;
      if (!this.inAdmin() && this.show) {
        if (this.advertisement_provider_id === 3) {
          this.adScriptService.scriptLoaded('smi2').subscribe((res) => {
            if (res) this.addScriptSmi2();
          });
        }
        if (this.advertisement_provider_id === 2) {
          this.adScriptService.scriptLoaded('adfox').subscribe((res) => {
            if (res) this.addScriptFox();
          });
        }
        if (this.advertisement_provider_id === 1) {
          this.adScriptService.scriptLoaded('zen').subscribe((res) => {
            if (res) this.addScriptZen();
          });
        }
        if (this.advertisement_provider_id === 4) {
          this.adScriptService.scriptLoaded('rtb').subscribe((res) => {
            if (res) this.addScriptRtb();
          });
        }
      }
    }
  }

  get show() {
    return !(
      this.props.displayMobile !== undefined &&
      this.detectedScreenChangeService.getScreenType() === 'mobile' &&
      !+this.props.displayMobile
    );
  }

  addScriptSmi2() {
    setTimeout(() => addSmi2(this.ads_1.nativeElement, (this.props as any).smi2Id));
  }

  addScriptFox() {
    setTimeout(() => addAdFox(this.uuid, this.props.params, this.props.type, +this.props.ownerId || undefined), 0);
  }

  addScriptZen() {
    setTimeout(() => addZen(this.uuid, this.detectedScreenChangeService.getScreenType(), this.props));
  }

  addScriptRtb() {
    setTimeout(() => yandexRtb(this.uuid));
  }
}
