import { Response } from 'express';
import fetch from 'node-fetch';

export const TOKEN_NAME = 'x-auth-subscription-token';
export const PRO_TOKEN_NAME = 'x-auth-pro-subscription-token';

const CHECK_QUERY = `query checkSubscriptionPost(
  $slug: String!
) {
  checkSubscriptionPost(slug: $slug)
}`;

const checkSubscription = async (key: string, token: string, tokenName: string) => {
  const [, slug] = key.split(':');
  if (slug.length) {
    try {
      const response = await fetchQuery(CHECK_QUERY, { slug }, { [tokenName]: token });
      console.log(response.data?.checkSubscriptionPost);
      if (response.data?.checkSubscriptionPost) {
        return `${key}:paid`;
      }
    } catch (e) {
      console.log('SUBSCRIPTION AUTH EXCEPTION', e);
    }
  }
  return key;
};

export const transformPostsKey = async (key: string, res: Response) => {
  console.log('POST TRANSFORM FUNCTION');
  const token = res.locals?.localStorage?.getItem(TOKEN_NAME) || null;
  const proToken = res.locals?.localStorage?.getItem(PRO_TOKEN_NAME) || null;

  console.log('LOCAL STORAGE TOKEN:', token);
  console.log('LOCAL STORAGE PRO TOKEN:', proToken);

  if (token && token.length) {
    key = await checkSubscription(key, token, TOKEN_NAME);
  }

  if (proToken && proToken.length) {
    key = await checkSubscription(key, proToken, PRO_TOKEN_NAME);
  }

  return key;
};

const fetchQuery = async (query, params, headers) => {
  console.log({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...headers,
  });
  const result = await fetch(process.env.CLIENT_HOST_GRAPHQL_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
    body: JSON.stringify({
      query,
      variables: params,
    }),
  });
  return await result.json();
};
