import {NgModule} from '@angular/core';
import {LayoutLineHeightModule} from '@thebell/common/directives/layout-line-height';
import {WidgetHeightModule} from '@thebell/common/directives/widget-height';
import {CommonUiUtilsModule} from '@thebell/common/ui/utils';
import {CommonUiWidgetsModule} from '@thebell/common/ui/widgets';
import {
  CommonUiSubscriptionModule,
  PostSubscriptionComponent,
  SubscriptionDialogComponent,
} from '@thebell/common/ui/subscription';
import {POST_SUBSCRIPTION_COMPONENT} from '@thebell/common/injection-tokens/post-subscription-component';
import {SUBSCRIPTION_DIALOG_COMPONENT} from '@thebell/common/injection-tokens/subscription-dialog-component';
import {BottomStickyDialogModule} from './component/bottom-sticky-dialog';
import {InfiniteScrollModule} from './component/infinite-scroll';
import {ThemeModule} from './component/theme';
import {WidgetScrollerModule} from './component/widget-scroller';

@NgModule({
  exports: [
    BottomStickyDialogModule,
    InfiniteScrollModule,
    ThemeModule,
    WidgetScrollerModule,
    LayoutLineHeightModule,
    WidgetHeightModule,
    CommonUiUtilsModule,
    CommonUiWidgetsModule,
    CommonUiSubscriptionModule,
  ],
  // FixMe: Kostyl' for break circular dependency
  providers: [
    {provide: SUBSCRIPTION_DIALOG_COMPONENT, useValue: SubscriptionDialogComponent},
    {provide: POST_SUBSCRIPTION_COMPONENT, useValue: PostSubscriptionComponent},
  ],
})
export class CommonUiModule {}
