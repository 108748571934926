import {gql} from 'apollo-angular';

export const POSTS_GET_QUERY = gql`
  query getPosts(
    $first: Int
    $after: String
    $title: String
    $status: String
    $author: String
    $category: String
    $tag: String
    $published_at: [Timestamp!]
    $created_at: [Timestamp!]
    $orderDirection: String
    $orderBy: String
    $lang: String
  ) {
    posts(
      first: $first
      after: $after
      title: $title
      status: $status
      author: $author
      category: $category
      tag: $tag
      lang: $lang
      orderDirection: $orderDirection
      published_at: $published_at
      created_at: $created_at
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          status
          author
          authors {
            name_ru
          }
          created_at
          published_at
        }
      }
    }
  }
`;

export const POST_TOPIC_GET_QUERY = gql`
  query getPostTopic($id: Int!) {
    post(id: $id) {
      slug
      title
      assets {
        src
        mode
        postfix
        prefix
        subject
        path
      }
    }
    postWidgetStyle(postId: $id) {
      data
    }
  }
`;

export const POST_PAID_CONTENT_GET_QUERY = gql`
  query postPaidContent($id: Int!) {
    postPaidContent(postId: $id) {
      id
      content
      content_rss
      content_amp
    }
  }
`;

export const POST_GET_QUERY = gql`
  query getPost($id: Int!) {
    post(id: $id) {
      content
      raw_content
      content_rss
      content_amp
      slug
      title
      status
      id
      published_at
      authors {
        author_image
        id
        name_ru
        slug
        contacts {
          email
          telegram
          twitter
          vk
        }
        description_ru
      }
      source {
        id
        text
      }
      source_link
      tags {
        id
        title
      }
      categories {
        id
        title
      }
      assets {
        id
        src
        width
      }
      is_was_published
      show_on_main
      advertisement_ban
    }
  }
`;

export const POST_PUBLISHED_GET_QUERY = gql`
  query getPublishedPost($slug: String!) {
    published_post(slug: $slug) {
      allowed
      content
      slug
      title
      subtitle
      status
      id
      published_at
      authors {
        author_image
        id
        name_ru
        slug
        contacts {
          email
          fb
          instagram
          telegram
          twitter
          vk
        }
        description_ru
      }
      source {
        id
        text
      }
      source_link
      tags {
        id
        title
        slug
      }
      categories {
        id
        title
        slug
      }
      assets {
        id
        src
        version
        width
      }
      is_was_published
      show_on_main
      advertisement_ban
    }
  }
`;

export const BIND_ASSETS_QUERY = gql`
  mutation bindAssets($bindAssetInput: BindAssetInput!, $postId: Int!) {
    bindAssets(bindAssetInput: $bindAssetInput, postId: $postId) {
      id
      src
      version
      width
      subject
    }
  }
`;

export const POST_CREATE_QUERY = gql`
  mutation createPost(
    $createPostInput: CreatePostInput!
    $postMetaInput: PostMetaInput
    $postMetaWidgetInput: PostMetaWidgetInput
    $bindAssetInput: BindAssetInput
    $feedSettingsInput: FeedSettingsInput
  ) {
    createPost(
      createPostInput: $createPostInput
      postMetaInput: $postMetaInput
      postMetaWidgetInput: $postMetaWidgetInput
      bindAssetInput: $bindAssetInput
      feedSettingsInput: $feedSettingsInput
    ) {
      id
      title
    }
  }
`;

export const POST_DELETE_QUERY = gql`
  mutation deletePost($id: Int!) {
    deletePost(id: $id) {
      id
    }
  }
`;

export const POST_PUBLIC_QUERY = gql`
  mutation postPublic(
    $updatePostInput: UpdateOrCreatePostInput!
    $postMetaInput: PostMetaInput
    $postMetaWidgetInput: PostMetaWidgetInput
    $bindAssetInput: BindAssetInput
    $feedSettingsInput: FeedSettingsInput
  ) {
    postPublic(
      updateOrCreatePostInput: $updatePostInput
      postMetaInput: $postMetaInput
      postMetaWidgetInput: $postMetaWidgetInput
      bindAssetInput: $bindAssetInput
      feedSettingsInput: $feedSettingsInput
    ) {
      id
      title
      status
      slug
      published_at
    }
  }
`;

export const POST_PUBLIC_FORCE_QUERY = gql`
  mutation postPublicForce(
    $updatePostInput: UpdatePostInput!
    $postMetaInput: PostMetaInput
    $postMetaWidgetInput: PostMetaWidgetInput
    $bindAssetInput: BindAssetInput
    $feedSettingsInput: FeedSettingsInput
  ) {
    postPublicForce(
      updatePostInput: $updatePostInput
      postMetaInput: $postMetaInput
      postMetaWidgetInput: $postMetaWidgetInput
      bindAssetInput: $bindAssetInput
      feedSettingsInput: $feedSettingsInput
    ) {
      id
      title
      status
      published_at
    }
  }
`;

export const POST_RETURN_TO_DRAFT_QUERY = gql`
  mutation postReturnToDraft(
    $updatePostInput: UpdatePostInput!
    $postMetaInput: PostMetaInput
    $postMetaWidgetInput: PostMetaWidgetInput
    $bindAssetInput: BindAssetInput
    $feedSettingsInput: FeedSettingsInput
  ) {
    postReturnToDraft(
      updatePostInput: $updatePostInput
      postMetaInput: $postMetaInput
      postMetaWidgetInput: $postMetaWidgetInput
      bindAssetInput: $bindAssetInput
      feedSettingsInput: $feedSettingsInput
    ) {
      id
      title
      status
    }
  }
`;

export const POST_UPDATE_QUERY = gql`
  mutation updatePost(
    $updatePostInput: UpdatePostInput!
    $postMetaInput: PostMetaInput
    $postMetaWidgetInput: PostMetaWidgetInput
    $bindAssetInput: BindAssetInput
    $feedSettingsInput: FeedSettingsInput
  ) {
    updatePost(
      updatePostInput: $updatePostInput
      postMetaInput: $postMetaInput
      postMetaWidgetInput: $postMetaWidgetInput
      bindAssetInput: $bindAssetInput
      feedSettingsInput: $feedSettingsInput
    ) {
      id
      title
      status
    }
  }
`;

export const AUTHORS_GET_QUERY = gql`
  query getAuthors($first: Int, $after: String, $name: String) {
    authors(first: $first, after: $after, name: $name) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name_ru
        }
      }
    }
  }
`;

// ///////////// META /////////////////////
export const POST_META_GET_QUERY = gql`
  query getPostMetaQuery($postId: Int!) {
    postMeta(postId: $postId) {
      description
      image
      title
    }
  }
`;

export const POST_META_UPDATE = gql`
  mutation updateMeta($postMeta: PostMetaInput!) {
    savePostMeta(postMeta: $postMeta) {
      id
    }
  }
`;

export const POST_META_WIDGET_GET_QUERY = gql`
  query getPostMetaWidgetQuery($postId: Int!) {
    postMetaWidget(postId: $postId) {
      background_type
      caption
      hide_logo
      id
      image
      post_id
    }
  }
`;

export const POST_META_WIDGET_UPDATE = gql`
  mutation updateWidgetMeta($postMetaWidget: PostMetaWidgetInput!) {
    savePostMetaWidget(postMetaWidget: $postMetaWidget) {
      id
    }
  }
`;

// ///////////// META /////////////////////

// ///////////// STYLE /////////////////////
export const POST_STYLE_GET_QUERY = gql`
  query getPostStyleQuery($postId: Int!) {
    postWidgetStyle(postId: $postId) {
      data
      name
      allowed_widget_sizes
    }
  }
`;

export const POST_STYLE_UPDATE = gql`
  mutation updateStyle($postWidgetStyle: WidgetStyleInput!) {
    updatePostWidgetStyle(postWidgetStyle: $postWidgetStyle) {
      post_id
    }
  }
`;
// ///////////// STYLE /////////////////////

export const POST_ASSETS = gql`
  query getAssetsQuery($id: Int!, $type: String!) {
    assets(id: $id, type: $type) {
      id
      src
      subject
      mode
    }
  }
`;

export const FEED_SETTINGS = gql`
  query getFeedSettings($post_id: Int!, $feed_key: String) {
    feed_settings(post_id: $post_id, feed_key: $feed_key) {
      key
      value
    }
  }
`;

export const EXTERNAL_POSTS_BY_CATEGORY = gql`
  query getExternalPostsByCategory($first: Int, $after: String, $category_title: String) {
    external_posts(first: $first, after: $after, category_title: $category_title) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
        }
      }
    }
  }
`;
