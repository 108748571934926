<div class="wrap" [class.error-wrapper]="isError">
  <div>
    <app-adfox-top></app-adfox-top>
    <app-header></app-header>
  </div>
  <div id="adfox_superfeature"></div>
  <router-outlet></router-outlet>
  <app-bottom-sticky-dialog></app-bottom-sticky-dialog>
  <app-footer></app-footer>
</div>
