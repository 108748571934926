import * as Plugin from 'vanilla-cookieconsent';
import ru from './translations/ru';
import {Category} from '../contracts/types';

export const consentWidgetConfig: Plugin.CookieConsentConfig = {
  manageScriptTags: false,

  guiOptions: {
    consentModal: {
      layout: 'cloud inline',
      position: 'bottom center',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  categories: {
    [Category.Necessaries]: {
      enabled: true,
      readOnly: true,
    },
    [Category.Analytics]: {
      enabled: false,
    },
    [Category.Ads]: {
      enabled: false,
    },
  },

  language: {
    default: 'ru',
    translations: {
      ru,
    },
  },
};
