<div class="banner-wrapperer">
  <div class="banner-content">
    <div class="banner-content-title">
      <img class='locker' src='/assets/images/icons/locker.svg'>
      <h2>Вы видите только часть этого материала</h2>
    </div>
    <p >
      Этот материал был написан специально для нашей еженедельной рассылки
      Bell.Инвестиции. Это платный продукт. Чтобы прочитать статью целиком
      и иметь доступ ко всему контенту The Bell для инвесторов, подпишитесь
      на рассылку.
    </p>
    <button id='invest_subscribe_button' (click)="setSuccessLinkAndNavigate('/invest')">Подписаться</button>
    <button id='invest_login_button' *ngIf="!isUserAuthorized" class="accent" (click)="setSuccessLinkAndNavigate('/account')">Я уже подписчик</button>
  </div>
</div>
