<div class="content">
    <span *ngIf="dialogRef" class="close" (click)="onModalClose()"></span>
    <form class="subscription" #subscriptionForm="ngForm">
        <div class="subscription_head">Рассылки The Bell</div>
        <div class="subscription_title">Выберите рассылки, которые подходят именно вам</div>
        <div class="subscription_form_wrapper">
            <div *ngIf="!subscribed; else successSubscribeBlock" class="subscription_form">
                <input
                    class="email"
                    #email="ngModel"
                    [ngClass]="{warn: email.invalid && email.value}"
                    placeholder="E-mail"
                    type="email"
                    name="email"
                    [(ngModel)]="subscriptionData.email"
                    required
                    minlength="3"
                    email
                />
                <app-button
                    buttonClass="default subscribe_form"
                    [class.disabled]="subscriptionForm.invalid || subscriptionTypesChecker() || loading"
                    [disabled]="subscriptionForm.invalid || subscriptionTypesChecker() || loading"
                    buttonText="Подписаться"
                    (buttonClick)="subscribe()"
                ></app-button>
            </div>
            <ng-template #successSubscribeBlock>
                <div class="success-subscription-block">
                    <img src="assets/images/envelope.svg" />
                    <div class="title">Спасибо!</div>
                    <div class="promt">Проверьте свою почту и подтвердите e-mail по ссылке</div>
                </div>
            </ng-template>
        </div>
        <div class="subscription_cards">
            <div
                [class]="'subscription_card ' + subscriptionType.slug"
                *ngFor="let subscriptionType of subscriptionTypes"
            >
                <div class="subscription_card_title">
                    <div class="subscription_card_title_text">{{ subscriptionType.title }}</div>
                    <app-switch-button
                        [isChecked]="subscriptionData.newsletterTypes[subscriptionType.slug]"
                        (switchClick)="clickSubscription($event, subscriptionType.slug)"
                    ></app-switch-button>
                </div>
                <div class="subscription_card_description">{{ subscriptionType.description }}</div>
                <a
                    class="subscription_card_bottom"
                    [routerLink]="lastNewsletterUrls ? ['/', lastNewsletterUrls[subscriptionType.slug]] : []"
                    target="_blank"
                >
                    {{ subscriptionType.bottom }}
                </a>
            </div>
        </div>
    </form>
</div>
