<form class="grid-container" #subscriptionForm="ngForm">
    <ng-container *ngIf='!subscribed'>
    <span class="title">
        Подпишитесь на рассылку The&nbsp;Bell!
    </span>
    <div class="subscription_cards">
        <div
            *ngFor="let subscriptionType of subscriptionTypes"
            [class]="'subscription_card ' + subscriptionType.slug"
        >
            <div class="subscription_card_title">
                <app-switch-button class="min"
                                   (switchClick)="clickSubscription($event, subscriptionType.slug)"
                                   [isChecked]="subscriptionData.newsletterTypes[subscriptionType.slug]"
                ></app-switch-button>
                <div class="subscription_card_title_text">{{ subscriptionType.title }}</div>
            </div>
        </div>
        <!--после "вечерняя" брейк начиная с small-desktop-->
        <div class="break-small-desktop" id="evening_break"></div>
    </div>
    <div class="subscription subscription_transform">
        <input placeholder="E-mail" type="email" name="email" [(ngModel)]="subscriptionData.email" required minlength="3"
               email />
        <app-button
            buttonText="Подписаться"
            (buttonClick)="subscribe()"
            [disabled]="subscriptionForm.invalid || subscriptionTypesChecker() || loading"
            [buttonClass]="subscriptionForm.valid ? 'default subscription-enabled' : 'default subscription-disabled'"
        ></app-button>
    </div>
    </ng-container>
    <ng-container *ngIf="subscribed">
        <div class='thanks'>
            <div class="thanks-header">Вы почти подписались!</div>
            <div class='thanks-text'>Чтобы подтвердить подписку, перейдите<br/>
                по ссылке в письме, которое мы вам<br/>
                отправили</div>
        </div>
    </ng-container>
</form>
