import {Injectable} from '@angular/core';
import {ApiClientService} from '@thebell/common/services/api/api-client';
import {ApiAdminService} from '@thebell/admin/services/api/api-admin';
import {Observable} from 'rxjs';
import {widgetsMap} from '@thebell/common/ui/widgets';
import {HelperService} from '@thebell/common/services/utils/helper';
import {LayoutTheme} from '@thebell/common/models/layout-theme';
import {LayoutLine} from '@thebell/common/models/layout-line';
import {WidgetStyle} from '@thebell/common/models/widget-style';
import {WidgetItem} from '@thebell/common/models/widget-item';

interface ICaches {
  adminThemes?: Observable<LayoutTheme[]>;
  clientThemes?: Observable<LayoutTheme[]>;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutThemesService {
  private cache$: Observable<LayoutTheme[]>;
  private caches$: ICaches = {};
  private fakeNewsItemData = {
    component: widgetsMap.NewsComponent,
    widget_style: {
      id: 1,
      name: 'news',
      slug: 'NewsComponent',
      fields: [
        {field: 'title', required: false, type: 'string'},
        {field: 'background', required: false, type: 'color', default: '#FFFFFF'},
        {field: 'background_image', required: false, type: 'image'},
        {
          field: 'main_image_as_background',
          required: false,
          type: 'boolean',
          default: false,
        },
      ],
    },
    widgetStyle: {},
  };

  private adComponent = {
    component: widgetsMap.AdvertisementComponent,
    widget_style: {
      name: 'advertisement',
      slug: 'AdvertisementComponent',
    },
    widgetStyle: {
      name: 'advertisement',
      slug: 'AdvertisementComponent',
    },
  };
  // itemData.widgetStyle = new WidgetStyle().deserialize(itemData.widget_style);

  constructor(
    private apiClientService: ApiClientService,
    private apiAdminService: ApiAdminService,
    private helperService: HelperService
  ) {
    this.fakeNewsItemData.widgetStyle = new WidgetStyle().deserialize(this.fakeNewsItemData.widget_style);
  }

  public getLayoutThemes(forceRefresh = false): Observable<LayoutTheme[] | null> {
    if (!this.caches$.clientThemes || forceRefresh) this.caches$.clientThemes = this.apiClientService.layoutThemes();
    return this.caches$.clientThemes;
  }

  public getAdminLayoutThemes(forceRefresh = this.helperService.inAdmin()): Observable<LayoutTheme[] | null> {
    if (!this.caches$.adminThemes || forceRefresh) this.caches$.adminThemes = this.apiAdminService.layoutThemes();
    return this.caches$.adminThemes;
  }

  setData(theme, data, presets, themeIndex, tagPage = false) {
    const preset = presets.find((elem) => {
      return elem.name === theme.name;
    });

    // eslint-disable-next-line
    const result = {} as any;
    for (const themeItem of theme.items) {
      if (themeIndex > 0 && !themeItem.repeatable) continue;
      // если это линия и тема первая или тема не первая, но повторяемая линия
      if (themeItem.model === 'LayoutLine' && (themeIndex === 0 || (themeIndex > 0 && themeItem.repeatable))) {
        const lineData = [];
        const layoutLine = new LayoutLine().deserialize(themeItem.item);
        for (let i = 0; i < layoutLine.widgets.length; i++) {
          // находим пресет для позиции на линии или не находим
          let itemData;
          // выбираем все пресеты для линии
          if (preset) {
            itemData = preset.items
              .filter(
                (presetItem) =>
                  presetItem.layout_theme_layout_theme_item_id === themeItem.layout_theme_layout_theme_item_id &&
                  (presetItem.repeatable || themeIndex === 0)
              )
              .find((item) => item.position - 1 === i);
            if (itemData) {
              itemData = Object.assign({preset_item_id: itemData.id}, itemData);
              itemData = this.spoofWidget(
                itemData,
                layoutLine.widgets[i].basis,
                themeItem.layout_theme_layout_theme_item_id,
                theme
              );
              lineData[i] = new WidgetItem(itemData.component, itemData, layoutLine.widgets[i].basis);
              continue;
            }
          }

          // если не нашли пресет или нашли, но тема не первая и пресет не повторяемый,
          // то данные для виджета нужно взять из общих данных
          if (!itemData && data.length > 0) {
            if (layoutLine.slug === 'four_25') {
              const postIndex = data.findIndex((post) => post.item.allowed_widget_sizes === '25');
              if (postIndex === -1) {
                itemData = {
                  component: widgetsMap.EmptyComponent,
                  widget_style: {
                    name: 'empty',
                    slug: 'EmptyComponent',
                  },
                };
              } else {
                itemData = data.splice(postIndex, 1)[0];
              }
            } else {
              itemData = data.shift();
            }
            itemData.position = i + 1;
            itemData = this.spoofWidget(
              itemData,
              layoutLine.widgets[i].basis,
              themeItem.layout_theme_layout_theme_item_id,
              theme
            );
            lineData[i] = new WidgetItem(itemData.component, itemData, layoutLine.widgets[i].basis);
          }
        }
        // проверка по длине только для страниц тегов

        result[themeItem.layout_theme_layout_theme_item_id] = lineData;
      }

      if (themeItem.model === 'LayoutThemes' && (themeIndex === 0 || (themeIndex > 0 && themeItem.repeatable))) {
        for (const presetTheme of themeItem.item) {
          result[presetTheme.layout_theme_layout_theme_item_id] = this.setData(
            presetTheme as LayoutTheme,
            [...data],
            presets,
            themeIndex
          );
        }
      }
    }

    return result;
  }

  private spoofWidget(itemData, basis, lineId, theme) {
    // Todo Воможно надо будет прокидывать это с бэка
    if (itemData.fixed_model_name === 'App\\Models\\Advertisement') {
      return {...itemData, ...this.adComponent};
    }
    if (itemData.widget_style.slug === 'LightningComponent') {
      return this.spoofLightning(itemData, basis, lineId, theme);
    }
    if (itemData.widget_style.slug !== 'NewsComponent' && basis === '25') {
      itemData = {...itemData, ...this.fakeNewsItemData};
      return itemData;
    }
    return itemData;
  }

  private spoofLightning(itemData, basis, lineId, theme) {
    // проверка, что молния стоит в первой линии с базисом 100
    // если это не так, то меняем на новость

    const firstLine = (asd) => {
      if (asd[0].model === 'LayoutLine') return asd[0];
      else return false;
    };
    const firstLineId = firstLine(theme.items).layout_theme_layout_theme_item_id;

    if (basis !== '100' || firstLineId !== lineId) {
      itemData = {...itemData, ...this.fakeNewsItemData};
    }

    return itemData;
  }
}
