import {Injectable} from '@angular/core';
import {additionalCategory, IAdditionalCategory} from '@thebell/common/models/additional-category';
@Injectable({
  providedIn: 'root',
})
export class AdditionalCategoryService {
  getAdCatSpec(adCat: additionalCategory): IAdditionalCategory {
    switch (adCat) {
      case additionalCategory.bellClub:
        return {
          name: 'Bell Club',
          svg: 'bellClub.svg',
          link: 'https://thebell.club/',
        };
      case additionalCategory.bellClubLive:
        return {
          name: 'Bell Club Live',
          svg: 'bellClubLive.svg',
          link: 'https://www.youtube.com/channel/UC7_VjEuPizTvqvPWr8NLnTA',
        };
      case additionalCategory.inSchool:
        return {
          name: 'inSchool',
          svg: 'inSchool.svg',
          link: 'https://inschool.ai/',
        };
      case additionalCategory.russiansAreCool:
        return {
          name: 'Русские норм',
          svg: 'russiansAreCool.svg',
          link: 'https://www.youtube.com/channel/UCo3S9OQDrM79i7yPmixnSsQ',
        };
      case additionalCategory.exclusive:
        return {
          name: 'эксклюзив',
          svg: '',
          link: '',
        };
      default:
        return {
          name: '',
          svg: '',
          link: '',
        };
    }
  }
  getAllAdCategories() {
    return Object.keys(additionalCategory).map((el) => additionalCategory[el]);
  }
}
