
export const environment = {
  production: true,
  ws: '/socket.io',
  appUrl: '/',
  clientMode: 'prod',
  baseUrl: '/',
  apiUrl: '/api/v1/',
  apiAdminUrl: '/api/v1/admin/',
  graphqlApi: '/api/v2/graphql',
  graphqlWsApi: 'wss/api/v2/graphql',
  apiTmpUrl: '/api/',
  setkaPublicToken: 't_tQqgp9bm2mwKdkisnj7hu0CLnd2Olz',
  oauth_client_id: 6,
  oauth_client_secret: 'C1fiwpx8DqNMybIdEGD7ejnfZTsexpLKBkPNrmiE',
  oauth_client_profile_id: 8,
  oauth_client_profile_secret: 'ZllURgvegNK3loNJ88pXU6KFjhEDAZlUDIvFrNwg',
  sentryEnabled: true,
  sentryDSN: 'https://93b6d26d01064f889f7e460c2021b558@o4505318649823232.ingest.sentry.io/4505318667517952',
  sentryTracingOrigins: '/together',
  redis: true,
  redisHost: '192.168.10.5',
  adminLayoutUser: 'g.goncharov@thebell.io',
  gtmId: '',
  recaptchaSiteKey: '6LcECeceAAAAAFc5ODAng7nBvLk14xTh-5lqY96j',
  cloudpaymentsKey: 'pk_7edca75d7503094dd70ec0f17af62',
  stripeApiUrl: 'https://api.stripe.com/v1/',
  stripeApiKey: 'pk_live_51MluxjDPTt3iBmICInmC1BK8TQjt914EV5JCSO3hdlS7ud31Ujymt2dZ6roUrhliIMdVJNtLLvQUoQLDOALQS0g400q0xq3UkD',

  stripeSubscriptionsPriceId: 'price_1NNzVUDPTt3iBmIC5TpEjHjY',

  stripeDonateOneTimeFirst: 'price_1NaK7UDPTt3iBmICl2CAnefJ',
  stripeDonateOneTimeSecond: 'price_1NaK7UDPTt3iBmIC6izxFuz2',
  stripeDonateOneTimeThird: 'price_1NaK7UDPTt3iBmICwYQcm3Js',
  stripeDonateOneTimeFourth: 'price_1NaK7UDPTt3iBmICT3QaRMDi',

  stripeDonateRecurrentFirst: 'price_1NLPkADPTt3iBmICpqA6g8N0',
  stripeDonateRecurrentSecond: 'price_1NLPkADPTt3iBmICdyds9brS',
  stripeDonateRecurrentThird: 'price_1NLPkBDPTt3iBmICEfOgjkZM',
  stripeDonateRecurrentFourth: 'price_1NLPkBDPTt3iBmICSIst0TEp',

  cloudpaymentsDonateOneTimeFirst: 'price_cloud_donate_one_time_plan_1',
  cloudpaymentsDonateOneTimeSecond: 'price_cloud_donate_one_time_plan_2',
  cloudpaymentsDonateOneTimeThird: 'price_cloud_donate_one_time_plan_3',
  cloudpaymentsDonateOneTimeFourth: 'price_cloud_donate_one_time_plan_4',

  cloudpaymentsDonateRecurrentFirst: 'price_cloud_donate_recurrent_plan_1',
  cloudpaymentsDonateRecurrentSecond: 'price_cloud_donate_recurrent_plan_2',
  cloudpaymentsDonateRecurrentThird: 'price_cloud_donate_recurrent_plan_3',
  cloudpaymentsDonateRecurrentFourth: 'price_cloud_donate_recurrent_plan_4',

  stripeSubscriptionsMonthDiscount: 'price_1PIUEtDPTt3iBmICo0TCZeML',
  stripeSubscriptionsQuaterDiscount: 'price_1PIUF6DPTt3iBmICo8sSbRHA',

  investSubscriptionDefaultCouponCode: 'TRIAL1',

  subscriberToken: 'KgTRn11duzwUMbPa8r8dpb',
  proSubscriberToken: 'J9s1MFL4xqZbiaPfXT19MxE3n3',

  mailchimpMorningMailingListId: 'f6fe1bf625',
  mailchimpEveningMailingListId: '5cf5773be9',
  mailchimpEnglishMailingListId: 'cc8c2d1cde',
  mailchimpWeeklyMailingListId: '823c4672bd',
  mailchimpTechMailingListId: 'e5902f88b1'
};
