import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchButtonComponent),
      multi: true,
    },
  ],
})
export class SwitchButtonComponent implements ControlValueAccessor {
  @Input() isChecked = false;
  @Input() class = '';
  @ViewChild('switcher', {static: false}) switcher: ElementRef;
  @Output() switchClick = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (value: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: boolean) {
    // получить из Forms API
    this.isChecked = outsideValue;
  }

  updateValue(insideValue: boolean) {
    this.isChecked = insideValue; // html
    this.onChange(insideValue); // уведомить Forms API
    this.onTouched();
  }
  click() {
    this.switcher.nativeElement.click();
    this.switchClick.emit(this.switcher.nativeElement.checked);
    this.updateValue(this.switcher.nativeElement.checked)
  }
}
