import {Component, Injector, Input} from '@angular/core';
import {Post, PublishedPost} from '@thebell/data-transfer-objects';
import {CollapsePostComponent} from '@thebell/common/ui/widgets';
import {WIDGET_DATA} from '@thebell/common/injection-tokens/widget-data';
import {DataMapperService} from '@thebell/common/services/utils/data-mapper';

@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.scss'],
})
export class PostViewComponent {
  get post(): Post | PublishedPost {
    return this._post;
  }
  @Input()
  set post(value: Post | PublishedPost) {
    this._post = value;
    this.postInjector = this.createInject(value);
  }
  private _post: Post | PublishedPost;
  injectors = [];
  collapsePostComponent = CollapsePostComponent;
  public postInjector: Injector;
  constructor(private injector: Injector, private dataMapperService: DataMapperService) {}

  private createInject(post: Post | PublishedPost): Injector {
    const obj = {
      data: {
        item: {
          ...post,
          no_ad: post.advertisement_ban,
          publish_date_ts: (post.published_at as number) / 1000,
        },
        widget_style_data: {},
        widgetStyle: {
          slug: 'PostComponent',
        },
      },
      component: CollapsePostComponent,
      basis: '100',
      name: 'Post',
    };
    return Injector.create({
      providers: [
        {
          provide: WIDGET_DATA,
          useValue: {
            data: this.dataMapperService.map(obj),
            open: true,
            authorAvatarIsResizable: false,
          },
        },
      ],
      parent: this.injector,
    });
  }
}
