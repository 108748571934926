import {Injectable} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {HelperService} from '@thebell/common/services/utils/helper';

@Injectable({
  providedIn: 'root',
})
export class DetectedRouteChangeService {
  public subject = new BehaviorSubject<ActivationEnd | null>(null);
  public currentRoute: ActivationEnd;

  constructor(private router: Router, private helperService: HelperService) {
    this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd && event.snapshot.children.length === 0))
      .subscribe((route: ActivationEnd) => {
        this.currentRoute = route;
        this.helperService.setInHeap('currentRoute', route);
        this.subject.next(route);
      });
  }
}
