import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LayoutLineHeightModule} from '@thebell/common/directives/layout-line-height';
import {LineComponent} from './line.component';

@NgModule({
  imports: [CommonModule, LayoutLineHeightModule],
  declarations: [LineComponent],
  exports: [LineComponent],
})
export class LineModule {}
