import {Component, Inject, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {WIDGET_DATA} from '@thebell/common/injection-tokens/widget-data';
import {HelperService} from '@thebell/common/services/utils/helper';
import {Lightning} from '@thebell/common/models/widget-style-data';
import {GaEventsService} from '@thebell/common/services/others/ga-events';

@Component({
  selector: 'app-widget-lightning',
  templateUrl: './lightning.component.html',
  styleUrls: ['./lightning.component.scss'],
})
export class LightningComponent implements OnInit {
  @Input() data: {data: Lightning; basis: string};
  textClass = 'text';
  title = '';
  slug = '';
  type = '';

  isVstrechiBellclub = false;

  constructor(
    @Inject(WIDGET_DATA) private dataInject: {data: Lightning; basis: string},
    private helperService: HelperService,
    private router: Router,
    private ga: GaEventsService
  ) {}
  inAdmin = () => this.helperService.inAdmin();

  ngOnInit(): void {
    this.data = this.data || this.dataInject;
    this.slug = this.data.data.slug;
    this.title = this.data.data.title;
    this.type = this.data.data.type;

    this.isVstrechiBellclub = this.router.url.startsWith('/vstrechi-bellclub') || this.type === 'page';
  }

  onClick() {
    if (this.helperService.isMainPage(this.router.url)) {
      this.ga.mainPageWidgetClickLightning(this.slug);
    }
  }
}
