import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '@thebell/common/services/api/auth';
import {ClientAuthService} from "../../../../services/api/client-auth/src";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private tokenPrefix: string;
  constructor(private auth: AuthService, private clientAuth: ClientAuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('skip')) {
      const headers = req.headers.delete('skip');
      return next.handle(req.clone({headers}));
    }
    const authReq = RegExp('graphql').test(req.url) ? this.addAuth(req, 'nest') : this.addAuth(req, 'laravel');
    return next.handle(authReq);
  }

  private addAuth(req: HttpRequest<any>, target: 'nest' | 'laravel') {
    let accessToken;
    if (target === 'nest') {
      accessToken = this.auth.nestToken || localStorage.getItem(`${this.tokenPrefix}${target}_access_token`);
    } else {
      accessToken = this.auth.laravelToken || localStorage.getItem(`${this.tokenPrefix}${target}_access_token`);
    }

    if (accessToken === null) {
      this.tokenPrefix = 'client_auth_';
      if (target === 'nest') {
        accessToken = this.clientAuth.nestToken || localStorage.getItem(`${this.tokenPrefix}${target}_access_token`);
      } else {
        accessToken = this.clientAuth.laravelToken || localStorage.getItem(`${this.tokenPrefix}${target}_access_token`);
      }
    }

    //console.log(this.tokenPrefix)
    //console.log(accessToken)

    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
    });
  }
}
