import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ImageSrcModule} from '@thebell/common/directives/image-src';
import {LazyImgModule} from '@thebell/common/directives/lazy-img';
import {ImageComponent} from './image.component';

@NgModule({
  imports: [CommonModule, ImageSrcModule, LazyImgModule],
  declarations: [ImageComponent],
  exports: [ImageComponent],
})
export class ImageModule {}
