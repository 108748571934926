import {QuizQuestion} from '../quiz-question';
import {QuizResult} from '../quiz-result';
import {QuizReport} from '../quiz-report';
import {Deserializable} from '@thebell/common/models/deserializable';
import {Asset} from '@thebell/common/models/asset';

export class Quiz extends Deserializable {
  id: number;
  created_at: Date;
  updated_at: Date;
  title: string;
  description: string;
  additional_title: string;
  assets: Asset[];
  questions: QuizQuestion[];
  results: QuizResult[];
  reports: QuizReport[];
}
