import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {UploadImageOldComponent} from './upload-image-old.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatProgressSpinnerModule],
  declarations: [UploadImageOldComponent],
  exports: [UploadImageOldComponent],
})
export class UploadImageOldModule {}
