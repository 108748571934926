import {AfterViewInit, Component, ElementRef, Inject, Injector, Input, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {WIDGET_DATA} from '@thebell/common/injection-tokens/widget-data';
import {CollapsePost, IPostSubject, Post} from '@thebell/common/models/post';
import {Category} from '@thebell/common/models/category';
import {UuidUtil} from '@thebell/common/services/utils/uuid';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {PostComponent} from '../post';
import {additionalCategory} from '@thebell/common/models/additional-category';
import {AdditionalCategoryService} from '@thebell/common/services/utils/additional-category/additinal-category';
import {SrcSetService} from '@thebell/common/services/utils/srcset';
import {SetkaCssService} from '@thebell/common/services/utils/setka-css';
import {replaceToWebP} from '../post/replaceToWebP';
import {Source} from '@thebell/data-transfer-objects';

@Component({
  selector: 'app-collapse-post',
  templateUrl: './collapse-post.component.html',
  styleUrls: ['./collapse-post.component.scss'],
})
export class CollapsePostComponent implements AfterViewInit {
  @ViewChild('post') post: ElementRef;
  @ViewChild('ad_sidebar', {read: ElementRef}) ad_sidebar: ElementRef;
  // @ViewChild('ads_1', {read: ElementRef}) ads_1: ElementRef;
  // @ViewChild('widget_container', {read: ElementRef}) widget_container: ElementRef;

  @Input() isOpen = false;
  public authorAvatarIsResizable: boolean;
  public postComponent;
  public postInjector: Injector;
  public data: CollapsePost;
  public basis;
  public lineMaxBasis;
  public themeIndex;
  public lineIndex;
  public title;
  public date;
  public authors;
  public leadImg;
  public leadText;
  public isQuiz;
  public uuid = '';
  public categories: Category[];
  public postRendered: Subject<boolean> = new Subject<boolean>();
  public postOpened: Subject<boolean> = new Subject<boolean>();
  public postAdsNeeded: Subject<IPostSubject> = new Subject<IPostSubject>();
  public additionalCategory: additionalCategory;
  public source: Source;
  public source_link: string;
  readonly html;

  private adsInit = false;
  private contentLoaded = false;
  public no_ad = false;
  public isFirstPost = false;
  constructor(
    private detectedScreenChangeService: DetectedScreenChangeService,
    private setkaCssService: SetkaCssService,
    @Inject(WIDGET_DATA)
    private dataInject: {
      data: Post;
      basis: string;
      lineMaxBasis: string;
      themeIndex: number;
      lineIndex?: number;
      open?: boolean;
      authorAvatarIsResizable: boolean;
    },
    private injector: Injector,
    private adCatService: AdditionalCategoryService,
    private screen: DetectedScreenChangeService,
    private readonly srcSetService: SrcSetService
  ) {
    this.authorAvatarIsResizable = dataInject.authorAvatarIsResizable;
    this.data = dataInject.data;
    // (this.data);
    this.basis = dataInject.basis;
    this.lineMaxBasis = dataInject.lineMaxBasis;
    this.themeIndex = dataInject.themeIndex;
    this.lineIndex = dataInject.lineIndex;
    this.title = this.data.title;
    this.date = this.data.date;
    this.authors = this.data.authors;
    this.categories = this.data.categories;
    this.additionalCategory = this.data.widget_style_data.additionalCategory;
    this.no_ad = this.data.no_ad;
    this.source = this.data.source;
    this.source_link = this.data.source_link;
    this.uuid = new UuidUtil().generator();
    this.isQuiz = this.data.content.includes('quiz_container');
    this.html = this.createParser();
    if (this.dataInject.open) {
      this.isFirstPost = true;
      this.open();
    }
    if (!this.isMailing(this.html)) {
      this.getLastSnippetArticlePreview();
      this.getTextLead();
      this.deleteEmpty(); // ?
      this.fixLastFigure(); // ?
    }
    this.postInjector = this.createInject();
  }

  ngAfterViewInit(): void {
    this.contentLoaded = true;
    if (!this.no_ad) {
      this.adsEmitter();
    }
  }

  createParser() {
    const content = replaceToWebP(this.data.content);
    const parser = new DOMParser().parseFromString(content, 'text/html');
    return this.setLazyLoad(parser);
  }

  setLazyLoad(html) {
    const images = html.querySelectorAll('img');
    images.forEach((img) => {
      img.setAttribute('loading', 'lazy');
    });
    return html;
  }

  async open() {
    this.postComponent = PostComponent;
    this.isOpen = true;
    if (!this.isTextArenaPost(this.html)) {
      await this.setkaCssService.loadSetkaCss();
    }
    if (!this.isFirstPostCheck()) ga('send', 'event', 'read_more', this.data.slug);

    if (!this.isFirstPostCheck()) dataLayer.push({
      'event': 'infinity_scroll_read_more', 'category': 'infinity_scroll', 'label': this.data.slug
    });

    if (!this.no_ad) {
      setTimeout(() => this.adsEmitter(), 0);
    }
  }

  adsEmitter() {
    if (!this.adsInit && this.getPostHeight() > 600) {
      if (this.detectedScreenChangeService.getScreenType() === 'desktop' && this.isOpen && this.contentLoaded) {
        this.postAdsNeeded.next({postHeight: this.getPostHeight(), screenType: 'desktop'});
        this.adsInit = true;
      } else if (this.detectedScreenChangeService.getScreenType() !== 'desktop' && this.contentLoaded) {
        this.postAdsNeeded.next({postHeight: this.getPostHeight(), screenType: 'other'});
        this.adsInit = true;
      }
    }
  }

  isFirstPostCheck() {
    return this.themeIndex === 0 && this.lineIndex === 0;
  }

  getPostHeight() {
    return this.post?.nativeElement?.clientHeight;
  }
  private deleteEmpty() {
    this.html.querySelectorAll('p.stk-reset').forEach((elem) => {
      if (elem.innerText === '') {
        elem.remove();
      }
    });
  }

  private fixLastFigure() {
    const lastChild = this.html.firstChild?.lastElementChild;
    if (lastChild?.tagName.toLowerCase() === 'figure') {
      lastChild.setAttribute('id', 'LAST_FIGURE');
      lastChild.classList.add('m0');
    }
  }

  getLink(category: additionalCategory): string {
    return this.adCatService.getAdCatSpec(category).link;
  }
  getText(category: additionalCategory): string {
    return this.adCatService.getAdCatSpec(category).name;
  }

  getImgPath(category: additionalCategory) {
    const svg = this.adCatService.getAdCatSpec(category).svg;
    const path = '/assets/images/exclusives/post/';
    if (this.screen.getScreenType() === 'mobile') {
      return path + 'mobile/' + svg;
    }
    return path + 'desktop/' + svg;
  }

  additionalCategoryResolver(cat: additionalCategory): 'tag' | 'asset' | '' {
    // проверка 2 на то, что в поле ассет, а не цвет
    if (this.additionalCategory) {
      return cat === additionalCategory.exclusive ? 'tag' : 'asset';
    }
    return '';
  }

  private createInject() {
    return Injector.create({
      providers: [
        {
          provide: WIDGET_DATA,
          useValue: {
            data: {...this.data, content: this.html.body.innerHTML},
            basis: this.basis,
            lineMaxBasis: this.lineMaxBasis,
            themeIndex: this.themeIndex,
            lineIndex: this.lineIndex,
          },
        },
      ],
      parent: this.injector,
    });
  }

  private getTextLead() {
    // Забираю только картинку, без лида будет ещё и текст
    const normalPost =
      this.isNewStyle() || this.isTextArenaPost(this.html)
        ? this.html.querySelector('p')
        : this.html.querySelector('.end-article-preview p');
    if (normalPost) {
      this.leadText = normalPost;
      return normalPost;
    }
    return this.html;
  }

  /*
   * Проверка на то, что это новый стиль сетки
   * https://gitlab.itsumma.dev/thebell/client-frontend/-/issues/446
   * */
  isNewStyle() {
    return !!this.html.querySelector('.stk-theme_42625');
  }

  isMailing(html: Element) {
    const bodyChildren = html.querySelector('body')?.children;
    const header4Tag = bodyChildren[0]?.tagName === 'H4';
    const imgTag = bodyChildren[1]?.children[0]?.tagName === 'IMG';
    if (header4Tag && imgTag) {
      this.leadImg = bodyChildren[1];
      this.leadText = bodyChildren[0];
      return true;
    }
    return false;
  }

  isTextArenaPost(html: Element) {
    return !html.querySelector('.stk-post');
  }

  private getLastSnippetArticlePreview() {
    // Забираю только картинку, без лида будет ещё и текст
    const normalPost =
      this.isNewStyle() || this.isTextArenaPost(this.html)
        ? this.html.querySelector('figure.lead')
        : this.html.querySelector('.end-article-preview .lead');

    if (normalPost) {
      this.leadImg = this.isTextArenaPost(this.html)
        ? normalPost
        : this.srcSetService.toNginxSizes(normalPost, this.data.assets);
      return normalPost;
    }
    return this.html;
  }
}
