import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {SubscriptionDialogComponent} from '../subscription-dialog';

@Component({
  selector: 'app-post-subscription',
  templateUrl: './post-subscription.component.html',
  styleUrls: ['./post-subscription.component.scss'],
})
export class PostSubscriptionComponent implements OnInit {
  screenType: string;

  subscriptionForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(private dialog: MatDialog, private detectedScreenChangeService: DetectedScreenChangeService) {}

  ngOnInit() {
    this.screenType = this.detectedScreenChangeService.getScreenType();
    this.detectedScreenChangeService.screenTypeChanged.subscribe((screenType) => {
      this.screenType = screenType;
    });
  }

  openModal() {
    ga('send', 'event', 'mail_article', 'mail_article_subscribe');
    dataLayer.push({
      'event': 'event', 'eventCategory': 'mail_article', 'eventAction': 'mail_article_subscribe'
    });
    if (this.subscriptionForm.valid) {
      const dialogRef = this.dialog.open(SubscriptionDialogComponent, {
        data: {email: this.subscriptionForm.value.email},
        height: '100%',
        maxHeight: 'calc(100vh - 100px)',
        maxWidth: 'calc(100vw - 16px)',
        backdropClass: 'subscription-backdrop',
        panelClass: 'subscription-panel',
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.subscriptionForm.patchValue({email: ''});
      });
    }
  }
}
