import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {OneStickyBannerDto} from '@thebell/data-transfer-objects';
import {filter, map} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

const getPopupQuery = gql`
  query getPopup {
    stickyBanner(id: 1) {
      periodicity
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class StickyBannerService {
  constructor(private apollo: Apollo) {}

  async getStickyBanner() {
    try {
      const banner = await this.apollo
        .query<OneStickyBannerDto>({
          query: getPopupQuery,
        })
        .pipe(map((el) => el.data.stickyBanner))
        .toPromise();
      return banner.periodicity;
    } catch (e) {
      return 0;
    }
  }
}
