import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LightningComponent} from './lightning.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [LightningComponent],
  exports: [LightningComponent],
})
export class LightningModule {}
