import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BaseModel} from '@thebell/common/models/base-model';
import {Author} from '@thebell/common/models/author';
import {ApiClientService} from '@thebell/common/services/api/api-client';
import {Title} from '@angular/platform-browser';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss'],
})
export class AuthorComponent implements OnInit {
  author = null;
  authorObserver: Observable<BaseModel<Author>>;

  constructor(private api: ApiClientService, private route: ActivatedRoute, private titleService: Title) {
    this.authorObserver = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.api.author(params.get('slug')))
    );
  }

  ngOnInit(): void {
    this.authorObserver.subscribe((response) => {
      this.author = response.data;
      this.titleService.setTitle(`${this.author.name_ru} — The Bell`);
    });
  }
}
