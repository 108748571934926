import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {Post} from '@thebell/common/models/post';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WIDGET_DATA} from '@thebell/common/injection-tokens/widget-data';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {UuidUtil} from '@thebell/common/services/utils/uuid';
import {Quiz} from '@thebell/common/models/quizzes';
import {QuizService} from '@thebell/common/services/api/quiz';
import {AdScriptService} from '@thebell/common/services/others/ad-script';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {Category} from '@thebell/common/models/category';
import {Author} from '@thebell/common/models/author';
import {Tag} from '@thebell/common/models/tag';
import {PostAdGetterService} from '@thebell/common/services/utils/post-ad-getter';
import {SEOService} from '@thebell/common/services/others/seo';
import {EnvironmentService} from '@thebell/common/services/core/environment';
import {ComponentType} from '@angular/cdk/overlay';
import {SUBSCRIPTION_DIALOG_COMPONENT} from '@thebell/common/injection-tokens/subscription-dialog-component';
import {JsonLdService} from 'ngx-seo';
import {DOCUMENT} from '@angular/common';
import {SrcSetService} from '@thebell/common/services/utils/srcset';
import {QuizComponent} from '../../../../utils/src/lib/quiz/quiz.component';
import {PostBannerService} from '@thebell/common/services/api/post-banner';
import {PaywallComponent} from '../paywall-banner';
import {MailingsPaywallComponent} from '../mailings-paywall-banner';
import {AuthService} from '../../../../../../../apps/client/src/app/services/auth.service';


@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  providers: [UuidUtil],

  animations: [
    trigger('click', [
      state('clicked', style('*')),
      transition('* <=> clicked', [
        animate(
          '0.3s',
          keyframes([
            style({
              'background-color': '#e0e0e0',
              color: '#FFFFFF',
              offset: 0.5,
            }),
            style({
              'background-color': '#ffffff',
              color: '#9e9e9e',
              offset: 1,
            }),
          ])
        ),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostComponent implements OnInit, AfterViewInit {
  @Input() data: {data: Post; basis: string; lineMaxBasis: string; themeIndex: number; lineIndex?: number};
  @Input() isUserAuthorized: boolean;
  @ViewChild('innerHTML', {static: false, read: ViewContainerRef}) innerHTML: ViewContainerRef;
  @ViewChild('placeForButton', {static: false}) placeForButton;
  @ViewChild('postTop', {static: false}) postTop;

  adFox = false;
  smi2 = false;
  zen = false;

  no_ad = false;
  slug: string;
  categories: Category[];
  tags: Tag[];
  title: string;
  date: string;
  authors: Author[];
  collapsed = true;
  oldCollapsed = true;
  quiz: Quiz;
  isNewsletter = false;
  isClicked = false;
  hidden: boolean;
  state = 'unclicked';
  postIndex: number;
  subscriptionForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    @Inject(SUBSCRIPTION_DIALOG_COMPONENT) private dialogWidget: ComponentType<any>,
    @Inject(WIDGET_DATA)
    private dataInject: {
      data: Post;
      basis: string;
      lineMaxBasis: string;
      themeIndex: number;
      lineIndex?: number;
    },
    public snackbar: MatSnackBar,
    private seo: SEOService,
    private detectedScreenChangeService: DetectedScreenChangeService,
    private quizService: QuizService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private app: ApplicationRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    private cdr: ChangeDetectorRef,
    private adScriptService: AdScriptService,
    private postAdGetter: PostAdGetterService,
    private envService: EnvironmentService,
    private jsonLdService: JsonLdService,
    @Inject(DOCUMENT) private document: Document,
    private readonly srcSetService: SrcSetService,
    private postBannerService: PostBannerService,
    private authService: AuthService
  ) {
    this.checkAuthorization()
  }

  checkAuthorization() {
    if (this.authService.isAuthenticated()) {
      this.isUserAuthorized = true;
    }
  }

  ngAfterViewInit(): void {
    const factory = this.resolver.resolveComponentFactory(PaywallComponent);
    const mailingsFactory = this.resolver.resolveComponentFactory(MailingsPaywallComponent);
    const elements = this.innerHTML.element.nativeElement.querySelectorAll('div[data-paywall="true"]');
    const mailingsPaywallElements = this.innerHTML.element.nativeElement.querySelectorAll('div[data-mailings-paywall="true"]');

    if (elements.length) {
      elements.forEach((element) => {
        const componentRef = factory.create(this.injector, [], element);
        componentRef.instance.postSlug = this.data.data.slug;
        componentRef.instance.isUserAuthorized = this.isUserAuthorized;
        componentRef.changeDetectorRef.detectChanges();
        ga('send', 'event', 'invest', 'snippet_show', this.data.data.slug);
        dataLayer.push({
          'event': 'event', 'eventCategory': 'invest', 'eventAction': 'snippet_show', 'eventLabel': this.data.data.slug
        });
      });
    }

    if (mailingsPaywallElements.length) {
      mailingsPaywallElements.forEach((element) => {
        const componentRef = mailingsFactory.create(this.injector, [], element);
        componentRef.instance.postSlug = this.data.data.slug;
        componentRef.instance.isUserAuthorized = this.isUserAuthorized;
        componentRef.changeDetectorRef.detectChanges();
        ga('send', 'event', 'mailings', 'snippet_show', this.data.data.slug);
        dataLayer.push({
          'event': 'event', 'eventCategory': 'mailings', 'eventAction': 'snippet_show', 'eventLabel': this.data.data.slug
        });
      });
    }
  }

  ngOnInit(): void {
    this.hidden = true;
    this.data = this.data || this.dataInject;

    let articleText = this.data.data.content;
    this.data.data.content = this.srcSetService.toNginxSizes(
      this.data.data.content,
      this.data.data.assets
    ).documentElement.innerHTML;

    this.no_ad = this.data.data.no_ad;
    this.categories = this.data.data.categories;
    this.tags = this.data.data.tags;
    const newsletterSlugs = ['morning-news', 'evening-news', 'itogi-nedeli', 'rassylka-o-tehnologiyah', 'weekly'];
    this.isNewsletter = this.categories.some((category) => {
      return newsletterSlugs.includes(category.slug);
    });

    if (this.isNewsletter && this.route.snapshot.queryParams.utm_medium === 'email') {
      const newsletterSlug = this.categories.find((category) => {
        return newsletterSlugs.includes(category.slug);
      });
      switch (newsletterSlug.slug) {
        case 'morning-news':
          localStorage.setItem('mc_sub_morning', '1');
          break;
        case 'evening-news':
          localStorage.setItem('mc_sub_evening', '1');
          break;
        case 'itogi-nedeli':
          localStorage.setItem('mc_sub_weekly', '1');
          break;
        case 'rassylka-o-tehnologiyah':
          localStorage.setItem('mc_sub_ok_future', '1');
          break;
        case 'weekly':
          localStorage.setItem('mc_sub_en', '1');
          break;
      }

      this.isNewsletter = false;
    }

    if (this.isNewsletter) {
      const newsletterSlug = this.categories.find((category) => {
        return newsletterSlugs.includes(category.slug);
      });
      switch (newsletterSlug.slug) {
        case 'morning-news':
          if (localStorage.getItem('mc_sub_morning') === '1') {
            this.isNewsletter = false;
          }
          break;
        case 'evening-news':
          if (localStorage.getItem('mc_sub_evening') === '1') {
            this.isNewsletter = false;
          }
          break;
        case 'itogi-nedeli':
          if (localStorage.getItem('mc_sub_weekly') === '1') {
            this.isNewsletter = false;
          }
          break;
        case 'rassylka-o-tehnologiyah':
          if (localStorage.getItem('mc_sub_ok_future') === '1') {
            this.isNewsletter = false;
          }
          break;
        case 'weekly':
          if (localStorage.getItem('mc_sub_en') === '1') {
            this.isNewsletter = false;
          }
          break;
      }
    }
    this.title = this.data.data.title;
    this.date = this.data.data.date;
    this.authors = this.data.data.authors;
    if (this.data.data.content.includes('quiz_container')) this.getQuizId();
    // if (isPlatformBrowser(this.platformId)) this.getBanners();
    if (this.data.themeIndex === 0 && this.data.lineIndex === 0) {
      this.collapsed = false;
      this.hidden = false;
    }
    if (!this.no_ad) {
      this.adScriptService.setScripts();
    }

    const postImages: string[] = [];
    this.data.data.assets.forEach((asset) => {
      if (asset.width > 1200) {
        postImages.push(asset.src);
      }
    });
    articleText = articleText.replace(/\<.*?\>/g, '');
    const newsArticle = this.jsonLdService.getObject('NewsArticle', {
      mainEntityOfPage: this.jsonLdService.getObject('WebPage', {
        '@id': `${this.document.URL}`,
      }),
      headline: `${this.title}`,
      articleBody: articleText,
      datePublished: `${this.data.data.publish_date}`,
      dateModified: `${this.data.data.modified_date}`,
      author:
        this.authors.length > 0 && this.authors[0]
          ? this.jsonLdService.getObject('Person', {
              name: `${this.authors[0].name_ru}`,
              url: `${this.envService.getEnvironment().appUrl}author/${this.authors[0].slug}`,
            })
          : null,
      description: `${this.data.data.subtitle}`,
      image: postImages,
      publisher: this.jsonLdService.getObject('Organization', {
        name: 'The Bell — деловые новости и аналитика',
        logo: this.jsonLdService.getObject('ImageObject', {
          url: '/assets/images/logo.svg',
        }),
      }),
    });
    this.jsonLdService.setData(newsArticle);
  }

  onClickCopy() {
    ga('send', 'event', 'post_link');
    dataLayer.push({
      'event': 'share', 'category': 'share', 'method': 'link'
    });
    this.isClicked = !this.isClicked;
  }

  getQuizId() {
    const re = /<p(.+)>(\d+)<\/p>/gi;
    const quizId = this.data.data.content.search(re) !== -1 ? re.exec(this.data.data.content)[2] : null;
    if (quizId)
      this.quizService.getQuiz(Number(quizId)).subscribe((res) => {
        this.quiz = res;
        const containerNode = this.innerHTML.element.nativeElement;
        const quizNode = containerNode.querySelector('[data-container-name="quiz_container"]');
        if (quizNode) {
          const factory = this.resolver.resolveComponentFactory(QuizComponent);
          const ref = factory.create(this.injector, [], quizNode);
          ref.instance.quiz = this.quiz;
          ref.instance.quizSlug = this.data.data.slug;
          if (this.quiz.id === 9) {
            ref.instance.started = true;
          }
          this.app.attachView(ref.hostView);
        }
      });
  }

  // async getBanners() {
  //   const re = /(<div data-banner="true"><\/div>)/g;
  //   const banner = await this.postBannerService.actualBanner$.toPromise().catch(() => {});
  //   if (banner && banner.content) {
  //     let _box;
  //     let i = 0;
  //     let content: string = this.data.data.content;
  //     while ((_box = re.exec(this.data.data.content)) !== null) {
  //       i += _box.index;
  //       content = `
  //         ${content.slice(0, i)}
  //         ${decodeURI(banner.content)}
  //         ${content.slice(i, content.length)}`;
  //       i += banner.content.length;
  //     }
  //     this.data.data.content = content;
  //     this.cdr.markForCheck();
  //   }
  // }

  get currentUrl(): string {
    // const {appUrl} = this.envService.getEnvironment();
    // return `${appUrl}${this.data.data.slug}`;
    const domain = 'https://thebell.site/';
    return `${domain}${this.data.data.slug}`;
  }

  hide() {
    ga('send', 'event', 'mail_wall', 'mail_wall_close');
    dataLayer.push({
      'event': 'mail_wall_close', 'category': 'newsletter'
    });
    this.isNewsletter = false;
  }

  openModal() {
    ga('send', 'event', 'mail_wall', 'mail_wall_subscribe');
    dataLayer.push({
      'event': 'mail_wall_subscribe', 'category': 'newsletter', 'label': this.subscriptionForm.value.email
    });
    const dialogRef = this.dialog.open(this.dialogWidget, {
      data: {email: this.subscriptionForm.value.email},
      height: '100%',
      maxHeight: 'calc(100vh - 100px)',
      maxWidth: 'calc(100vw - 16px)',
      backdropClass: 'subscription-backdrop',
      panelClass: 'subscription-panel',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'subscribed') this.isNewsletter = false;
    });
  }

  onGoTelegram() {
    ga('send', 'event', 'mail_wall', 'mail_wall_telegram');
    dataLayer.push({
      'event': 'mail_wall_tg', 'category': 'newsletter'
    });
  }
}
