export enum PaymentStatusDto {
  IN_PAY = 'in_pay', // создался платеж
  PENDING = 'pending', // начали обрабатывать платеж, висит (пока не получили апдейтов от платежной системы)
  FAILED = 'failed',
  CANCELED = 'canceled', // отменен (для регулярных платежей)
  PAID = 'paid', // всё ок, но у нас ничего не сделано
  FAILED_IN_PROCESSING = 'failed_in_processing', //
  PROCESSED = 'processed', // всё ок, и на нашей стороне все сделано
}

export enum ProductTypes {
  subscription = 'subscription', // подписка на инвест рассылку
  donation = 'donation', // подписка на донаты
}

export enum ProcessedType {
  BY_CRON = 'by_cron',
  BY_HOOK = 'by_hook',
  NONE = 'none',

}

export interface PaymentDto {
  id: number;
  email: string;
  amount: number;
  status: PaymentStatusDto;
  payment_external_id: string;
  processed_type: ProcessedType;
  processed_at: number | Date;
  created_at: number | Date;
  updated_at: number | Date;
}

export interface OrderStatusDto {
  status: PaymentStatusDto;
  email: string;
}

export interface PaymentItemDto {
  id: number;
  quantity: number;
  amount: number;
  payment_id: number;
  product_type: string;
  product_id: number;
  created_at: number | Date;
  updated_at: number | Date;
}
