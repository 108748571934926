import {isPlatformBrowser} from '@angular/common';
import {combineLatest, ReplaySubject} from 'rxjs';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ExternalAssetAppenderService} from '@thebell/common/services/utils/external-asset-appender';
import {AdvertisementPostsService} from '@thebell/common/services/api/adverisement-posts';
import {AdProviderService} from '@thebell/common/services/utils/ad-provider';

@Injectable({
  providedIn: 'root',
})
export class AdScriptService {
  private scriptsMap = {};
  private debugMode = true;
  private adFoxLoader = 'https://yastatic.net/pcode/adfox/loader.js';

  constructor(
    public advertisementPostsService: AdvertisementPostsService,
    private assetAppender: ExternalAssetAppenderService,
    private adProviders: AdProviderService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>
  ) {}

  public setDebugMode(val) {
    this.debugMode = val;
  }

  public setScripts() {
    if (isPlatformBrowser(this.platformId)) {
      this.advertisementPostsService.getAdvertisementsProviders().subscribe((scripts) => {
        scripts.data.forEach((script) => {
          if (!this.scriptsMap[script.slug]) {
            const textScript = this.adProviders.getScriptBySlug(script.slug);
            if (textScript) {
              this.assetAppender.addTextScript(textScript, script.name).subscribe(() => {
                this.scriptsMap[script.slug] = true;
              });
            }
          }
        });
      });
    }
  }

  public scriptLoaded(slug) {
    if (isPlatformBrowser(this.platformId)) {
      const subject = new ReplaySubject<any>();
      const interval = setInterval(() => {
        const scriptLoad = this.scriptsMap[slug];
        if (scriptLoad) {
          subject.next(1);
          clearInterval(interval);
          return subject;
        }
        subject.next(0);
        return subject;
      }, 500);
      return subject;
    }
  }

  public addScriptToPage(script: string) {
    const scriptTag = document.createElement('script');
    scriptTag.innerHTML = script;
    try {
      document.head.appendChild(scriptTag);
      this.log('ок', script.slice(0, 15));
    } catch (er) {
      this.log(er);
    }
  }

  private log(text, val = '') {
    if (this.debugMode) console.log(text, val);
  }
}
