import {Component, Input} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-mailings-paywall',
  templateUrl: './mailings-paywall.component.html',
  styleUrls: ['./mailings-paywall.component.scss'],
})
export class MailingsPaywallComponent {
  @Input() postSlug: string;
  @Input() isUserAuthorized: boolean;

  constructor(private cookieService: CookieService) {}

  setSuccessLinkAndNavigate(url: string) {
    this.setSuccessLink();
    window.location.href = url;
  }

  setSuccessLink() {
    // ga('send', 'event', 'mailings', 'snippet_subscribe', this.postSlug);
    // dataLayer.push({
    //   'event': 'mailings_paywall_snippet_subscribe', 'category': 'mailings'
    // });
    const expires = new Date(new Date().getTime() + 30 * 60000);
    this.cookieService.set('success-invest-paid-link', this.postSlug, expires);
  }
}
