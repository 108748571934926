import {isPlatformBrowser} from '@angular/common';
import {
  AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, Renderer2, ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-ad-thetruestory',
  templateUrl: './ad-thetruestory.component.html',
  styleUrls: ['./ad-thetruestory.component.scss'],
})
export class AdThetruestoryComponent implements AfterViewInit {
  @ViewChild('ad_thetruestory_component', {static: false}) divSidebar: ElementRef;
  private SCRIPT_ELEMENT_ID = 'ad-thetruestory-script';

  constructor(private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Record<string, any>) {
  }

  get show() {
    return !!(this.divSidebar?.nativeElement?.firstChild.shadowRoot?.lastChild);
  }

  ngAfterViewInit() {
    this.divSidebar.nativeElement.innerHTML = '<thetruestory-widget-top slug="thebell"></thetruestory-widget-top>';
    this.appendScriptIfNotExist();
  }

  appendScriptIfNotExist() {
    if (isPlatformBrowser(this.platformId)) {
      let scriptEl = document.getElementById(this.SCRIPT_ELEMENT_ID);
      if (scriptEl) {
        return;
      }
      const head = document.getElementsByTagName('head')[0];
      scriptEl = this.createBaseScript();
      this.renderer.appendChild(head, scriptEl);
    }
  }

  private createBaseScript() {
    // <script async src="https://thetruestory.news/widget-top.iife.js"></script>
    const script = this.renderer.createElement('script');
    script.async = true;
    script.id = this.SCRIPT_ELEMENT_ID;
    script.src = 'https://thetruestory.news/widget-top.iife.js';
    return script;
  }
}
