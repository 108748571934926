import {Directive, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {IPostSubject} from '@thebell/common/models/post';
import {AdScriptService} from '@thebell/common/services/others/ad-script';
import {PostAdGetterService} from '@thebell/common/services/utils/post-ad-getter';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';

@Directive({
  selector: '[appPostAds]',
})
export class PostAdsDirective implements OnInit, OnDestroy {
  @Input() isOpen: boolean;
  @Input() uuid;
  @Input() needAdsSub: Subject<IPostSubject>;
  @Input()
  get zenEl(): HTMLDivElement {
    return this._zenEl;
  }
  set zenEl(value: HTMLDivElement) {
    this._zenEl = value;
  }
  @Input()
  get smi2El(): HTMLDivElement {
    return this._smi2El;
  }
  set smi2El(value: HTMLDivElement) {
    this._smi2El = value;
  }
  @Input()
  get adFoxEl(): HTMLDivElement {
    return this._adFoxEl;
  }
  set adFoxEl(value: HTMLDivElement) {
    this._adFoxEl = value;
  }

  public adFox = false;
  public zen = false;
  public smi2 = false;
  public hostEl;

  private _zenEl: HTMLDivElement;
  private _smi2El: HTMLDivElement;
  private _adFoxEl: HTMLDivElement;

  constructor(
    private el: ElementRef,
    private postAdGetter: PostAdGetterService,
    private adScriptService: AdScriptService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    private detectedScreenChangeService: DetectedScreenChangeService
  ) {
    this.hostEl = el.nativeElement;
  }

  ngOnInit() {
    this.needAdsSub.subscribe((res) => {
      if (isPlatformBrowser(this.platformId)) {
        if (res.screenType === 'desktop') {
          this.dataIsLoadEmitter(res.postHeight);
        } else {
          this.setMobileAd();
        }
      }
    });
  }

  private dataIsLoadEmitter(height) {
    const adfoxHeight = 540;
    const smi2Height = 356;
    const smi2Id = 93755;

    if (!this.adFox) {
      if (isPlatformBrowser(this.platformId)) {
        this.adScriptService.scriptLoaded('adfox').subscribe((load) => {
          if (load) {
            this.adFoxEl.style.display = 'block';
            addAdFox(this.uuid, undefined, 'adfox_side1', 388377);
            addAdFox(this.uuid, undefined, 'adfox_under_post_desktop', 388377);
            this.adFox = true;
          }
        });
      }
    }
    const adHeight = adfoxHeight + 20;

    if (adHeight + smi2Height + 40 < height) {
      if (!this.smi2) {
        this.adScriptService.scriptLoaded('smi2').subscribe((load) => {
          if (load && this.smi2El) {
            addSmi2(this.smi2El, smi2Id);
            this.smi2 = true;
          }
        });
      }
      if (this.smi2El) {
        this.smi2El.style.display = 'block';
      }
    } else {
      if (this.smi2El) {
        this.smi2El.style.display = 'none';
      }
      this.zenEl.style.display = 'none';
      this.adFoxEl.style.display = 'none';
    }
  }

  private setMobileAd() {
    const curAd = this.postAdGetter.getNextAd();
    const smi2Id = 93755;
    switch (curAd) {
      case 'adfox':
        if (!this.adFox) {
          this.adScriptService.scriptLoaded('adfox').subscribe((load) => {
            if (load) {
              addAdFox(this.uuid, undefined, 'adfox_mob3', 388377);
              this.adFox = true;
              if (this.smi2El) {
                this.smi2El.style.display = 'none';
              }
              this.zenEl.style.display = 'none';
            }
          });
        }
        this.adFoxEl.style.display = 'block';
        break;
      case 'null':
        if (this.smi2El) {
          this.smi2El.style.display = 'none';
        }
        this.zenEl.style.display = 'none';
        this.adFoxEl.style.display = 'none';
        break;
      case 'zen':
        if (!this.zen) {
          this.adScriptService.scriptLoaded('zen').subscribe((load) => {
            if (load) {
              addZen(this.uuid, this.detectedScreenChangeService.getScreenType());
              this.zen = true;
              if (this.smi2El) {
                this.smi2El.style.display = 'none';
              }
              this.adFoxEl.style.display = 'none';
            }
          });
        }
        this.zenEl.style.display = 'block';
        break;
      case 'smi2':
        if (!this.smi2) {
          this.adScriptService.scriptLoaded('smi2').subscribe((load) => {
            if (load && this.smi2El) {
              addSmi2(this.smi2El, smi2Id);
              this.smi2 = true;
              this.zenEl.style.display = 'none';
              this.adFoxEl.style.display = 'none';
            }
          });
        }
        if (this.smi2El) {
          this.smi2El.style.display = 'block';
        }
        break;
    }
  }
  ngOnDestroy() {
    this.needAdsSub.unsubscribe();
  }
}
