import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {PrivateEnvironments} from '@thebell/data-transfer-objects';

export const PRIVATE_ENVIRONMENTS_GET_QUERY = gql`
  query getPrivateEnvirments {
    private_env {
      IZO_JWT
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PrivateEnvService {
  private privateEnvironments: PrivateEnvironments = undefined;

  constructor(private apollo: Apollo) {}

  async get(key: string): Promise<string> {
    if (!this.privateEnvironments) {
      this.privateEnvironments = (
        await this.apollo
          .watchQuery<{private_env: PrivateEnvironments}>({
            query: PRIVATE_ENVIRONMENTS_GET_QUERY,
          })
          .result()
      ).data.private_env;
    }

    return Promise.resolve(this.privateEnvironments[key]);
  }
}
