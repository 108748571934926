import {GqlDto} from '../gql.dto';
import {AllowedDateType} from '../common.dto';
import {BankType} from '@thebell/data-transfer-objects';

export enum PaymentSubscriptionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface SubscriptionDto {
  id?: number;
  status: PaymentSubscriptionStatus;
  retryAttempts: number;
  startedAt: Date;
  nextProlongatedAt: Date;
  periodValue: number;
  periodType: AllowedDateType;
  amount: number;
  totalAmount: number;
  recurrentPaymentExternalId: string;
  subscriptionPlanId: number;
  email: string;
  subscriptionPlan?: SubscriptionPlanDto | Promise<SubscriptionPlanDto>;
  acquiringBank?: BankType;
  feedback?: string;
  deactivateDate?: string;
  deactivatedAt?: Date;
}

export const DateTypeToRu = {
  [AllowedDateType.YEAR]: 'год',
  [AllowedDateType.MONTH]: 'месяца',
  [AllowedDateType.DAY]: 'дня',
};

export enum AllowedPlanStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum AllowedProductStatus {
  INVEST = 'invest',
  BELL_PRO = 'bell_pro',
}

export interface SubscriptionPlanDto {
  id: number;
  price: number;
  periodValue: number;
  periodType: AllowedDateType;
  promoPeriodValue?: number;
  promoPeriodType?: AllowedDateType;
  name: string;
  description: string;
  status: AllowedPlanStatus;
  listId: string;
  position: number;
  slug: string;
  priceUSD: number;
  couponCode?: string;
  currency: string;
  paymentSystemId: string;
  paymentSystemPriceId?: string;
  discountedPrice?: number;
  customName?: string;
  isExternalPayment: boolean;
  externalLink?: string;
  isDiscounted: boolean;
  hasPromoPeriod: boolean;
  product: AllowedProductStatus;
  showOriginalPriceWithPromo: boolean;
  discountPercent?: number;
}

export interface SubscriptionPaymentDto {
  id: number;
  paymentId: number;
  subscriptionId: number;
}

export type SubscriptionPlanListDto = GqlDto<'subscription_plans', SubscriptionPlanDto[]>;

export enum EventsType {
  orderStatusCheck = 'orderStatusCheck',
}

export type CreateSubscription = Omit<SubscriptionDto, 'id' | 'subscriptionPlan'>;

export interface InitSubscriptionInputDto {
  email: string;
  subscriptionPlanId: number;
  token: string;
  bankType?: BankType;
  domain?: string;
  allowCouponCode?: boolean;
  cryptoData?: string;
  cardHolderName?: string;
}

export interface InitSubscriptionAuthKeysDto {
  client_auth_laravel_access_token: string;
  client_auth_nest_access_token: string;
  client_auth_token_expired: string;
  client_auth_currentUser: string;
  client_auth_ad_key: string;
  client_auth_pro_key: string
}

export interface InitSubscriptionDto {
  confirmationToken: string;
  returnUrl: string;
  threeDSecureInfo?: {
    acsUrl: string;
    paReq: string;
    transactionId: string;
    termUrl: string;
  };
  error?: {success: boolean; message: string;};
}

export interface RequestAccessSubscriptionDto {
  email: string;
  slug: string;
  domain?: string;
  token: string;
}

export interface CreateSubscriptionPlanInputDto {
  price: number;
  periodValue: number;
  periodType: AllowedDateType;
  promoPeriod?: number;
  promoPeriodType?: AllowedDateType;
  name: string;
  description: string;
  listId: string;
  position?: number;
  slug: string;
}

export interface ProlongateSubscriptionInputDto {
  startDate: Date;
  endDate: Date;
  resultDate: Date;
}
