import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {UuidUtil} from '@thebell/common/services/utils/uuid';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';

interface SplitHtml {
  isParagraph: boolean;
  html: string;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appPostAd]',
  providers: [UuidUtil],
})
export class PostAdDirective implements AfterViewInit {
  private isOpen = false;
  private incutId = [];

  // Чтобы отрабатывало скрытие рекламы,
  // необходимо параметр "no_ad" задавать в html теге раньше,
  // чем парметр "open"
  @Input() no_ad = false;

  @Input() set open(isOpen: boolean) {
    if (isOpen && this.isOpen !== isOpen && !this.no_ad) {
      this.loadAdFox();
    }
    this.isOpen = isOpen;
  }

  private _html: SafeHtml;
  @HostBinding('innerHTML')
  @Input()
  set html(html) {
    const html2 = html.toString();
    this._html = this.sanitizer.bypassSecurityTrustHtml(html2);
    if (html2.includes('stk-post')) {
      this.renderer.addClass(this.el.nativeElement, 'setka_content');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'wp_content');
    }
    if (html2.includes('stk-theme_37074')) {
      this.renderer.addClass(this.el.nativeElement, 's37074');
    }
  }

  get html() {
    return this._html;
  }

  private idsNrPlayer = [];
  private idsTopAdFox = [];
  private idsLongAdFox = [];

  private screenWidth: number;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private detectedScreenChangeService: DetectedScreenChangeService,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>
  ) {
    const getScreenWidth = (screenType: string): number => detectedScreenChangeService.getScreenWidth(screenType);
    this.screenWidth = getScreenWidth(detectedScreenChangeService.getScreenType());
    detectedScreenChangeService.screenTypeChanged.subscribe((screenType) => {
      this.screenWidth = getScreenWidth(screenType);
    });
  }

  ngAfterViewInit() {
    if (this.no_ad) return;

    const preAdEl = this.getPreAdElement();
    if (preAdEl) {
      this.addNewTopAdBlockContainer(preAdEl);
    } else {
      // this.addTopAdBlockContainer();
    }

    setTimeout(() => {
      this.nrPlayer();
    });
  }

  private loadAdFox() {
    setTimeout((_) => {
      /* if (this.detectedScreenChangeService.getScreenType() !== 'desktop') {
        if (this.el.nativeElement.offsetHeight / window.innerHeight > 4) {
          let height = 0;

          let mode = 'beforeend';
          let adElement = (id) => {
            return '<p style="margin: 20px -15px"><span id="ad-sidebar-' + id + '"></span></p>';
          };

          let elements = this.el.nativeElement.querySelectorAll('.stk-post div');
          if (elements.length === 0) {
            mode = 'afterend';
            adElement = (id) => {
              return '<p><span id="ad-sidebar-' + id + '"></span></p>';
            };
            elements = this.el.nativeElement.children;
          }

          for (const element of elements) {
            if (!element.outerHTML.includes('no-ad')) {
              height += element.offsetHeight;
              if (Math.trunc(height / window.innerHeight) > 4) {
                height = 0;
                const id = new UuidUtil().generator();
                element.insertAdjacentHTML(mode, adElement(id));
                this.idsLongAdFox.push(id);
              }
            }
          }
        }
      }*/
      setTimeout((h) => {
        this.adFox();
      });
    });
  }

  private addTopAdBlockContainer() {
    let html = this.el.nativeElement.innerHTML;
    if (!html) return;

    const htmlStr = html.toString();
    const tagsSplit = htmlStr.match(/(<\s|.*>)+/g);
    if (tagsSplit === null) return;

    const id = new UuidUtil().generator();
    const adHtml = '<p class="reklama-block"><span id="ad-sidebar-' + id + '"></span></p>';

    const paragraph = this.splitHtml(tagsSplit);
    const paragraphsCount = paragraph.filter((e) => e.isParagraph).length;
    html = '';
    let currentParagraph = 0;
    for (const e of paragraph) {
      currentParagraph = e.isParagraph ? (currentParagraph += 1) : currentParagraph;
      html += `${e.html}`;
      if (paragraphsCount > 1 && currentParagraph === 2) {
        html += adHtml;
        currentParagraph++;
      }
    }

    this.el.nativeElement.innerHTML = html;

    if (this.screenWidth > this.detectedScreenChangeService.screenTypes.mobile) {
      this.idsNrPlayer.push(id);
    } else {
      this.idsTopAdFox.push(id);
    }
  }

  private addNewTopAdBlockContainer(preAdElement) {
    const id = new UuidUtil().generator();
    const adHtml = '<p style="margin: 20px -15px"><span style="width: 100%;" id="ad-sidebar-' + id + '"></span></p>';

    preAdElement.insertAdjacentHTML('afterend', adHtml);

    if (this.screenWidth > this.detectedScreenChangeService.screenTypes.mobile) {
      this.idsNrPlayer.push(id);
    } else {
      this.idsTopAdFox.push(id);
    }
  }

  private nrPlayer() {
    if (isPlatformBrowser(this.platformId)) {
      this.idsNrPlayer.forEach((id) => {
        const postArticle = this.el.nativeElement.querySelector(`#ad-sidebar-${id}`);
        SeedrPlayer(postArticle, 50, {
          gid: '5a438cd05f366e9e298b46e8',
          article: 'post-page',
          onLoad: () => {
            const nativeRoll = postArticle.querySelector('.nr-player');
            (nativeRoll as any).style.marginTop = '20px';
            (nativeRoll as any).style.marginBottom = '20px';
          },
        });
      });
    }
  }

  private adFox() {
    this.idsLongAdFox.forEach((id) => {
      addAdFox(id, undefined, 'adfox_mob1');
    });
    this.idsTopAdFox.forEach((id) => {
      addAdFox(id, undefined, 'adfox_mob2', 388377);
    });
  }

  private splitHtml(html): SplitHtml[] {
    return html.map((e) => {
      return {isParagraph: Boolean(e.match(/(<p(.*)>)+/g)), html: e} as SplitHtml;
    });
  }

  private getPreAdElement() {
    return this.el.nativeElement.querySelector('.pre-ad');
  }
}
