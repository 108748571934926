import {Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {DetectedRouteChangeService} from '@thebell/common/services/utils/detected-route-change';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

const PARAMS = {
  MAIN: {
    DESK: {
      pp: 'crtt', ps: 'fddk', p2: 'htyz'
    }, MOB: {
      pp: 'brlr', ps: 'fddk', p2: 'htyz'
    },
  }, OTHER: {
    DESK: {
      pp: 'crtt', ps: 'fddk', p2: 'htyz'
    }, MOB: {
      pp: 'brlr', ps: 'fddk', p2: 'htyz'
    },
  },
};

@Component({
  selector: 'app-sticky-banner',
  templateUrl: './sticky-banner.component.html',
  styleUrls: ['./sticky-banner.component.scss'],
})
export class StickyBannerComponent implements OnInit {
  @Output() close = new EventEmitter();
  notShowedYet: boolean = true;

  constructor(private detectedScreenChangeService: DetectedScreenChangeService, private detectedRouteChangeService: DetectedRouteChangeService, @Inject(PLATFORM_ID) private platformId: Record<string, any>) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.resolve();
    }
  }

  ngOnDestroy(): void {
    this.close.emit(true);
  }

  private initAd(params) {
    if (this.notShowedYet) {
      window.yaContextCb.push(() => {
        window.Ya.adfoxCode.create({
          ownerId: 388377, containerId: 'adfox_165943851723661061', params, onDestroy: () => {
            this.close.emit(true);
          },
        });
      });
      this.notShowedYet = false
    }
  }

  private resolve() {
    const screen$ = this.detectedScreenChangeService.screenType$.pipe(map((type) => (type === 'desktop' ? 'DESK' : 'MOB')));
    const route$ = this.detectedRouteChangeService.subject.pipe(map((route) => {
      return route.snapshot.data.name === 'main' ? 'MAIN' : 'OTHER';
    }));
    combineLatest([screen$, route$]).subscribe((result) => {
      const [screen, route] = result;
      this.initAd(PARAMS[route][screen]);
    });
  }
}
