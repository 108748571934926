import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ExternalAssetAppenderService {
  constructor(@Inject(PLATFORM_ID) private platformId: Record<string, any>) {}

  addTextScript(script: string, name: string) {
    if (isPlatformBrowser(this.platformId)) {
      const subject = new ReplaySubject<any>();
      if (!document.querySelector('script[name="' + name + '"]')) {
        const scriptTag = document.createElement('script');
        scriptTag.innerHTML = script;
        scriptTag.setAttribute('name', name);
        scriptTag.setAttribute('load', 'loading');
        scriptTag.setAttribute('load', 'loaded');
        document.head.appendChild(scriptTag);
        subject.next(true);
      } else if (!document.querySelector('script[name="' + name + '"][load = "loading"]')) {
        subject.next(true);
      }

      return subject;
    }
  }

  add(url: string) {
    if (isPlatformBrowser(this.platformId)) {
      const subject = new ReplaySubject<any>();
      switch (url.split('.').slice(-1)[0]) {
        case 'js':
          if (!document.querySelector('script[src="' + url + '"]')) {
            const script = document.createElement('script');
            script.innerHTML = '';
            script.src = url;
            script.async = false;
            script.setAttribute('load', 'loading');
            script.onload = () => {
              script.setAttribute('load', 'loaded');
              subject.next(true);
            };
            document.head.append(script);
          } else if (document.querySelector('script[src="' + url + '"][load = "loading"]')) {
            const interval = setInterval(() => {
              if (document.querySelector('script[src="' + url + '"][load = "loaded"]')) {
                subject.next(true);
                clearInterval(interval);
              }
            }, 500);
          } else {
            subject.next(true);
          }
          return subject;
        case 'css':
          if (!document.querySelector('script[src="' + url + '"]')) {
            const style = document.createElement('link');
            style.href = url;
            style.rel = 'stylesheet';
            subject.next(true);
            document.head.append(style);
          } else {
            subject.next(true);
          }

          return subject;
      }
    }
  }
}
