import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LayoutThemeItem} from '@thebell/common/models/layout-theme-item';

@Injectable({
  providedIn: 'root',
})
export class RepeatableLinesExistsService {
  private repaeatableLinesExists = new BehaviorSubject<boolean>(false);
  /*
   * Search first repeatable line, then emit true
   * */
  findRepeats(data: LayoutThemeItem[]): void {
    this.repaeatableLinesExists.next(false);
    data.every((line) => {
      if (line.repeatable) {
        this.repaeatableLinesExists.next(true);
        return false;
      }
      return true;
    });
  }
  /*
   * return true, if page has repeatable lines
   * */
  getValue(): boolean {
    return this.repaeatableLinesExists.getValue();
  }
  /*
   * return observable, that broadcast repeatable value
   * true - if repeatable lines exist per page
   * false - if repeatable lines not exist per page
   * */
  get$(): Observable<boolean> {
    return this.repaeatableLinesExists.asObservable();
  }
}
