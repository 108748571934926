import {Inject, Injectable} from '@angular/core';
import {ENV} from './environment.injector';
import {Environment} from './environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(@Inject(ENV) private environment: Environment) {}

  getEnvironment() {
    return this.environment;
  }
}
