import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PostAdDirective} from './post-ad.directive';
import {RunScriptsDirective} from './run-scripts.directive';
import {EmbedPluginEngineDirective} from './embed-plugin-engine/embed-plugin-engine.directive';
import {GalleryPluginEngineDirective} from './gallery-plugin-engine.directive';
import {IncutParserDirective} from '@thebell/common/directives/incut-parser';
import {PostInnerAdvertisingDirective} from '@thebell/common/directives/post-inner-advertising';
// import {PostSubscriptionDirective} from './post-subscription.directive';

@NgModule({
  declarations: [
    PostAdDirective,
    IncutParserDirective,
    /* PostSubscriptionDirective,*/
    RunScriptsDirective,
    EmbedPluginEngineDirective,
    GalleryPluginEngineDirective,
    PostInnerAdvertisingDirective,
  ],
  imports: [CommonModule],
  exports: [
    PostAdDirective,
    IncutParserDirective,
    /* PostSubscriptionDirective,*/ RunScriptsDirective,
    EmbedPluginEngineDirective,
    GalleryPluginEngineDirective,
    PostInnerAdvertisingDirective,
  ],
})
export class DirectivesModule {}
