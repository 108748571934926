import {BaseWidget} from '../base';
import {Asset} from '@thebell/common/models/asset';

export class BellClub extends BaseWidget {
  title: string;
  text: string;
  background: string;
  background_image_id: number; // asset.id
  background_image: Asset;
  photo_id: number; // asset.id
  photo: Asset;
  link: string;
  link_title: string;
  // eslint-disable-next-line id-blacklist
  number?: string;
  author?: string;
  author_description?: string;
  quote?: string;
}
