import {gql} from 'apollo-angular';

export const GET_PREVIEW_LINK = gql`
  mutation createPostAccessKey($id: Int!) {
    createPostAccessKey(id: $id) {
      key
      expires_at
    }
  }
`;
