import {Component, Inject, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {RequestAccessSubscriptionDto} from '@thebell/data-transfer-objects';
import {Apollo, gql} from 'apollo-angular';
import {catchError, map, tap} from 'rxjs/operators';
import {RecaptchaComponent} from 'ng-recaptcha';
import {environment} from "../../../../../../../apps/client/src/environments/environment";

@Component({
  selector: 'app-paywall-modal',
  templateUrl: './paywall-modal.component.html',
  styleUrls: ['./paywall-modal.component.scss'],
})
export class PaywallModalComponent  {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {postSlug: string},
    private dialogRef: MatDialogRef<any>,
    private apollo: Apollo
  ) {}
  inProgress = false;
  isThanksModal = false;
  textError = '';
  siteKey = environment.recaptchaSiteKey;
  @ViewChild('captchaRef') reCaptcha: RecaptchaComponent;

  form = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  get formIsInvalid(): boolean {
    return this.form.invalid;
  }

  // ToDo: remove to service
  async submit(evt) {
    evt.preventDefault();
    const email = this.form.value.email;
    const slug = this.data.postSlug;
    if (!email || !slug) {
      this.textError = 'Что-то пошло не так :(';
      return;
    }
    this.reCaptcha.execute()
  }

  async callAuth(token: string) {

    const email = this.form.value.email;
    const slug = this.data.postSlug;
    const domain = window.location.origin;

    this.inProgress = true;
    this.apollo
      .mutate<{requestSubscriptionAccess: boolean}, {input: RequestAccessSubscriptionDto}>({
        mutation: gql`
          mutation requestSubscriptionAccess($input: RequestAccessSubscription!) {
            requestSubscriptionAccess(requestAccessInput: $input)
          }
        `,
        variables: {
          input: {email, slug, domain, token}
        },
      })
      .pipe(
        // tap((res) => {
        //   if (res.errors) {
        //     const err = res.errors[0];
        //     console.log('err', err);
        //     switch(err?.extensions?.errors[0]?.body?.constraints?.customError) {
        //       case 'this email has no active subscriptions': {
        //         this.textError = 'У пользователя с таким email нет активных подписок';
        //         break;
        //       }
        //       default: {
        //         this.textError = 'Что-то пошло не так :(';
        //         break;
        //       }
        //     }
        //   }
        // }),
        map((res) => (res.data ? res.data.requestSubscriptionAccess : null))
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.inProgress = false;
            this.isThanksModal = res;
            this.textError = '';
          } else {
            this.textError = 'Что-то пошло не так :(';
          }
        },
        error: (error) => {
          this.inProgress = false;
          // Если не нашли подписку с таким email, то не говорим об этом, чтобы защитить пользователей.
          if (error.message === 'Request validation error') {
            this.isThanksModal = true;
            this.textError = '';
          } else {
            this.isThanksModal = false;
            this.textError = 'Что-то пошло не так :(';
          }
        },
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
