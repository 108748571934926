import {ChangeDetectorRef, Component, Inject, Input, OnInit, Renderer2} from '@angular/core';
import {Asset} from '@thebell/common/models/asset';
import {DOCUMENT} from '@angular/common';

type TypeAdvertisingSize = 'tall' | 'wide';

@Component({
  selector: 'app-advertising-box',
  templateUrl: './advertising-box.component.html',
  styleUrls: ['./advertising-box.component.scss'],
})
export class AdvertisingBoxComponent implements OnInit {
  @Input() asset: Asset | string;
  advertising_size: TypeAdvertisingSize;
  height: number;
  type: string;
  constructor(private cdr: ChangeDetectorRef, private r2: Renderer2, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    if (this.asset) {
      this.advertisingSize(this.asset).then((res) => {
        switch (res) {
          case 'tall':
            this.height = 32;
            break;
          case 'wide':
            this.height = 20;
        }
        this.advertising_size = res;
        this.cdr.markForCheck();
      });
    }
  }

  private async loadImage(src: string): Promise<TypeAdvertisingSize> {
    this.type = src.split('.').reverse()[0];
    return new Promise<TypeAdvertisingSize>((resolve, reject) => {
      const img = this.r2.createElement('img');
      this.r2.listen(img, 'load', () => {
        if (+img.width / +img.height > 4) resolve('wide');
        else resolve('tall');
      });
      this.r2.listen(img, 'error', () => {
        reject('unknown');
      });
      this.r2.setAttribute(img, 'src', src);
    });
  }

  async advertisingSize(image: Asset | string): Promise<TypeAdvertisingSize> {
    return new Promise<TypeAdvertisingSize>((resolve) => {
      if (!image) return;
      if (typeof image === 'string') {
        return this.loadImage(image)
          .then((res) => {
            resolve(res);
          })
          .catch((er) => {
            resolve(er);
          });
      } else {
        return this.loadImage(image.src)
          .then((res) => {
            resolve(res);
          })
          .catch((er) => {
            resolve(er);
          });
      }
    });
  }
}
