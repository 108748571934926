import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PostAdsDirective} from './post-ads.directive';

@NgModule({
  declarations: [PostAdsDirective],
  imports: [CommonModule],
  exports: [PostAdsDirective],
})
export class PostAdsModule {}
