import {Deserializable} from '@thebell/common/models/deserializable';

export class QuizReport extends Deserializable {
  id: number;
  points: number;
  answers: Answer[];
}

export class Answer {
  question_id: number;
  answer_ids: number[];
}
