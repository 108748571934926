import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-subscribe-confirmed',
  templateUrl: './subscribe-confirmed.component.html',
  styleUrls: ['./subscribe-confirmed.component.scss'],
})
export class SubscribeConfirmedComponent implements OnInit {
  newsletter = '';
  socials = [
    {
      icon: 'telegram',
      text: 'The Bell',
      href: 'https://tlgg.ru/thebell_io',
    },
    // {
    //   icon: 'telegram',
    //   text: 'Что мне с этого?', // 'The Bell News',
    //   href: 'https://tlgg.ru/Bell_daily',
    // },
    {
      icon: 'telegram',
      text: 'The Bell Tech',
      href: 'https://tlgg.ru/Bell_tech',
    },
    {
      icon: 'twitter',
      text: 'The Bell',
      href: 'https://twitter.com/ru_thebell',
    },
    {
      icon: 'twitter',
      text: 'The Bell English',
      href: 'https://twitter.com/thebell_io',
    },
    {
      icon: 'twitter',
      text: 'The Bell Tech',
      href: 'https://twitter.com/TheBellTech',
    },
    {
      icon: 'fb',
      text: 'Facebook',
      href: 'https://www.facebook.com//',
    },
    {
      icon: 'vk',
      text: 'ВКонтакте',
      href: 'https://vk.com/thebell_io',
    },
    {
      icon: 'odnoklassniki',
      text: 'Одноклассники',
      href: 'https://ok.ru/thebell',
    },
    {
      icon: 'instagram',
      text: 'Instagram',
      href: 'https://www.instagram.com/thebell_io/',
    },
    {
      icon: 'youtube',
      text: 'Русские норм!',
      href: 'https://www.youtube.com/channel/UCo3S9OQDrM79i7yPmixnSsQ',
    },
  ];

  constructor(private route: ActivatedRoute) {
    const newsletterType = this.route.snapshot.queryParams.newsletter.toString();
    if (newsletterType) {
      localStorage.setItem('mc_sub_' + newsletterType, '1');
      switch (newsletterType) {
        case 'morning':
          this.newsletter = 'утреннюю рассылку';
          break;
        case 'evening':
          this.newsletter = 'вечернюю рассылку';
          break;
        case 'weekly':
          this.newsletter = 'итоговую рассылку';
          break;
        case 'ok_future':
          this.newsletter = 'рассылку The Bell о технологиях';
          break;
        default:
          this.newsletter = 'рассылку';
          break;
      }
    }
  }

  ngOnInit(): void {
    ga('set', 'location', environment.baseUrl + 'subscribe-confirmed');
    ga('send', 'pageview');
    //dataLayer.push({'event': 'pageview'});
  }
}
