import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConsentWidgetComponent} from './consent-widget';

@NgModule({
  imports: [CommonModule],
  declarations: [ConsentWidgetComponent],
  exports: [ConsentWidgetComponent],
})
export class PrivacyScriptManagerModule {}
