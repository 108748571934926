import {Connection, ConnectionIzo, PageInfo} from '@thebell/data-transfer-objects';

export interface ISimplePagination<T> {
  cursor: string;
  nodes: T[];
  isNext: boolean;
}

export interface IFullPagination<T> {
  pageInfo: PageInfo;
  nodes: T[];
}

export const parsePaginationQuery = <T>(res: Connection<T>): ISimplePagination<T> => {
  const {edges} = res;
  const {hasNextPage} = res.pageInfo;
  return {
    cursor: edges[edges.length - 1]?.cursor,
    nodes: edges.map((edge) => edge.node),
    isNext: Boolean(hasNextPage),
  };
};

export const parsePaginationQueryIzo = <T>(res: ConnectionIzo<T>): IFullPagination<T> => {
  return {
    pageInfo: res.pageInfo,
    nodes: res.edges.map((edge) => edge.node),
  };
};
