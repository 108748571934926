<div class="content">
    <img src='assets/images/logo.svg'>
    <span class="subtitle"
        >Узнайте, что стоит за последними новостями.<br *ngIf="screenType != 'mobile'" />
        Без цензуры в самом удобном формате.</span
    >
    <div class="subscription" [formGroup]="subscriptionForm">
        <input placeholder="E-mail" type="email" name="email" (keyup.enter)="openModal()" formControlName="email" />
        <app-button
            buttonText="Подписаться"
            (buttonClick)="openModal()"
            [disabled]="subscriptionForm.invalid"
            [buttonClass]="subscriptionForm.valid ? 'default subscribe_form' : 'default subscription-disabled'"
            [spanClass]="'no-word-break default-text'"
        >
        </app-button>
    </div>
</div>
