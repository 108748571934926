import {Deserializable} from '@thebell/common/models/deserializable';

export const AssetRoles = {
  author_image: 1,
  widget_NewsComponent_background_image: 2,
  plugin: 3,
  theme_file: 4,
  content_editor_file: 5,
  widget_QuoteComponent_photo: 6,
  share_image: 7,
  main_image: 8,
  quiz_cover: 9,
  quiz_additional: 10,
  widget_NewsComponent_advertising_image: 12,
};

export class Asset extends Deserializable {
  id: number;
  assetable_id: number;
  assetable_type: string;
  path: string;
  role: number;
  type: string;
  src: string;
  prefix?: string;
  postfix?: string;
  width?: number;
  height?: string;
  preview?: string;
  origin_id?: string;
  is_origin: boolean;
  mime_type?: string;
  created_at: Date;
  updated_at: Date;
  version: number;
}
