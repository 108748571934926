<div class="page">
    <div class="page_block">
        <div class="page_block_title">Подписка подтверждена</div>
        <div class="page_block_text">Вы подписались на {{ newsletter }}. Спасибо!</div>
        <div class="page_block_text">
            Если у вас есть вопросы к нам: <a href="mailto:info@thebell.io">info@thebell.io</a>
        </div>
    </div>
    <div class="page_block">
        <div class="page_block_subtitle">Читайте нас в соцсетях</div>
        <div class="page_block_socials">
            <a class="page_block_social" *ngFor="let social of socials" [href]="social.href" target="_blank">
                <app-image
                    [imageClass]="'social ' + social.icon"
                    [image]="'/assets/images/icons.svg#' + social.icon"
                    imageType="svg"
                    [isResizable]="false"
                ></app-image>
                <div class="page_block_social_text">{{ social.text }}</div>
            </a>
        </div>
    </div>
    <div class="page_bellclub">
        <div class="page_bellclub_title">Присоединяйтесь к Bell.Club</div>
        <div class="page_bellclub_text">
            Мы делаем закрытые встречи с успешными бизнесменами, политиками, экономистами. Онлайн и офлайн.
        </div>
        <a href="/vstrechi-bellclub">
            <app-button buttonClass="default bellclub" buttonText="Подробнее"></app-button>
        </a>
    </div>
</div>
