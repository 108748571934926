import {Injectable} from '@angular/core';
import {Post} from '@thebell/common/models/post';
import {WidgetStyle} from '@thebell/common/models/widget-style';
import {widgetsMap} from '@thebell/common/ui/widgets';

@Injectable({
  providedIn: 'root',
})
export class DeserializeWidgetDataService {
  deserialize(item) {
    item.item = new Post().deserialize(item.item);
    item.widgetStyle = new WidgetStyle().deserialize(item.widget_style);
    item.component = widgetsMap[item.widget_style.slug];
    return item;
  }
}
