import {Component, Inject, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ColorService} from '@thebell/common/services/utils/color';
import {HelperService} from '@thebell/common/services/utils/helper';
import {WIDGET_DATA} from '@thebell/common/injection-tokens/widget-data';
import {NumberOfDay} from '@thebell/common/models/widget-style-data';
import {GaEventsService} from '@thebell/common/services/others/ga-events';

@Component({
  selector: 'app-number-of-day',
  templateUrl: './number-of-day.component.html',
  styleUrls: ['./number-of-day.component.scss'],
})
export class NumberOfDayComponent implements OnInit {
  @Input() data: {data: NumberOfDay; basis: string};
  categoryClass = 'category';
  numberClass = 'number';
  innerTextClass = 'text-inner';
  textClass = 'text';
  timeClass = 'time';
  image = '';
  imageType = '';
  // eslint-disable-next-line id-blacklist
  number = '';
  text = '';
  date: string;
  slug = '';
  type = '';
  basis: number;

  isVstrechiBellclub = false;

  constructor(
    @Inject(WIDGET_DATA) private dataInject: {data: NumberOfDay; basis: string},
    public helperService: HelperService,
    private colorService: ColorService,
    private router: Router,
    private ga: GaEventsService
  ) {}

  inAdmin = () => this.helperService.inAdmin();

  ngOnInit(): void {
    this.data = this.data || this.dataInject;
    this.basis = +this.data.basis;
    this.categoryClass += ' category-' + this.data.basis;
    this.numberClass += ' number-' + this.data.basis;
    this.innerTextClass += ' text-inner-' + this.data.basis;
    this.timeClass += ' time-' + this.data.basis;
    // eslint-disable-next-line id-blacklist
    this.number = this.data.data.number;
    this.text = this.data.data.text;
    this.date = this.data.data.date;
    this.slug = this.data.data.slug;
    this.type = this.data.data.type;
    if (this.data.data.background) {
      this.image = this.data.data.background;
      this.imageType = 'background-color';
      if (this.colorService.colorIsRed(this.image) || this.colorService.colorIsBlue(this.image)) {
        this.textClass += ' white';
        this.categoryClass += ' white';
        this.timeClass += ' white';
      }
    } else {
      this.image = '#FFFFFF';
      this.imageType = 'background-color';
    }

    this.isVstrechiBellclub = this.router.url.startsWith('/vstrechi-bellclub') || this.type === 'page';
  }

  onClick() {
    if (this.helperService.isMainPage(this.router.url)) {
      this.ga.mainPageWidgetClickNumbOfDay(this.slug);
    }
  }
}
