import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SetkaCssService {
  private setkaCssLoaded = false;
  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private readonly document: Document, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadSetkaCss() {
    return new Promise<void>((resolve, reject) => {
      if (this.setkaCssLoaded) {
        resolve();
      }
      const link = this.renderer.createElement('link');
      link.id = 'setka_style';
      link.rel = 'stylesheet';
      link.href = '/assets/css/setka.min.css';
      link.onload = resolve;
      link.onerror = reject;
      this.renderer.appendChild(this.document.head, link);
      this.setkaCssLoaded = true;
      resolve();
    });
  }
}
