import {Deserializable} from '@thebell/common/models/deserializable';

export class WidgetStyle extends Deserializable {
  id: number;
  name: string;
  slug: string;
  fields: Field[];
}

export class Field {
  field: string;
  required: boolean;
  type: string;
  default?: string;
}
