import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {DetectedRouteChangeService} from '@thebell/common/services/utils/detected-route-change';
import {ApiClientService} from '@thebell/common/services/api/api-client';
import {environment} from '../../../environments/environment';
import {SetkaCssService} from '@thebell/common/services/utils/setka-css';
import {ContentLoadingService} from '@thebell/common/services/others/event-bus';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-additional',
  templateUrl: './additional.component.html',
  styleUrls: ['./additional.component.scss'],
})
export class AdditionalComponent implements OnDestroy, OnInit {
  content = '';
  contentHtml: SafeHtml;
  detectedRouteChangeSubject: Subscription;

  constructor(
    private sanitizer: DomSanitizer,
    private detectedRouteChangeService: DetectedRouteChangeService,
    private apiClientService: ApiClientService,
    private router: Router,
    private setkaCssService: SetkaCssService,
    private contentLoadingService: ContentLoadingService
  ) {}

  ngOnInit() {
    this.contentLoadingService.set(false);
    ga('set', 'location', environment.baseUrl + this.router.url.replace(/\/$/, ''));
    ga('send', 'pageview');
    //dataLayer.push({'event': 'pageview'});
    this.detectedRouteChangeSubject = this.detectedRouteChangeService.subject.subscribe(async (currentRoute) => {
      const post = await this.apiClientService.postByWpId(currentRoute.snapshot.data.wpId).toPromise();
      await this.setkaCssService.loadSetkaCss();
      this.content = post.content;
      this.contentHtml = this.sanitizer.bypassSecurityTrustHtml(post.content);
      this.contentLoadingService.set(true);
    });
  }

  ngOnDestroy(): void {
    this.detectedRouteChangeSubject.unsubscribe();
  }
}
