<a
    *ngIf="type !== 'external_post'"
    class="grid-container"
    [routerLink]="inAdmin() ? [] : [isVstrechiBellclub ? '/vstrechi-bellclub' : '/', slug]"
    (click)="onClick()"
>
    <app-image
        [imageClass]="'widget-background'"
        [image]="image"
        [imageType]="imageType"
        [isResizable]="false"
        [addition]="{basis:basis}"
    ></app-image>
    <div [class]="textClass">{{ text }}</div>
    <div class="author">
        <div class="author-text">
            <div [class]="nameClass">{{ name }}</div>
            <div [class]="captionClass" *ngIf='caption.trim() != ""'>{{ caption }}</div>
        </div>
    </div>
    <div [class]="dateClass">{{ date }}</div>
</a>

<a *ngIf="type === 'external_post'" class="grid-container" [attr.href]="inAdmin() ? null : slug">
    <app-image
        [imageClass]="'widget-background'"
        [image]="image"
        [imageType]="imageType"
        [isResizable]="false"
        [addition]="{basis:basis}"
    ></app-image>
    <div [class]="categoryClass">цитата</div>
    <div [class]="textClass">{{ text }}</div>
    <div class="author">
        <div class="author-text">
            <div [class]="nameClass">{{ name }}</div>
            <div [class]="captionClass" *ngIf='caption.trim() != ""'>{{ caption }}</div>
        </div>
    </div>
    <div class="time">{{ data.data.date }}</div>
</a>
