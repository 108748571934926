import {AfterViewInit, Directive, ElementRef, Inject, PLATFORM_ID} from '@angular/core';
import {UuidUtil} from '@thebell/common/services/utils/uuid';
import {ClientGalleryState, GalleryData, SliderData} from '@thebell/common/models/gallery';
import {calculatedSrc, computedValues, moveThumbsLine} from '@thebell/frontend/images';
import {isPlatformServer} from '@angular/common';

@Directive({
  selector: '[appGalleryPluginEngine]',
})
export class GalleryPluginEngineDirective implements AfterViewInit {
  galleriesState: {[k: string]: ClientGalleryState} = {};

  constructor(private elementRef: ElementRef, @Inject(PLATFORM_ID) private platformId: Record<string, any>) {}

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const galleries = this.elementRef.nativeElement.getElementsByClassName('galley-snippet') as HTMLCollection;
    for (let i = 0; i < galleries.length; i++) {
      const gallery = galleries.item(i);
      const id = new UuidUtil().generator();
      gallery.id = id;
      const rawData = gallery.getAttribute('data');
      const data = JSON.parse(rawData) as GalleryData;
      const prevArrow = gallery.getElementsByClassName('arrow-prev')[0];
      const nextArrow = gallery.getElementsByClassName('arrow-next')[0];
      const bigImgElement = gallery.getElementsByClassName('main-image')[0];
      const thumbnailsElements = gallery.getElementsByClassName('thumbnail-wrapper') as HTMLCollection;
      const botBlocksWrapper = gallery.getElementsByClassName('bot-blocks-wrapper')[0];
      let sliderData: SliderData;
      const timer = setInterval(() => {
        sliderData = this.computeValuesForSlider(gallery, 0);
        if (sliderData.slideWidth !== 0) {
          clearInterval(timer);
          const obj: ClientGalleryState = {
            id,
            bigImgElement,
            thumbnailsElements,
            prevArrowElement: prevArrow,
            nextArrowElement: nextArrow,
            botBlocksWrapperElement: botBlocksWrapper,
            data: {
              mainId: 0,
              pictures: data.pictures,
              sizes: data.sizes,
            },
            sliderData,
          };
          this.galleriesState[id] = obj;
          prevArrow.addEventListener('click', () => {
            this.slideHandle(obj.data.mainId - 1, id);
          });
          nextArrow.addEventListener('click', () => {
            this.slideHandle(obj.data.mainId + 1, id);
          });
          for (let i = 0; i < thumbnailsElements.length; i++) {
            const thumb = thumbnailsElements.item(i);
            thumb.addEventListener('click', () => {
              this.slideHandle(i, id);
            });
          }
        }
      }, 500);
    }
  }

  computeValuesForSlider(gallery, mainID) {
    return computedValues(gallery, mainID);
  }

  arrowController(gallery: ClientGalleryState) {
    const idImage = gallery.data.mainId;
    if (idImage === gallery.data.pictures.length - 1) {
      gallery.nextArrowElement.classList.add('invisible');
      gallery.prevArrowElement.classList.remove('invisible');
    } else if (idImage === 0) {
      gallery.prevArrowElement.classList.add('invisible');
      gallery.nextArrowElement.classList.remove('invisible');
    } else {
      gallery.nextArrowElement.classList.remove('invisible');
      gallery.prevArrowElement.classList.remove('invisible');
    }
  }

  slideHandle(idImage, idGalleryy) {
    const currentGallery = this.galleriesState[idGalleryy];
    const oldMainID = currentGallery.data.mainId;
    if (idImage === oldMainID) return;
    const direction = oldMainID < idImage ? 'r' : 'l';
    const pic = currentGallery.data.pictures[idImage];
    const currentSrc = pic.src as string;
    const currentAlt = pic.alt || '';
    const srcSet = calculatedSrc(currentGallery.data.sizes, currentSrc).join(', ');
    currentGallery.bigImgElement.setAttribute('src', currentSrc);
    currentGallery.bigImgElement.setAttribute('alt', currentAlt);
    currentGallery.bigImgElement.setAttribute('srcset', srcSet);
    currentGallery.thumbnailsElements[oldMainID].classList.remove('current');
    currentGallery.thumbnailsElements[idImage].classList.add('current');
    currentGallery.data.mainId = idImage;
    this.arrowController(currentGallery);
    // двигаем нижний блок
    currentGallery.sliderData = moveThumbsLine(currentGallery.sliderData, direction, idImage);
    currentGallery.botBlocksWrapperElement.attributes.getNamedItem(
      'style'
    ).value = `transform: translateX(${currentGallery.sliderData.shift}px)`;
  }
}
