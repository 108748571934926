import {AfterContentInit, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: 'picture[appLazyImg]',
})
export class LazyImgDirective implements AfterContentInit {
  img: HTMLImageElement;
  imgSrc: string;

  constructor(private el: ElementRef<HTMLPictureElement>) {}

  ngAfterContentInit() {
    this.img = this.el.nativeElement.querySelector('img');
    const supports = 'loading' in HTMLImageElement.prototype;
    //console.log(supports);
    if (supports) {
      this.img.setAttribute('loading', 'lazy');
    } else {
      this.imgSrc = this.img.src;
      this.img.removeAttribute('src');
      this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
    }
  }

  private canLazyLoad() {
    return window && 'IntersectionObserver' in window;
  }

  private lazyLoadImage() {
    const sources = this.el.nativeElement.querySelectorAll('source');
    const srcsetArr = [];
    sources.forEach((source) => {
      srcsetArr.push(source.srcset);
      source.removeAttribute('srcset');
    });
    const obs = new IntersectionObserver((entries) => {
      entries.forEach(({isIntersecting}) => {
        if (isIntersecting) {
          sources.forEach((source, index) => {
            source.srcset = srcsetArr[index];
          });
          this.loadImage();
          obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }

  private loadImage() {
    this.img.src = this.imgSrc;
  }
}
