import {gql} from 'apollo-angular';

export const SET_AUTH = gql`
  mutation login($credentials: Credentials!) {
    login(credentials: $credentials) {
      access_token
      expires_in
    }
  }
`
