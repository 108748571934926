import * as Sentry from '@sentry/angular';

export enum SentryEvents {
  donateInit,
  donateWidgetFailed,
}

export interface SentryEvt {
  message: string;
  email: string;
  level: Sentry.SeverityLevel;
  payload;
}
