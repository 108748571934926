<div
  *ngIf="type !== 'external_post'"
  class="grid-container"
  [class.date-first]="textClass.includes('width-image')"
  [class.small]="small"
>
  <!--Дополнительная категория-->
  <a
    *ngIf="additionalCategoryResolver(additionalCategory) === 'asset'"
    target="_blank"
    [href]="getLink(additionalCategory)"
    class="additional-category"
  >
    <img
      [ngClass]="'additional-category-image ' + getAdditionClass(additionalCategory)"
      [src]="getImgPath(additionalCategory)"
    />
  </a>
  <!-- /Дополнительная категория -->
  <a
    (click)="onClick()"
    class="full-block-link"
    [class.small]="small"
    [attr.aria-label]="title"
    [routerLink]="inAdmin() ? [] : [isVstrechiBellclub ? '/vstrechi-bellclub' : '/', slug]"
  >
  </a>
  <app-image
    *ngIf="basis > 25 && !small"
    [imageClass]="'widget-background'"
    [image]="image"
    [imageType]="imageType"
    [isResizable]="resizable"
    [addition]="{basis: basis}"
  ></app-image>
  <app-advertising-box
    class="advertising"
    [asset]="advertising_image"
    *ngIf="advertisingIsAvailable"
  ></app-advertising-box>
  <div [class]="categoryClass" *ngIf="!advertisingIsAvailable">
    <a class="link main" [routerLink]="'/category/' + categorySlug">{{ category }}</a>
    <span *ngIf="additionalCategoryResolver(additionalCategory) === 'tag'" class="link exclusive">{{
      getText(additionalCategory)
    }}</span>
  </div>
  <div [class]="textClass">{{ title }}</div>
  <div [class]="timeClass">{{ date }}</div>
</div>
<div *ngIf="type === 'external_post'" class="grid-container" [class.date-first]="textClass.includes('width-image')">
  <ng-container isVisible [id]='data?.srcData?.data?.item?.id' [title]='data?.srcData?.data?.item?.title'>
    <a class="full-block-link" [attr.aria-label]="title" [attr.href]="inAdmin() ? null : slug" target="_blank"></a>
    <!--Дополнительная категория-->
    <a
      *ngIf="additionalCategoryResolver(additionalCategory) === 'asset'"
      target="_blank"
      [href]="getLink(additionalCategory)"
      class="additional-category"
    >
      <img [src]="getImgPath(additionalCategory)" />
    </a>
    <!-- /Дополнительная категория -->
    <app-image
      *ngIf="basis > 25"
      [imageClass]="'widget-background'"
      [image]="image"
      [imageType]="imageType"
      [isResizable]="true"
      [addition]="{basis: basis}"
    ></app-image>
    <app-advertising-box
      class="advertising"
      [asset]="advertising_image"
      *ngIf="advertisingIsAvailable"
    ></app-advertising-box>
    <div [class]="categoryClass">
    <!-- ToDo: Return ngif -->
    <!--<div [class]="categoryClass" *ngIf="!advertisingIsAvailable">-->
      <a class="link" [routerLink]="'/category/' + categorySlug">{{ category }}</a>
      <span *ngIf="additionalCategoryResolver(additionalCategory) === 'tag'" class="link exclusive">
        {{ getText(additionalCategory) }}
      </span>
    </div>
    <div [class]="textClass">{{ title }}</div>
    <div [class]="timeClass">{{ date }}</div>
  </ng-container>
</div>
