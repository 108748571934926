import {AfterViewInit, Component, Input} from '@angular/core';

@Component({
  selector: 'app-inoagent-banner',
  templateUrl: './inoagent.component.html',
  styleUrls: ['./inoagent.component.scss'],
})
export class InoagentComponent implements AfterViewInit {
  @Input() post: string;
  showBanner = false;
  BANNER_TEXT_SHORT = 'НАСТОЯЩИЙ МАТЕРИАЛ (ИНФОРМАЦИЯ) ПРОИЗВЕДЕН И РАСПРОСТРАНЕН';

  ngAfterViewInit(): void {
    this.showBanner = !this.checkInoagentBanner(this.BANNER_TEXT_SHORT);
  }

  checkInoagentBanner(text: string): boolean {
    const FIRST_CHARS_COUNT = 1000;
    let htmlStr = this.post.slice(0, FIRST_CHARS_COUNT);
    htmlStr = this.removeSpaces(htmlStr);
    return htmlStr.includes(text);
  }

  removeSpaces(text: string) {
    // remove extra spaces and non-breaking space
    const spaces = ['&nbsp;', '&thinsp;', '&ensp;', '&emsp;', ' ', ' ', ' '];
    spaces.forEach((space) => {
      text = text.split(space).join(' ');
    });
    text = text.replace(/\s\s+/g, ' ');
    return text;
  }
}
