import {Injectable} from '@angular/core';
import {ApiClientService} from '@thebell/common/services/api/api-client';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private features: Array<Record<string, string>> = [];

  constructor(private api: ApiClientService) {}

  public value(name: string): Promise<string | boolean> {
    return new Promise((resolve, _) => {
      let value = this.features[name];
      if (!value) {
        this.api.features().subscribe((res) => {
          Object.keys(res.data).forEach((key) => {
            this.features[key] = res.data[key].value;
          });

          value = this.features[name];

          resolve(['on', 'off'].includes(value) ? value === 'on' : value);
        });
      } else {
        resolve(['on', 'off'].includes(value) ? value === 'on' : value);
      }
    });
  }
}
