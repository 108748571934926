import {Deserializable} from '@thebell/common/models/deserializable';
import {SafeHtml} from '@angular/platform-browser';

export class Category extends Deserializable {
  id: number;
  title: string;
  slug: string;
  parent_id: number;
  parent?: Category;
  description: string;
  description_html: SafeHtml;
  created_at: Date;
  updated_at: Date;
  wp_terms_term_id: number;
  lang: string;
}

export type FilterObj = Omit<{title: string}, 'id'>;
