import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {AppendThemeItemComponent} from './append-theme-item.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, MatButtonModule, MatFormFieldModule, MatSelectModule],
  declarations: [AppendThemeItemComponent],
  exports: [AppendThemeItemComponent],
})
export class AppendThemeItemModule {}
