import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {SubscriptionData} from '@thebell/common/models/subscription-data';
import {EnvironmentService} from '@thebell/common/services/core/environment';

@Component({
  selector: 'app-widget-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent {
  @Input() basis: string;
  loading = false;
  subscribed = false;
  subscriptionTypes = [
    {
      slug: 'all',
      title: 'Все',
    },
    {
      slug: 'morning',
      title: 'Утренняя',
    },
    {
      slug: 'evening',
      title: 'Вечерняя',
    },
    {
      slug: 'weekly',
      title: 'Итоги недели',
    },
    {
      slug: 'tech',
      title: 'Технорассылка',
    },
    {
      slug: 'en',
      title: 'The Bell in English',
    },
  ];

  subscriptionData = new SubscriptionData().deserialize({
    email: '',
    newsletterTypes: {
      all: true,
      morning: false,
      weekly: false,
      evening: false,
      tech: false,
      en: false,
    },
  });

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private envService: EnvironmentService
  ) {}

  subscribe() {
    const {apiUrl} = this.envService.getEnvironment();
    if (this.subscriptionData.email.length === 0 || this.subscriptionTypesChecker() || this.loading) return;
    this.loading = true;
    const trueKeys = Object.keys(this.subscriptionData.newsletterTypes).filter(key => this.subscriptionData.newsletterTypes[key] === true);
    const resultString = trueKeys.join(',');
    console.log('subscribe', resultString)
    ga('send', 'event', 'main_page', 'main_page_widget_mail_subscribe');
    dataLayer.push({
      'event': 'main_page_widget_mail_subscribe', 'category': 'main_page', 'type': resultString
    });
    // for (const key of Object.keys(this.subscriptionData.newsletterTypes)) {
    //   if (this.subscriptionData.newsletterTypes[key])
    //     ga('send', 'event', 'main_page', 'main_page_widget_mail_subscribe', key);
    //   if (this.subscriptionData.newsletterTypes[key])
    //     dataLayer.push({
    //       'event': 'event', 'eventCategory': 'main_page', 'eventAction': 'main_page_widget_mail_subscribe', 'eventLabel': key
    //     });
    // }
    this.http.post(apiUrl + 'email_subscription', this.subscriptionData).subscribe(
      () => {
        if (this.subscriptionData.newsletterTypes.all) {
          localStorage.setItem('mc_sub_morning', '1');
          localStorage.setItem('mc_sub_evening', '1');
          localStorage.setItem('mc_sub_weekly', '1');
        }
        if (this.subscriptionData.newsletterTypes.morning) localStorage.setItem('mc_sub_morning', '1');
        if (this.subscriptionData.newsletterTypes.weekly) localStorage.setItem('mc_sub_weekly', '1');
        if (this.subscriptionData.newsletterTypes.evening) localStorage.setItem('mc_sub_evening', '1');
        if (this.subscriptionData.newsletterTypes.tech) localStorage.setItem('mc_sub_ok_future', '1');
        if (this.subscriptionData.newsletterTypes.en) localStorage.setItem('mc_sub_en', '1');
        this.subscribed = true;
        this.cdr.markForCheck();
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.snackbar.open('Ошибка\n' + err.error.error, 'Закрыть');
      }
    );
  }

  subscriptionTypesChecker() {
    // проверка, что хотябы одна из рассылок отмечена
    return (
      Object.values(this.subscriptionData.newsletterTypes).filter((type) => {
        return type;
      }).length === 0
    );
  }

  clickSubscription($event: any, slug) {
    if (slug !== 'all' && $event) {
      this.subscriptionData.newsletterTypes.all = false;
    } else if (slug === 'all' && $event) {
      for (const key of Object.keys(this.subscriptionData.newsletterTypes)) {
        this.subscriptionData.newsletterTypes[key] = false;
      }
    }
    this.subscriptionData.newsletterTypes[slug] = $event;
  }
}
