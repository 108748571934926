import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import {ClientAuthService} from "../../../../../libs/common/services/api/client-auth/src";
import {AccountLoginService} from "../component/account-login/account-login.service";
import {
  ClientNestAuthService
} from "../../../../../libs/admin/services/api/api-admin/src/lib/services/client-auth/client-auth.service";


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly authNest: ClientNestAuthService,
    private readonly auth: ClientAuthService,
    private readonly accountLoginService: AccountLoginService,
  ) {}

  async runAuth(user: any, token: string, handleSuccessfulAuthentication: Function, handleError: Function) {
    if (this.isAuthenticated()) {
      console.log('User is already authenticated');
      return;
    }

    combineLatest([this.auth.login(user), this.authNest.auth(user), this.authNest.adKey(user), this.authNest.proKey(user)]).subscribe(
      async ([laravel, nest, adKey, proKey]) => {
        const min_expires_in = Math.min(
          +adKey.getClientAd.expires_in,
          +proKey.getClientPro.expires_in
        );

        ClientAuthService.setToken({
          laravel_access_token: laravel.access_token,
          nest_access_token: nest.loginClient.access_token,
          expires_in: min_expires_in * (24 * 60 * 60 * 1000),
          ad_key: adKey.getClientAd.ad_key,
          pro_key: proKey.getClientPro.pro_key
        });

        this.logoutAfter(min_expires_in * (24 * 60 * 60 * 1000));
        user = {...user, token};
        if (token === 'no_token') {
          this.auth.getUser().subscribe(
            (res) => {
              handleSuccessfulAuthentication(res);
            },
            () => {
              handleError('Неверный логин или пароль');
            }
          );
        } else {
          this.accountLoginService.initLoginAccount(user).subscribe(
            (resInit) => {
              this.auth.getUser().subscribe(
                (res) => {
                  handleSuccessfulAuthentication(res);
                },
                () => {
                  handleError('Неверный логин или пароль');
                }
              );
            },
            () => {
              handleError('Неверный код капчи');
            }
          );
        }
      },
      () => {
        handleError('Неверный логин или пароль');
      }
    );
  }

  async logoutAfter(duration: number) {
    await ClientAuthService.logoutTimer(duration).toPromise();
    ClientAuthService.logout();
  }

  isAuthenticated(): boolean {
    return this.auth.isAuthenticated();
  }
}
