import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import {SentryEvt} from '../types';

export class SentryService {

  constructor() { }

  send(evt: SentryEvt){
    const {message, email, payload, level} = evt
    Sentry.captureEvent({
      message,
      user: {
        email,
      },
      contexts: {
        payload
      },
      level,
    });
  }
}
