<div class="ad" *ngIf="!inAdmin()">
    <div [id]="'ad-sidebar-' + uuid" class="ad-sidebar" #ad_sidebar></div>
    <div class="ads-1" #ads_1></div>
    <div [class]="['widget-container', 'widget-container-' + uuid]" #widget_container></div>
    <div [id]="'yandex_rtb_' + uuid" #rtb></div>
</div>
<div class="ad admin-preset-ad grid-container" *ngIf="inAdmin()">
    <div class="wrap">
        <p>{{name}}</p>
    </div>
</div>
