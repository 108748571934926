import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {EnvironmentService} from '@thebell/common/services/core/environment';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {JsonLdService} from 'ngx-seo';
import {StickyBannerService} from '../../services/sticky-banner.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  renderer: string;
  prod: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    private envService: EnvironmentService,
    private router: Router,
    private jsonLdService: JsonLdService,
  ) {
    ga('set', 'location', environment.baseUrl + this.router.url.replace(/\/$/, '').replace(/^\//, ''));
    ga('send', 'pageview');
    //dataLayer.push({'event': 'pageview'});
    this.renderer = isPlatformBrowser(this.platformId) ? 'browser' : 'server';
    this.prod = this.envService.getEnvironment().production;
  }

  ngOnInit(): void {
    const newsArticle = this.jsonLdService.getObject('WebSite', {
      '@id': `${this.envService.getEnvironment().appUrl}#website`,
      url: `${this.envService.getEnvironment().appUrl}`,
      name: 'The Bell',
      potentialAction: this.jsonLdService.getObject('SearchAction', {
        target: `${this.envService.getEnvironment().appUrl}?s={search_term_string}`,
        'query-input': 'required name=search_term_string',
      }),
    });
    this.jsonLdService.setData(newsArticle);
  }
}
