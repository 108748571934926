import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() disabled = false;
  @Input() buttonClass = 'default';
  @Input() spanClass = 'default-text';
  @Input() buttonText = 'Отправить';
  @Output() buttonClick = new EventEmitter();

  click() {
    this.buttonClick.emit();
  }
}
