<div class="quiz" [formGroup]="quizForm" #quizTop>
    <div *ngIf="questionIndex === -1" class="quiz-description">
      <div *ngIf='quiz.description' class="quiz-description-container">
        <div class="quiz-description-text">{{ quiz.description }}</div>
      </div>
        <div class="quiz-description-container-start">
            <app-image
                [imageClass]="
                    detectedScreenChangeService.getScreenType() === 'mobile' ? 'quiz-cover-background' : 'quiz-cover'
                "
                [image]="getCoverImage()"
                [imageType]="detectedScreenChangeService.getScreenType() === 'mobile' ? 'background' : 'image'"
                [isResizable]="false"
            ></app-image>
            <app-button
                [buttonClass]="'default quiz start'"
                [buttonText]="'Начать тест'"
                (buttonClick)="startQuiz()"
            ></app-button>
        </div>
        <div *ngIf='quiz.additional_title' class="quiz-description-container-add">
            <div class="quiz-description-container-add-text">{{ quiz.additional_title }}</div>
            <div class="quiz-description-container-add-image">
                <app-image
                    [imageClass]="'quiz-cover'"
                    [image]="getAdditionalImage()"
                    [imageType]="'image'"
                    [isResizable]="false"
                ></app-image>
            </div>
        </div>
    </div>
    <div
        *ngIf="questionIndex > -1 && questionIndex < quiz.questions.length"
        formArrayName="questions"
        class="quiz-questions"
    >
        <div *ngFor="let question of questions.controls; let i = index" [formGroupName]="i">
            <div *ngIf="questionIndex === i" formArrayName="variants">
                <div class="quiz-questions-counter">{{ i + 1 }}/{{ questions.value.length }}</div>
                <div class="quiz-question">{{ question.value.question }}</div>
                <div
                    *ngFor="let variant of getVariants(question).controls; let j = index"
                    [formGroupName]="j"
                    class="quiz-question-variant"
                >
                    <input
                        [id]="'q-' + i + 'v' + j"
                        type="checkbox"
                        *ngIf="!question.value.multiple"
                        formControlName="selected"
                        (change)="respondToQuestion(questionIndex)"
                        [attr.disabled]="questionAnswered === i ? '' : null"
                    />
                    <input
                        [id]="'q-' + i + 'v' + j"
                        type="checkbox"
                        *ngIf="question.value.multiple"
                        formControlName="selected"
                        [attr.disabled]="questionAnswered === i ? '' : null"
                    />
                    <label
                        [for]="'q-' + i + 'v' + j"
                        class="quiz-question-variant-checkbox"
                        [ngClass]="{
                            selected: variant.value.selected,
                            answered: questionAnswered === i,
                            correct: questionAnswered === i && variant.value.weight > 0,
                            wrong: questionAnswered === i && variant.value.selected && variant.value.weight === 0
                        }"
                    >
                        <div class="quiz-question-variant-checkbox-text">{{ variant.value.variant }}</div>
                        <div
                            *ngIf="questionAnswered === i && variant.value.selected && variant.value.comment"
                            class="quiz-question-variant-comment"
                            [innerHTML]="sanitizer.bypassSecurityTrustHtml(variant.value.comment)"
                        ></div>
                        <div
                            *ngIf="questionAnswered === i && variant.value.selected && variant.value.recommendation"
                            class="quiz-question-variant-recommendation"
                        >
                            <app-image
                                [imageClass]="'quiz-recommendation'"
                                [image]="'/assets/images/icons.svg#info'"
                                [imageType]="'svg'"
                                [isResizable]="false"
                            ></app-image>
                            <div
                                class="quiz-question-variant-recommendation-text"
                                [innerHTML]="sanitizer.bypassSecurityTrustHtml(variant.value.recommendation)"
                            ></div>
                        </div>
                    </label>
                </div>
                <app-button
                    *ngIf="questionAnswered !== i && question.value.multiple"
                    [buttonText]="'Ответить'"
                    [buttonClass]="'default quiz'"
                    (buttonClick)="respondToQuestion(questionIndex)"
                    [disabled]="!checkMultipleAnswer(getVariants(question).value)"
                ></app-button>
                <app-button
                    *ngIf="questionAnswered === i || !question.value.required"
                    [buttonText]="questionIndex === quiz.questions.length - 1 ? 'Завершить тест' : 'Дальше'"
                    [buttonClass]="'default quiz'"
                    (buttonClick)="nextQuestion(questionIndex)"
                ></app-button>
            </div>
        </div>
    </div>
    <div *ngIf="questionIndex >= quiz.questions.length" class="quiz-result">
        <div class="quiz-result-image">
            <app-image
                [imageClass]="'quiz-cover'"
                [image]="getResultImage()"
                [imageType]="'image'"
                [isResizable]="false"
            ></app-image>
        </div>
        <div class="quiz-result-title">{{ result.title }}</div>
        <div class="quiz-result-description" [innerHTML]="sanitizer.bypassSecurityTrustHtml(result.description)"></div>
        <app-button
            [buttonText]="'Пройти тест еще раз'"
            [buttonClass]="'default quiz'"
            (buttonClick)="startQuiz()"
        ></app-button>
        <div class="quiz-result-share">
            <div class="quiz-result-share-text">Поделиться:</div>
            <a class="quiz-result-share-icon" *ngFor="let social of socials" [href]="social.href" target="_blank">
                <app-image
                    [imageClass]="'social ' + social.icon"
                    [image]="'/assets/images/icons.svg#' + social.icon"
                    imageType="svg"
                    [isResizable]="false"
                ></app-image>
            </a>
        </div>
    </div>
    <!--<div *ngIf="questionIndex >= quiz.questions.length" class="quiz-form">
        <iframe width="100%" height="100%" [src]="quizFormUrl" frameborder="0" scrolling="no"></iframe>
    </div>-->
</div>
