import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonComponent} from './button';
import {DialogComponent} from './dialog';
import {ImageModule} from './image';
import {SwitchButtonComponent} from './switch-button';
import {ShareButtonsComponent} from './share-buttons/share-buttons.component';
import {AuthorSocialComponent} from './author-social/author-social.component';
import {WidgetAuthorComponent} from './author-widget/widget-author.component';
import {QuizComponent} from './quiz/quiz.component';
import {PipesModule} from '@thebell/common/pipes';
import {PostAdsModule} from '@thebell/common/directives/post-ads';
import {OnScreenDirective} from '@thebell//frontend/element-on-screen-lib';

@NgModule({
  declarations: [
    ButtonComponent,
    DialogComponent,
    ShareButtonsComponent,
    SwitchButtonComponent,
    AuthorSocialComponent,
    WidgetAuthorComponent,
    QuizComponent,
    OnScreenDirective,
  ],
  imports: [
    CommonModule,
    MatRippleModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    ImageModule,
  ],
  exports: [
    ButtonComponent,
    DialogComponent,
    ImageModule,
    ShareButtonsComponent,
    SwitchButtonComponent,
    AuthorSocialComponent,
    WidgetAuthorComponent,
    QuizComponent,
    PostAdsModule,
    OnScreenDirective
  ],
})
export class CommonUiUtilsModule {}
