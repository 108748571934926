import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {combineLatest, EMPTY, empty, Observable, Subject} from 'rxjs';
import {ApiClientService} from '@thebell/common/services/api/api-client';
import {LayoutThemeDataService} from '@thebell/common/services/api/layout-theme-data';
import {LayoutThemesService} from '@thebell/common/services/api/layout-themes';
import {LayoutPresetsService} from '@thebell/common/services/api/layout-presets';
import {ThemeDataRequestOptions} from '@thebell/common/models/theme-data-request-options';
import {delayedRetry} from '@thebell/common/services/api/utils';
import {HelperService} from '@thebell/common/services/utils/helper';
import {Subscription} from 'rxjs/internal/Subscription';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LayoutWidgetsService {
  public subject = new Subject<any>();
  private inLoad: boolean[] = [];
  private fork: Observable<any>;
  private forkSub: Subscription;

  constructor(
    private api: ApiClientService,
    private layoutThemesService: LayoutThemesService,
    private layoutThemeDataService: LayoutThemeDataService,
    private layoutPresetsService: LayoutPresetsService,
    private helperService: HelperService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>
  ) {}

  collectData(options: ThemeDataRequestOptions) {
    const inLoadKey = JSON.stringify(options);
    if (undefined === this.inLoad[inLoadKey] || this.inLoad[inLoadKey] === false) {
      this.inLoad[inLoadKey] = true;

      const themes = this.helperService.authorized()
        ? this.layoutThemesService.getAdminLayoutThemes()
        : this.layoutThemesService.getLayoutThemes();

      const presets = this.helperService.authorized()
        ? this.layoutPresetsService.getAdminLayoutPresets()
        : this.layoutPresetsService.getLayoutPresets();

      const themeData = this.helperService.authorized()
        ? this.layoutThemeDataService.getAdminLayoutThemeData(options)
        : this.layoutThemeDataService.getLayoutThemeData(options);

      this.fork = combineLatest([themes, presets, themeData]);
      if (isPlatformBrowser(this.platformId)) {
        this.fork = this.fork.pipe(delayedRetry(10000, 5)) as Observable<any>;
      }
      this.forkSub = this.fork.subscribe(([themes2, presets2, themeData2]) => {
        this.inLoad[inLoadKey] = false;
        this.subject.next([themes2, themeData2, presets2]);
      });
    }
  }

  unsubscribe() {
    if (this.forkSub && !this.forkSub.closed) {
      this.forkSub.unsubscribe();
    }
  }
}
