import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {UrlBuilderService} from '@thebell/common/services/utils/url-builder';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {Asset, AssetRoles} from '@thebell/common/models/asset';
import {EnvironmentService} from '@thebell/common/services/core/environment';
import {ImageVersion} from '@thebell/data-transfer-objects';

@Directive({
  selector: '[appImageSrc]',
})
export class ImageSrcDirective implements OnInit, OnChanges, OnDestroy {
  @Input() img: string | Asset[];
  @Input() isResizable = true;
  @Input() type = 'image';
  @Input() addition = {basis: null};
  @Output() setImgSrc = new EventEmitter<string>();
  @Output() setSrcSet = new EventEmitter<string | string[]>();
  subscribe: Subscription;

  constructor(
    public renderer: Renderer2,
    public hostElement: ElementRef,
    private detectedScreenChangeService: DetectedScreenChangeService,
    private urlBuilderService: UrlBuilderService,
    private envService: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.setImage(this.detectedScreenChangeService.getScreenType());
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setImage(this.detectedScreenChangeService.getScreenType());
    this.subscribe = this.detectedScreenChangeService.screenTypeChanged.subscribe((e) => {
      this.setImage(e);
    });
  }

  ngOnDestroy(): void {
    this.subscribe?.unsubscribe();
  }

  setImage(screenType: string) {
    switch (this.type) {
      case 'image':
        if (typeof this.img === 'string') this.setImgSrc.emit(this.getImageUrlFromString(screenType));
        else {
          this.setSrcSet.emit(this.getSrcSetData());
        }
        this.renderer.removeAttribute(this.hostElement.nativeElement, 'style');
        break;
      case 'svg':
        this.setImgSrc.emit(this.getImageUrlFromString(screenType));
        break;
      case 'background':
      case 'background-shadow':
        if (typeof this.img === 'string') {
          this.renderer.setAttribute(
            this.hostElement.nativeElement,
            'style',
            'background-image: url("' + this.getImageUrlFromString(screenType) + '")'
          );
        } else {
          this.setSrcSet.emit(this.getSrcSetData());
        }
        break;
      case 'background-color':
      case 'background-color-shadow':
        this.renderer.setAttribute(
          this.hostElement.nativeElement,
          'style',
          'background-color: ' + this.getImageUrlFromString(screenType)
        );
        if (this.getImageUrlFromString(screenType) === '#FFFFFF') {
          this.renderer.setAttribute(
            this.hostElement.nativeElement,
            'style',
            'box-sizing: border-box; border: 0.5px solid #E6E6E6; background-color: ' +
              this.getImageUrlFromString(screenType)
          );
        }
        break;
    }
  }

  getImageUrlFromString(screenType: string) {
    const div = this.hostElement;
    if (div && div.nativeElement.clientWidth) {
      const divWidth = div.nativeElement.clientWidth;
      screenType = this.detectedScreenChangeService.getScreenTypeByWidth(divWidth);
    }
    const imgUrl = this.img;
    if (this.type.includes('color')) this.isResizable = false;
    if (imgUrl) {
      return this.urlBuilderService.buildUrl(imgUrl as string, screenType, this.isResizable);
    }
    return '';
  }

  getSrcSetData() {
    let imagesArray = this.img as Asset[];
    if (!Array.isArray(imagesArray)) imagesArray = [imagesArray];
    return (() => {
      const imageVersion = imagesArray[0]?.version || 1;
      switch (imageVersion) {
        case ImageVersion.UPLOADED_BY_NEST: {
          return this.getNestVersionSrcSet(imagesArray[0]);
        }
        case ImageVersion.DEFAULT:
        default: {
          return this.getDefaultVersionSrcSet(imagesArray);
        }
      }
    })();
  }

  selectImageByMaxWidth(images, maxWidth): Asset {
    const largeSizeAssets = images
      .filter((asset) => asset.width >= maxWidth)
      .sort((a, b) => {
        return a.width - b.width;
      });
    const smallerSizeAssets = images
      .filter((asset) => asset.width < maxWidth)
      .sort((a, b) => {
        return b.width - a.width;
      });
    const assets = largeSizeAssets.concat(smallerSizeAssets);

    return assets[0];
  }

  getImageSrc(asset: Asset) {
    const {baseUrl} = this.envService.getEnvironment();
    let src;
    if (asset?.src) {
      src = asset.src;
    } else if (asset?.path) {
      src = (asset.path.includes('http') ? '' : baseUrl) + asset.path;
    }
    return src;
  }

  private getDefaultVersionSrcSet(imagesArray: Asset[]) {
    const srcSet = [];
    const imagesArrayWithWidth = imagesArray.filter((imgA) => imgA && imgA?.width !== null);
    let media = '';

    const breakpoints = [320, 375, 480, 640, 767, 1024, 1025];
    const widgetBreakpoint_1x = {
      100: {
        1025: 1264,
        1024: 1016,
        767: 759,
        640: 632,
        480: 632,
        375: 632,
        320: 632,
      },
      66: {
        1025: 817,
        1024: 759,
        767: 759,
        640: 632,
        480: 508,
        375: 406,
        320: 326,
      },
      50: {
        1025: 632,
        1024: 508,
        767: 759,
        640: 632,
        480: 508,
        375: 406,
        320: 326,
      },
      33: {
        1025: 406,
        1024: 326,
        767: 759,
        640: 632,
        480: 508,
        375: 406,
        320: 326,
      },
      25: {
        1025: 326,
        1024: 252,
        767: 252,
        640: 252,
        480: 252,
        375: 252,
        320: 252,
      },
    };
    const widgetBreakpoint_2x = {
      100: {
        1025: 2468,
        1024: 1984,
        767: 1634,
        640: 1264,
        480: 1264,
        375: 1264,
        320: 1264,
      },
      66: {
        1025: 1634,
        1024: 1634,
        767: 1634,
        640: 1264,
        480: 1016,
        375: 759,
        320: 632,
      },
      50: {
        1025: 1264,
        1024: 1016,
        767: 1634,
        640: 1264,
        480: 1016,
        375: 759,
        320: 632,
      },
      33: {
        1025: 817,
        1024: 759,
        767: 1634,
        640: 1264,
        480: 1016,
        375: 759,
        320: 632,
      },
      25: {
        1025: 632,
        1024: 508,
        767: 508,
        640: 508,
        480: 508,
        375: 508,
        320: 508,
      },
    };
    if (imagesArrayWithWidth.length !== 0)
      for (let i = 0; i < breakpoints.length; i++) {
        let width1x;
        let width2x;
        if (this.addition.basis) {
          width1x = widgetBreakpoint_1x[this.addition.basis][breakpoints[i]];
          width2x = widgetBreakpoint_2x[this.addition.basis][breakpoints[i]];
        } else {
          width1x = breakpoints[i];
          width2x = breakpoints[i] * 2;
        }

        if (i === 0) {
          media = '(max-width: ' + breakpoints[i] + 'px)';
        } else if (i === breakpoints.length - 1) {
          media = '(min-width: ' + breakpoints[i] + 'px)';
        } else {
          media = '(min-width: ' + (breakpoints[i - 1] + 1) + 'px) and (max-width: ' + breakpoints[i] + 'px)';
        }

        srcSet.push({
          image:
            this.getImageSrc(this.selectImageByMaxWidth(imagesArrayWithWidth, width1x)) +
            ', ' +
            this.getImageSrc(this.selectImageByMaxWidth(imagesArrayWithWidth, width2x)) +
            ' 2x',
          media,
        });
      }

    if (srcSet.length === 0) {
      srcSet.push({
        image: this.getImageSrc(imagesArray[0]),
        media: '',
      });
    }

    return srcSet;
  }

  private getNestVersionSrcSet(image: Asset) {
    switch (image.role) {
      case AssetRoles.author_image:
        return this.getNestVersionOfAuthorImage(image);
      case AssetRoles.widget_NewsComponent_background_image:
      case AssetRoles.main_image:
        return this.getNestVersionSrcSetOfBackgroundWidget(image);
      default:
        return [];
    }
  }

  private getNestVersionSrcSetOfBackgroundWidget(image: Asset) {
    const srcSet = [];
    const breakpoints = [320, 375, 480, 640, 767, 1024, 1025];
    // 75 как 66 потому что не сильно отличаются
    const widgetSizes = {
      100: {
        1025: [[1264, 500], [2528, 1000],],
        1024: [[1016, 500], [2032, 1000]],
        767: [[759, 500], [1518, 1000]],
        640: [[632, 500], [1264, 1000]],
        480: [[508, 500], [1016, 1000]],
        375: [[406, 500], [812, 1000]],
        320: [[406, 500], [812, 1000]],
      },
      75: {
        1025: [[817, 300], [1634, 600]],
        1024: [[759, 300], [1518, 600]],
        767: [[759, 300], [1518, 600]],
        640: [[632, 300], [1264, 600]],
        480: [[508, 300], [1016, 600]],
        375: [[406, 300], [812, 600]],
        320: [[406, 300], [812, 600]],
      },
      66: {
        1025: [[817, 300], [1634, 600]],
        1024: [[759, 300], [1518, 600]],
        767: [[759, 300], [1518, 600]],
        640: [[632, 300], [1264, 600]],
        480: [[508, 300], [1016, 600]],
        375: [[406, 300], [812, 600]],
        320: [[406, 300], [812, 600]],
      },
      50: {
        1025: [[632, 300], [1264, 600]],
        1024: [[508, 300], [1016, 600]],
        767: [[759, 300], [1518, 600]],
        640: [[632, 300], [1264, 600]],
        480: [[508, 300], [1016, 600]],
        375: [[406, 300], [812, 600]],
        320: [[406, 300], [812, 600]],
      },
      33: {
        1025: [[406, 300], [812, 600]],
        1024: [[406, 300], [812, 600]],
        767: [[759, 300], [1518, 600]],
        640: [[632, 300], [1264, 600]],
        480: [[508, 300], [1016, 600]],
        375: [[406, 300], [812, 600]],
        320: [[406, 300], [812, 600]],
      },
    };
    breakpoints.forEach((breakpoint) => {
      if (!this.addition.basis) return;
      const [size_1x, size_2x] = widgetSizes[this.addition.basis][breakpoint];
      const src = (() => {
        const [, name, ext] = image.src.match(/(.*).(jpg|jpeg|png|webp|svg)/);
        return `${name}_${size_1x[0]}_${size_1x[1]}.webp 1x, ${name}_${size_2x[0]}_${size_2x[1]}.webp 2x`;
      })();
      const media = `(max-width: ${breakpoint}px)`;
      srcSet.push({
        image: src,
        media,
      });
    });
    if (srcSet.length > 0) {
      srcSet[srcSet.length - 1].media = srcSet[srcSet.length - 1].media.replace('max-width', 'min-width');
    }

    return srcSet;
  }

  private getNestVersionOfAuthorImage(image: Asset) {
    const [, name, ext] = image.src.match(/(.*)\.(.*)$/);
    return [
      {
        image: `${name}_100_0.${ext}`,
        media: '',
      },
    ];
  }
}
