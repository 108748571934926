import {GqlDto} from './gql.dto';
import {Post} from './post.dto';

export enum ImageVersion {
  DEFAULT = 1,
  UPLOADED_BY_NEST = 2,
}

export enum ImageSubject {
  AUTHOR_IMAGE = 'author_image',
  WIDGET_NEWS_COMPONENT_BACKGROUND_IMAGE = 'widget_NewsComponent_background_image',
  PLUGIN = 'plugin',
  THEME_FILE = 'theme_file',
  CONTENT_EDITOR_FILE = 'content_editor_file',
  WIDGET_QUOTE_COMPONENT_PHOTO_IMAGE = 'widget_QuoteComponent_photo_image',
  SHARE_IMAGE = 'share_image',
  SHARE_IMAGE_TMP = 'share_image_tmp',
  MAIN_IMAGE = 'main_image',
  POST_INNER_IMAGE = 'post_inner_image',
  QUIZ_COVER = 'quiz_cover',
  QUIZ_ADDITIONAL = 'quiz_additional',
  THEME_IMAGE = 'theme_image',
  PARTNER_LOGO = 'partner_logo',
  GALLERY_IMAGE = 'gallery_image',
  NEWS_ADVERTISING_IMAGE = 'widget_NewsComponent_advertising_image',
  SHARE_IMAGE_TINKOFF = 'share_image_tinkoff',
}

export enum ImageRole {
  AUTHOR_IMAGE = 1,
  WIDGET_NEWS_COMPONENT_BACKGROUND_IMAGE = 2,
  PLUGIN = 3,
  THEME_FILE = 4,
  CONTENT_EDITOR_FILE = 5,
  WIDGET_QUOTE_COMPONENT_PHOTO_IMAGE = 6,
  SHARE_IMAGE = 7,
  MAIN_IMAGE = 8,
  QUIZ_COVER = 9,
  QUIZ_ADDITIONAL = 10,
  THEME_IMAGE = 11,
  SHARE_IMAGE_TMP = 12,
  POST_INNER_IMAGE = 13,
  PARTNER_LOGO = 14,
  GALLERY_IMAGE = 16, // ToDo: разобраться с ролями для "на правах рекламы"
  NEWS_ADVERTISING_IMAGE = 15,
  SHARE_IMAGE_TINKOFF = 17,
}

export const RoleToSubjectMap = {
  [ImageRole.AUTHOR_IMAGE]: ImageSubject.AUTHOR_IMAGE,
  [ImageRole.WIDGET_NEWS_COMPONENT_BACKGROUND_IMAGE]: ImageSubject.WIDGET_NEWS_COMPONENT_BACKGROUND_IMAGE,
  [ImageRole.THEME_FILE]: ImageSubject.THEME_FILE,
  [ImageRole.CONTENT_EDITOR_FILE]: ImageSubject.CONTENT_EDITOR_FILE,
  [ImageRole.WIDGET_QUOTE_COMPONENT_PHOTO_IMAGE]: ImageSubject.WIDGET_QUOTE_COMPONENT_PHOTO_IMAGE,
  [ImageRole.SHARE_IMAGE]: ImageSubject.SHARE_IMAGE,
  [ImageRole.MAIN_IMAGE]: ImageSubject.MAIN_IMAGE,
  [ImageRole.POST_INNER_IMAGE]: ImageSubject.POST_INNER_IMAGE,
  [ImageRole.QUIZ_COVER]: ImageSubject.QUIZ_COVER,
  [ImageRole.QUIZ_ADDITIONAL]: ImageSubject.QUIZ_ADDITIONAL,
  [ImageRole.THEME_IMAGE]: ImageSubject.THEME_IMAGE,
  [ImageRole.SHARE_IMAGE_TMP]: ImageSubject.SHARE_IMAGE_TMP,
  [ImageRole.PARTNER_LOGO]: ImageSubject.PARTNER_LOGO,
  [ImageRole.GALLERY_IMAGE]: ImageSubject.GALLERY_IMAGE,
  [ImageRole.NEWS_ADVERTISING_IMAGE]: ImageSubject.NEWS_ADVERTISING_IMAGE,
  [ImageRole.SHARE_IMAGE_TINKOFF]: ImageSubject.SHARE_IMAGE_TINKOFF,
};

export const SubjectToRoleMap = {
  [ImageSubject.AUTHOR_IMAGE]: ImageRole.AUTHOR_IMAGE,
  [ImageSubject.WIDGET_NEWS_COMPONENT_BACKGROUND_IMAGE]: ImageRole.WIDGET_NEWS_COMPONENT_BACKGROUND_IMAGE,
  [ImageSubject.THEME_FILE]: ImageRole.THEME_FILE,
  [ImageSubject.CONTENT_EDITOR_FILE]: ImageRole.CONTENT_EDITOR_FILE,
  [ImageSubject.WIDGET_QUOTE_COMPONENT_PHOTO_IMAGE]: ImageRole.WIDGET_QUOTE_COMPONENT_PHOTO_IMAGE,
  [ImageSubject.SHARE_IMAGE]: ImageRole.SHARE_IMAGE,
  [ImageSubject.MAIN_IMAGE]: ImageRole.MAIN_IMAGE,
  [ImageSubject.POST_INNER_IMAGE]: ImageRole.POST_INNER_IMAGE,
  [ImageSubject.QUIZ_COVER]: ImageRole.QUIZ_COVER,
  [ImageSubject.QUIZ_ADDITIONAL]: ImageRole.QUIZ_ADDITIONAL,
  [ImageSubject.THEME_IMAGE]: ImageRole.THEME_IMAGE,
  [ImageSubject.SHARE_IMAGE_TMP]: ImageRole.SHARE_IMAGE_TMP,
  [ImageSubject.PARTNER_LOGO]: ImageRole.PARTNER_LOGO,
  [ImageSubject.GALLERY_IMAGE]: ImageRole.GALLERY_IMAGE,
  [ImageSubject.NEWS_ADVERTISING_IMAGE]: ImageRole.NEWS_ADVERTISING_IMAGE,
  [ImageSubject.SHARE_IMAGE_TINKOFF]: ImageRole.SHARE_IMAGE_TINKOFF,
};

export interface Asset {
  id: number;
  path: string;
  src: string;
  prefix: string;
  postfix: string;
  params: string;
  mode: string;
  subject: string;
  isOrigin: boolean;
  width: number;
  height: number;
  version: number;
  role: number;
  mimeType: string;
}

export interface AssetInput {
  id?: number;
  src: string;
  subject: string;
  mode: string;
}

export interface BindAssetInput {
  id?: number;
  type: string;
  uploaded: AssetInput[];
  unbind: AssetInput[];
}

export interface AssetListInputDto {
  id: number;
  type: string;
}

export type AssetListDto = GqlDto<'assets', Asset[]>;
