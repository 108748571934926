import {Deserializable} from '@thebell/common/models/deserializable';

export class ThemeDataRequestOptions extends Deserializable {
  constructor(
    public route: string = 'main',
    public param?: any,
    public last_post_id: number = null,
    public index: number = 1,
    public count: number = 1,
    public lang: string = 'ru',
    public isAdmin: number = 0,
    public accepted_data_theme_id: number = null,
    public accepted_data_preset_id: number = null
  ) {
    super();
  }
}
