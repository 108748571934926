import {Injectable} from '@angular/core';

export type colorType = {value: string; text: string};

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  private colorList = [
    {value: '#FFFFFF', text: 'белый'},
    {value: '#EB3323', text: 'красный'},
    {value: '#1787FB', text: 'голубой'},
  ];

  getColorList(): colorType[] {
    return this.colorList;
  }

  valueIsColor(str: string) {
    return !!this.getColorList().find((el) => el.value === str);
  }

  colorIsRed(color) {
    const r = this.hexToRgb(color)[0];
    const g = this.hexToRgb(color)[1];
    const b = this.hexToRgb(color)[2];

    const h = this.rgbToHsl(r, g, b)[0]; // оттенок
    const s = this.rgbToHsl(r, g, b)[1]; // насыщеность
    const l = this.rgbToHsl(r, g, b)[2]; // яркость

    return (h < 30 || h > 330) && s > 30 && l > 30 && l < 90;
  }

  colorIsBlue(color) {
    const r = this.hexToRgb(color)[0];
    const g = this.hexToRgb(color)[1];
    const b = this.hexToRgb(color)[2];

    const h = this.rgbToHsl(r, g, b)[0]; // оттенок
    const s = this.rgbToHsl(r, g, b)[1]; // насыщеность
    const l = this.rgbToHsl(r, g, b)[2]; // яркость

    return h > 200 && h < 270 && s > 30 && l > 30 && l < 90;
  }

  colorIsWhite(color) {
    const r = this.hexToRgb(color)[0];
    const g = this.hexToRgb(color)[1];
    const b = this.hexToRgb(color)[2];

    return r === 255 && g === 255 && b === 255;
  }

  hexToRgb(hex) {
    const rgb = parseInt(hex.replace('#', ''), 16);
    // eslint-disable-next-line
    const r = (rgb >> 16) & 0xff;
    // eslint-disable-next-line
    const g = (rgb >> 8) & 0xff;
    // eslint-disable-next-line
    const b = (rgb >> 0) & 0xff;
    return [r, g, b];
  }

  rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h;
    let s;
    let l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = d / (1 - Math.abs(2 * l - 1));
      switch (max) {
        case r:
          h = ((g - b) / d) % 6;
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h = Math.round(h * 60);
      while (h < 0) h += 360;
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1);
    }

    return [h, s, l];
  }
}
