import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReloadOnNavigateDirective} from '../directive/reload-on-navigate.directive';

@NgModule({
  declarations: [ReloadOnNavigateDirective],
  imports: [CommonModule],
  exports: [ReloadOnNavigateDirective],
})
export class ReloadOnNavigateModule {}
