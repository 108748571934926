import {Category} from '../contracts/types';
import {Controller} from '../contracts/controller';

export class SimpleThirdParty implements Controller {
  constructor(public readonly name: string, public readonly categories: Category[], public readonly src?: string) {
    if (categories.length === 0) {
      throw new Error('At least one category must be provided');
    }
  }

  public get isConsentResponsive() {
    return (
      typeof (this as Controller).init === 'function' &&
      typeof (this as Controller).onUpdatePrivacyConsents === 'function'
    );
  }

  public hasCategory(category: string): boolean {
    return this.categories.includes(category as Category);
  }

  public hasAnyCategory(categories: string[]): boolean {
    return categories.some((category) => this.hasCategory(category));
  }
}
