import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/*
 * class for tracking the state of page loading.
 * - true - page loaded
 * - false - page is loading
 * */
export class ContentLoadingService {
  private subject$ = new BehaviorSubject(false);
  /*
   * set state of page loading
   * - true - page loaded
   * - false - page is loading
   * */
  set(value: boolean) {
    this.subject$.next(value);
  }
  /*
   * get state of page loading
   * - true - page loaded
   * - false - page is loading
   * */
  getValue(): boolean {
    return this.subject$.getValue();
  }
  /*
   * get observer of page loading
   * */
  get$(): BehaviorSubject<boolean> {
    return this.subject$;
  }
}
