import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AppendThemeItemModule} from './append-theme-item';
import {LineModule} from './line';
import {ThemeComponent} from './theme.component';

@NgModule({
  imports: [CommonModule, RouterModule, LineModule, AppendThemeItemModule],
  declarations: [ThemeComponent],
  exports: [ThemeComponent],
})
export class ThemeModule {}
