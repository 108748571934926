import {Injectable} from '@angular/core';
import {NumberOfDay, News, Lightning, BellClub, Quote, Advertisement} from '@thebell/common/models/widget-style-data';
import {AssetRoles} from '@thebell/common/models/asset';
import {Post, CollapsePost} from '@thebell/common/models/post';
import {WidgetTypes} from '@thebell/common/models/widget-types';
import {WidgetItem} from '@thebell/common/models/widget-item';
import {DateConverterService} from '@thebell/common/services/utils/date-converter';
import {BaseWidget} from '@thebell/common/models/widget-style-data';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ru';
dayjs.extend(relativeTime);
dayjs.extend(utc);

@Injectable({
  providedIn: 'root',
})
export class DataMapperService {
  private widgetItem: WidgetItem;
  private widgetStyleData: any;
  constructor(private dts: DateConverterService) {}

  map(widgetItem: WidgetItem): WidgetTypes {
    this.widgetItem = widgetItem;
    this.widgetStyleData = {...this.widgetItem.data.widget_style_data};
    if (!widgetItem.data.widgetStyle) return;
    switch (widgetItem.data.widgetStyle.slug) {
      case 'NewsComponent':
        return this.newsInit(new News());

      case 'QuoteComponent':
        return this.quoteInit(new Quote());

      case 'NumberOfDayComponent':
        return this.numberOfDayInit(new NumberOfDay());

      case 'BellClubComponent':
        return this.bellClubInit(new BellClub());

      case 'LightningComponent':
        return this.lightningInit(new Lightning());

      case 'PostComponent':
        return this.postInit(new CollapsePost());

      case 'AdvertisementComponent':
        return this.adInit(new Advertisement());
    }
  }

  convertDate() {
    const lastDate = this.widgetItem.data.item.publish_date_ts * 1000;
    const difference = Date.now() - lastDate;
    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    const minutesDifference = Math.floor(difference / 1000 / 60);
    if (minutesDifference <= 59) {
      return this.dts.num_word('min', minutesDifference, 10);
    } else if (hoursDifference <= 23) {
      return this.dts.num_word('h', hoursDifference, 10);
    }
    return dayjs.utc(lastDate).locale('ru').format('D MMMM YYYY');
  }

  initCommons(widget: any) {
    // Это для того, чтобы удалить из цвета текст и остаивть толкьо value
    if (this.widgetStyleData.background?.value) {
      this.widgetStyleData.background = this.widgetStyleData.background.value;
    }
    if (this.widgetItem.data.item) {
      if (this.widgetItem.data.item.type) widget.type = this.widgetItem.data.item.type;
      if (this.widgetItem.data.item.url) widget.url = this.widgetItem.data.item.url;
      if (this.widgetItem.data.item.slug) widget.slug = this.widgetItem.data.item.slug;
      if (this.widgetItem.data.item.type === 'external_post') widget.slug = this.widgetItem.data.item.url;
    }
    widget.date = this.convertDate();
    if (this.widgetItem.data.widget_style?.fields) {
      this.widgetItem.data.widget_style.fields
        .filter((field) => field.default !== undefined)
        .map((field) => {
          if (!this.widgetStyleData[field.field]) {
            this.widgetStyleData[field.field] = field.default;
          }
        });
      return widget;
    } else {
      return widget;
    }
  }

  newsInit(widget: News): News {
    widget = this.initCommons(widget);
    if (this.widgetStyleData.background_image_id) {
      widget.background_image_id = this.widgetStyleData.background_image_id;
    }
    if (this.widgetStyleData.advertising_image_id) {
      widget.advertising_image_id = this.widgetStyleData.advertising_image_id;
    }
    if (this.widgetStyleData.additionalCategory) {
      widget.additionalCategory = this.widgetStyleData.additionalCategory;
    }
    if (this.widgetStyleData.advertising) {
      widget.advertising = this.widgetStyleData.advertising;
    }

    widget.advertising_image = this.widgetStyleData.advertising_image;
    widget.advertising_link = this.widgetStyleData.advertising_link;
    if (this.widgetItem.data.item.assets) {
      let backgroundImageAsset = null;
      let backgroundAssets = null;
      if (this.widgetStyleData.background_image_id) {
        backgroundImageAsset = this.getOptimizedImage(AssetRoles.widget_NewsComponent_background_image);
        backgroundAssets = this.getImagesByRole(AssetRoles.widget_NewsComponent_background_image);
      }

      if (this.widgetStyleData.main_image_as_background) {
        backgroundImageAsset = this.getOptimizedImage(AssetRoles.main_image);
        backgroundAssets = this.getImagesByRole(AssetRoles.main_image);
      }
      if (backgroundImageAsset) widget.background_image = backgroundImageAsset;
      if (backgroundAssets) widget.background_assets = backgroundAssets;
    }
    if (this.widgetStyleData.background_image && !widget.background_image) {
      widget.background_image = this.widgetStyleData.background_image;
    }
    if (this.widgetStyleData.background) {
      widget.background = this.widgetStyleData.background;
    }
    widget.title = this.widgetItem.data.item.title;
    if (this.widgetStyleData.title) {
      widget.title = this.widgetStyleData.title;
    }
    if (this.widgetItem.data.item.type === 'external_post') {
      widget.category = this.widgetItem.data.item.category
        ? this.widgetItem.data.item.category
        : {title: 'Партнеры The Bell', slug: 'partnery-the-bell'};
      const backgroundImageAsset = this.getOptimizedImage(AssetRoles.widget_NewsComponent_background_image);
      if (backgroundImageAsset) {
        if (backgroundImageAsset?.path?.includes('http')) backgroundImageAsset.src = backgroundImageAsset.path;
        widget.background_image = backgroundImageAsset;
      }
      const backgroundAssets = this.getImagesByRole(AssetRoles.widget_NewsComponent_background_image);
      if (backgroundAssets) widget.background_assets = backgroundAssets;
    } else if (this.widgetItem.data.item.type === 'page') {
      widget.category = {title: 'Bell.Club', slug: 'bellclub'};
    } else {
      widget.category = this.getPrimaryCategory(this.widgetItem);
    }
    widget.main_image_as_background = this.widgetStyleData.main_image_as_background;
    return widget;
  }

  quoteInit(widget: Quote): Quote {
    widget = this.initCommons(widget);
    if (this.widgetStyleData.background) {
      widget.background = this.widgetStyleData.background;
    }

    widget.author = this.widgetStyleData.author;
    widget.author_description = this.widgetStyleData.author_description ?? '';
    widget.quote = this.widgetStyleData.quote;
    return widget;
  }

  numberOfDayInit(widget: NumberOfDay): NumberOfDay {
    widget = this.initCommons(widget);
    if (this.widgetStyleData.background) {
      widget.background = this.widgetStyleData.background;
    } else if(this.widgetItem.data.widget_style?.fields){
      widget.background = this.widgetItem.data.widget_style.fields.find(({field}) => field === 'background').default;
    }

    widget.text = this.widgetStyleData.text;
    // eslint-disable-next-line id-blacklist
    widget.number = this.widgetStyleData.digit || this.widgetStyleData.number;
    return widget;
  }

  bellClubInit(widget: BellClub): BellClub {
    widget = this.initCommons(widget);
    return widget;
  }

  lightningInit(widget: Lightning): Lightning {
    widget = this.initCommons(widget);
    widget.title = this.widgetStyleData.title || this.widgetItem.data.item.title;
    return widget;
  }

  postInit(widget: Post): Post {
    widget = Object.assign({}, this.widgetItem.data.item);
    widget.date = this.convertDate();
    widget.content = this.widgetItem.data.item.content;
    widget.widget_style_data = this.widgetItem.data.widget_style_data;
    return widget;
  }

  adInit(widget: Advertisement): Advertisement {
    widget.title = this.widgetItem.data.item.title;
    widget.advertisement_provider_id = this.widgetItem.data.item.advertisement_provider_id;
    widget.json = this.widgetItem.data.item.json;
    widget.adminPreview = !!this.widgetItem.data.item.adminPreview;
    return widget;
  }

  mapLastNewsLetter(widgetItem: WidgetItem): News {
    this.widgetItem = widgetItem;
    return this.lastNewsLetterInit(new News());
  }

  lastNewsLetterInit(widget: News): News {
    if (this.widgetItem.data.item.slug) widget.slug = this.widgetItem.data.item.slug;
    widget.category = this.getPrimaryCategory(this.widgetItem);
    widget.title = this.widgetItem.data.item.title;
    widget.date = dayjs(this.widgetItem.data.item.publish_date).locale('ru').fromNow();
    if (this.widgetItem.data.item.assets) {
      let backgroundImageAsset = this.getOptimizedImage(AssetRoles.widget_NewsComponent_background_image);
      let backgroundAssets = this.getImagesByRole(AssetRoles.widget_NewsComponent_background_image);

      if (!backgroundImageAsset) {
        backgroundImageAsset = this.getImagesByRole(AssetRoles.main_image);
        backgroundAssets = this.getImagesByRole(AssetRoles.main_image);
      }

      if (backgroundImageAsset) widget.background_image = backgroundImageAsset;
      if (backgroundAssets) widget.background_assets = backgroundAssets;
    }

    return widget;
  }

  getOptimizedImage(role) {
    const size =
      window.devicePixelRatio *
      (window.innerWidth < 768
        ? window.innerWidth
        : (Math.min(1280, window.innerWidth) * parseInt(this.widgetItem.basis, 10)) / 100);
    const largeSizeAssets = this.widgetItem.data.item.assets
      .filter((asset) => asset.width >= size && asset.role === role)
      .sort((a, b) => {
        return a.width - b.width;
      });
    const smallerSizeAssets = this.widgetItem.data.item.assets
      .filter((asset) => asset.width < size && asset.role === role)
      .sort((a, b) => {
        return b.width - a.width;
      });
    const assets = largeSizeAssets.concat(smallerSizeAssets);

    if (assets.length === 0) {
      return this.widgetItem.data.item.assets.find((asset) => asset.role === role);
    }

    return assets[0];
  }

  getImagesByRole(role) {
    return this.widgetItem.data.item.assets
      .filter((asset) => asset.role === role)
      .sort((a, b) => {
        return a.width - b.width;
      });
  }

  private getPrimaryCategory(widgetItem: WidgetItem) {
    if (widgetItem.data.item.categories) {
      for (const category of widgetItem.data.item.categories) {
        if (category.is_main) return category;
      }
    } else if (widgetItem.data.item.category) {
      return widgetItem.data.item.category;
    }

    return widgetItem.data.item.categories && widgetItem.data.item.categories.length > 0
      ? widgetItem.data.item.categories[0]
      : {title: 'новости', slug: 'news'};
  }
}
