import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ImageModule} from '../../../../utils/src/lib/image/image.module';
import {NumberOfDayComponent} from './number-of-day.component';

@NgModule({
  imports: [CommonModule, RouterModule, ImageModule],
  declarations: [NumberOfDayComponent],
  exports: [NumberOfDayComponent],
})
export class NumberOfDayModule {}
