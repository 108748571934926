import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {HttpClient} from '@angular/common/http';

declare global {
  interface Window {
    Ya: any;
    yaContextCb: any;
  }
}

@Component({
  selector: 'app-adfox-top',
  templateUrl: './adfox-top.component.html',
  styleUrls: ['./adfox-top.component.scss'],
})
export class AdFoxComponent implements OnInit {
  adblock = false;
  bannerLoaded;
  constructor(
    private detectedScreenChangeService: DetectedScreenChangeService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    private http: HttpClient
  ) {}

  async checkAdBlock() {
    try {
      await this.http.head('https://yandex.ru/ads/system/header-bidding.js').toPromise();
      return false;
    } catch (e) {
      return true;
    }
  }

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.adblock = await this.checkAdBlock();
      if (this.adblock) {
        this.bannerLoaded = true;
        return;
      }
      const DESK1 = {
        pp: 'brlu',
        ps: 'fddk',
        p2: 'y',
      };

      const MOB1 = {
        pp: 'brlu',
        ps: 'fddk',
        p2: 'hiho',
      };

      const onRender = () => {
        this.bannerLoaded = true;
        const addFoxContent = document.querySelector('#adfox-top-content') as HTMLElement;
        addFoxContent.style.maxHeight = '300px';
        addFoxContent.style.opacity = '1';
      };
      if (this.detectedScreenChangeService.getScreenType() === 'mobile') {
        window.yaContextCb.push(() => {
          window.Ya.adfoxCode.create({
            ownerId: 388377,
            containerId: 'adfox-top-content',
            params: MOB1,
            onRender,
          });
        });
      } else {
        window.yaContextCb.push(() => {
          window.Ya.adfoxCode.createAdaptive(
            {
              ownerId: 388377,
              containerId: 'adfox-top-content',
              params: DESK1,
              onRender,
            },
            ['desktop', 'tablet'],
            {
              tabletWidth: 760,
              phoneWidth: 320,
              isAutoReloads: true,
            }
          );
        });
      }
    }
  }

  @HostListener('window:resize')
  checkMargins() {
    const addFoxContent = document.querySelector('#adfox-top-content') as HTMLElement;
    if (!addFoxContent) return;
    const screenType = this.detectedScreenChangeService.getScreenType();
    if (screenType === 'sMobile' || screenType === 'mobile') {
      addFoxContent.style.marginBottom = '8px';
      addFoxContent.style.marginTop = '0px';
    } else {
      addFoxContent.style.marginTop = '10px';
      addFoxContent.style.marginBottom = '0px';
    }
  }
}
