import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {AdScriptService} from '@thebell/common/services/others/ad-script';
import {RepeatableLinesExistsService} from '@thebell/common/services/others/repeatable-lines-exists';
import {Subscription} from 'rxjs';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {FullscreenAdService} from '@thebell/common/services/utils/fullscreen-ad';
import {SuperfeatureAdService} from '@thebell/common/services/utils/superfeature-ad';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [FullscreenAdService, SuperfeatureAdService],
})
export class LayoutComponent implements OnInit, OnDestroy {
  title = 'TheBell';
  isMain = false;
  isError = false;
  isFooterVisible = false;
  repeatableLines: Subscription;
  is404: boolean;
  isDonates: boolean;
  isAdditionalPage: boolean;
  constructor(
    private router: Router,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    private adScript: AdScriptService,
    public repeatableLinesExists: RepeatableLinesExistsService,
    private detectedScreenChangeService: DetectedScreenChangeService,
    private fullscreenAdService: FullscreenAdService,
    private superfeatureAdService: SuperfeatureAdService,
    private transferState: TransferState
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isMain = event.urlAfterRedirects === '/';
        this.isError = event.urlAfterRedirects.startsWith('/404') || this.is404;
        this.isDonates =
          event.urlAfterRedirects === '/donate' ||
          event.urlAfterRedirects === '/subscriptions' ||
          event.urlAfterRedirects === '/order-processing' ||
          event.urlAfterRedirects === '/thank-you-page';
        this.isAdditionalPage =
          event.urlAfterRedirects === '/privacy-policy' ||
          event.urlAfterRedirects === '/about' ||
          event.urlAfterRedirects === '/team';
      }
    });
  }

  ngOnInit() {
    this.repeatableLines = this.repeatableLinesExists.get$().subscribe((res) => {
      this.isFooterVisible = !res;
    });
    this.adScript.setScripts();
    if (isPlatformBrowser(this.platformId)) {
      this.is404 = this.transferState.get(makeStateKey('is404'), false);
      if (this.is404) {
        this.transferState.set(makeStateKey('is404'), false);
        this.router.navigateByUrl('/404', {skipLocationChange: true});
      }
      const screenType = this.detectedScreenChangeService.getScreenType();
      this.fullscreenAdService.init(screenType === 'desktop' || screenType === 'desktopRetina' ? 'desktop' : 'mobile');
      this.superfeatureAdService.init(
        screenType === 'desktop' || screenType === 'desktopRetina' ? 'desktop' : 'mobile'
      );
      this.detectedScreenChangeService.screenTypeChanged.subscribe((type) => {
        this.fullscreenAdService.init(type === 'desktop' || type === 'desktopRetina' ? 'desktop' : 'mobile');
        this.superfeatureAdService.init(type === 'desktop' || type === 'desktopRetina' ? 'desktop' : 'mobile');
      });
    }
  }

  get showFooter() {
    return (this.isFooterVisible && this.isMain) || this.isAdditionalPage;
  }

  ngOnDestroy() {
    this.repeatableLines?.unsubscribe();
  }
}
