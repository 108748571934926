<div class="share-buttons">
  <a
    target="_blank"
    (click)="onClick('telegram')"
    href="https://t.me/share/url?url={{ url }}&text={{ text }}"
    class="share-item"
  >
    <app-author-social [slug]="'telegram'"></app-author-social>
  </a>
  <a
    target="_blank"
    (click)="onClick('twitter')"
    href="https://twitter.com/share?url={{ url }}&text={{ text }}"
    class="share-item"
  >
    <app-author-social [slug]="'twitter'"></app-author-social>
  </a>
  <a target="_blank" (click)="onClick('vk')" href="https://vk.com/share.php?url={{ url }}" class="share-item">
    <app-author-social [slug]="'vk'"></app-author-social>
  </a>
</div>
