import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {ActualBannerDto, Banner} from '@thebell/data-transfer-objects';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PostBannerService {
  get actualBanner$(): Observable<Partial<Banner>> {
    return this.getActualBanners();
  }

  constructor(private apollo: Apollo) {}

  getActualBanners() {
    return this.apollo
      .query<ActualBannerDto>({
        query: gql`
          query getActual {
            actualBanner {
              content
              id
            }
          }
        `,
        fetchPolicy: 'network-only',
        // pollInterval: 500,
      })
      .pipe(map((res) => (res.data ? res.data.actualBanner : null)));
  }
}
