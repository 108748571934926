import {Asset} from '@thebell/common/models/asset';
import {Category} from '@thebell/common/models/category';
import {Tag} from '@thebell/common/models/tag';
import {Author} from '@thebell/common/models/author';
import {Position} from '@thebell/common/models/position';
import {SafeHtml} from '@angular/platform-browser';
import {BaseWidget} from '@thebell/common/models/widget-style-data';
import {WidgetStyle} from '@thebell/common/models/widget-style';
import {WidgetTypes} from '@thebell/common/models/widget-types';
import {Source} from '@thebell/data-transfer-objects';

export class Post extends BaseWidget {
  id: number; //
  wp_posts_id: number;
  type: string;
  title: string; //
  slug: string;
  subtitle: string;
  status: string;
  author: string; //
  content: string; //
  content_html: SafeHtml; //
  created_at: Date;
  updated_at: Date; //
  assets: Asset[] = [];
  positions: Position[] = [];
  url: string;
  blank: boolean;
  image: string;
  category_id: number;
  categories: Category[] = [];
  tags: Tag[] = [];
  authors: Author[] = [];
  lang: string;
  publish_date: Date;
  modified_date: Date;
  preset_item_id?: any;
  widget_style: WidgetStyle;
  widget_style_data: WidgetTypes;
  allowed_widget_sizes = null;
  show_on_main = 1;
  no_ad = false;
  source?: Source;
  source_link?: string;
  allowed?: boolean;
}

export class CollapsePost extends Post {}

export interface IPostSubject {
  postHeight: number;
  screenType: 'desktop' | 'other';
}

export interface IPost {
  id: number;
  title: string;
  status: 'Опубликована' | 'Черновик' | 'Отложена' | '';
  author: string;
  authors: Author[];
  created_at: string;
  // ToDo: убрать
  public_at: string;
  published_at: string;
}

export type ToolType = 'edit' | 'delete' | 'link';
export type FilterObj = Omit<IPost, 'id'>;
export type FilterType = keyof FilterObj;
