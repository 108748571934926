import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Component, Input, OnInit} from '@angular/core';
import {Author} from '@thebell/common/models/author';
import {Asset, AssetRoles} from '@thebell/common/models/asset';
import {ImageVersion} from '@thebell/data-transfer-objects';

@Component({
  selector: 'app-widget-author',
  templateUrl: './widget-author.component.html',
  styleUrls: ['./widget-author.component.scss'],
})
export class WidgetAuthorComponent implements OnInit {
  @Input() author: Author;
  @Input() showEmail = true;
  @Input() showDescription = true;
  @Input() avatarClass = 'author__content_image';
  @Input() nameClass = 'name';
  @Input() emailClass = 'email';
  @Input() imageResizable = true;
  email = '';
  description = '';
  description_html: SafeHtml;
  avatars: Asset[] | string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.avatars = (() => {
      const imageFromAsset = this.author.assets
        ?.filter((asset) => asset.role === AssetRoles.author_image)
        .sort((a, b) => a.width - b.width);
      const imageFromObject = this.author.author_image?.src ? [this.author.author_image] : this.author.author_image;
      return imageFromObject || imageFromAsset;
    })();
    if (Array.isArray(this.avatars) && this.avatars.length > 1) {
      this.avatars = [this.avatars.shift()];
    }

    this.email = this.author.contacts && 'email' in this.author.contacts ? this.author.contacts.email : '';
    const regex = new RegExp(
      // eslint-disable-next-line no-useless-escape
      /([a-zA-Z]+:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/
    );
    this.description = this.author.description_ru.replace(
      regex,
      // eslint-disable-next-line
      '<a target="_blank" style="color: #000000; text-decoration: none; padding: 0 0.3em" href="$1">$1</a>'
    );
    this.description_html = this.sanitizer.bypassSecurityTrustHtml(this.description);
  }
}
