import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonUiUtilsModule} from '@thebell/common/ui/utils';
import {BottomStickyDialogComponent} from './bottom-sticky-dialog.component';
import {SubscriptionContentComponent} from './contents/subscription/subscription-content.component';
import {PollContentComponent} from './contents/poll/poll-content.component';
import {StickyBannerComponent} from './contents/sticky-banner/sticky-banner.component';

@NgModule({
  imports: [CommonModule, CommonUiUtilsModule, ReactiveFormsModule],
  declarations: [
    BottomStickyDialogComponent,
    SubscriptionContentComponent,
    PollContentComponent,
    StickyBannerComponent,
  ],
  exports: [BottomStickyDialogComponent],
})
export class BottomStickyDialogModule {}
