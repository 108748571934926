import {Translation} from 'vanilla-cookieconsent';
import {Category} from '../../contracts/types';

const ru: Translation = {
  consentModal: {
    acceptAllBtn: 'Принять все',
    acceptNecessaryBtn: 'Только обязательные',
    showPreferencesBtn: 'Настройки',
  },
  preferencesModal: {
    title: 'Настройки файлов cookie',
    acceptAllBtn: 'Принять все',
    acceptNecessaryBtn: 'Отклонить все',
    savePreferencesBtn: 'Сохранить мои предпочтения',
    closeIconLabel: 'Закрыть',
    serviceCounterLabel: 'Сервис|Сервисы',
    sections: [
      {
        title: 'Ваша конфиденциальность важна для нас',
        description:
          'Куки - это небольшие текстовые файлы, которые сохраняются на компьютере, когда вы посещаете сайт. ' +
          'Вы можете ознакомиться с нашей Политикой конфиденциальности для получения дополнительной информации.',
      },
      {
        title: 'Необходимые<span class="pm__badge">всегда активны</span>',
        description: 'Эти файлы cookie необходимы для корректной работы сайта.',
        linkedCategory: Category.Necessaries,
      },
      {
        title: 'Аналитические',
        description:
          'Эти файлы cookie помогают анонимно собирать информацию о вашем взаимодействии с сайтом. ' +
          'Такие данные помогают лучше понимать нашу аудиторию и улучшать ваш пользовательский опыт.',
        linkedCategory: Category.Analytics,
      },
      {
        title: 'Рекламные',
        description:
          'Эти файлы cookie используются рекламными агентствами для отображения объявлений, более соответствующим вашим интересам.',
        linkedCategory: Category.Ads,
      },
    ],
  },
};

export default ru;
