import {Injectable} from '@angular/core';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';

@Injectable({
  providedIn: 'root',
})
export class UrlBuilderService {
  constructor(private detectedScreenChangeService: DetectedScreenChangeService) {}

  buildUrl(url: string, screenType: string, isResizable: boolean) {
    if (isResizable) {
      const width = '-w' + this.detectedScreenChangeService.getScreenWidth(screenType).toString();
      return url.substring(0, url.lastIndexOf('.')) + width + url.substring(url.lastIndexOf('.'));
    } else {
      return url;
    }
  }
}
