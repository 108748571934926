import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {EnvironmentService} from '@thebell/common/services/core/environment';

interface ICaches {
  advertisementProviders?: Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class AdvertisementPostsService {
  private caches$: ICaches = {};
  constructor(private envService: EnvironmentService, private http: HttpClient) {}

  getAdvertisementsProviders(): Observable<any> {
    const {apiUrl} = this.envService.getEnvironment();
    if (!this.caches$.advertisementProviders) {
      this.caches$.advertisementProviders = this.http.get<any>(`${apiUrl}ad_providers`).pipe(shareReplay());
    }
    return this.caches$.advertisementProviders;
  }
}
