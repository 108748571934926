import {Component, Input} from '@angular/core';
import {Asset} from '@thebell/common/models/asset';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() image: string | Asset[];
  @Input() imageClass = '';
  @Input() isResizable = true;
  @Input() imageType = '';
  @Input() addition = {};
  @Input() width: number;
  @Input() height: number;
  srcSetData: {
    image: string;
    media: string;
  }[];
  imgSrc = '';
  defaultSrc = '';

  setImgSrc(image: string) {
    this.imgSrc = image;
  }

  setSrcSet(set) {
    this.srcSetData = set;
    if (!this.srcSetData.length) {
      return;
    }
    const value = this.srcSetData[Math.floor(this.srcSetData.length / 2)].image;
    this.defaultSrc = (() => {
      if (value?.length) {
        return value.split(',')[0].split(' ')[0].trim();
      }
      return '';
    })();
  }
}
