import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiAdminService} from '@thebell/admin/services/api/api-admin';
import {ApiClientService} from '@thebell/common/services/api/api-client';
import {BaseModel} from '@thebell/common/models/base-model';
import {LayoutThemeData} from '@thebell/common/models/layout-theme-data';
import {ThemeDataRequestOptions} from '@thebell/common/models/theme-data-request-options';

@Injectable({
  providedIn: 'root',
})
export class LayoutThemeDataService {
  private caches$: Observable<LayoutThemeData[][]>[] = [];

  constructor(private apiClientService: ApiClientService, private apiAdminService: ApiAdminService) {}

  public getLayoutThemeData(options: ThemeDataRequestOptions): Observable<LayoutThemeData[][]> {
    const cacheKey = JSON.stringify(options);
    if (!this.caches$[cacheKey]) {
      this.caches$[cacheKey] = this.apiClientService.layoutThemeData(options);
    }
    return this.caches$[cacheKey];
  }

  public getAdminLayoutThemeData(options: ThemeDataRequestOptions): Observable<BaseModel<LayoutThemeData[][]>> {
    return this.apiAdminService.layoutThemeData(options);
  }
}
