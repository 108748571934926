import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PaywallModalComponent} from '../paywall-banner-modal/paywall-modal.component';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-paywall',
  templateUrl: './paywall.component.html',
  styleUrls: ['./paywall.component.scss'],
})
export class PaywallComponent {
  @Input() postSlug: string;
  @Input() isUserAuthorized: boolean;

  constructor(private dialog: MatDialog, private cookieService: CookieService) {}

  setSuccessLinkAndNavigate(url: string) {
    this.setSuccessLink();
    window.location.href = url;
  }

  setSuccessLink() {
    // ga('send', 'event', 'invest', 'snippet_subscribe', this.postSlug);
    // dataLayer.push({
    //   'event': 'paywall_snippet_subscribe', 'category': 'new_paywall'
    // });
    const expires = new Date(new Date().getTime() + 30 * 60000);
    this.cookieService.set('success-invest-paid-link', this.postSlug, expires);
  }

  alreadySub(): void {
    // dataLayer.push({
    //   'event': 'invest_paywall_snippet_already_subscriber', 'category': 'invest'
    // });
    this.dialog.open(PaywallModalComponent, {
      width: '390px',
      backdropClass: 'modal-invest-wrapper',
      panelClass: 'modal-invest-panel',
      data: {postSlug: this.postSlug},
    });
  }
}
