import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FeaturesService} from '@thebell/common/services/others/features';
import {CookieService} from 'ngx-cookie-service';
import {COOKIE_CONSTANTS, StickyBannerCookie} from '@thebell/constants';
import {StickyBannerService} from '../../services/sticky-banner.service';
import {isPlatformServer} from '@angular/common';

@Component({
  selector: 'app-bottom-sticky-dialog',
  templateUrl: './bottom-sticky-dialog.component.html',
  styleUrls: ['./bottom-sticky-dialog.component.scss'],
})
export class BottomStickyDialogComponent implements AfterViewInit, OnInit {
  @Output() hide = new EventEmitter<boolean>();
  contentType: string | boolean;
  show = false;
  cookieItem: StickyBannerCookie;
  subscriptionForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private featureService: FeaturesService,
    private cookieService: CookieService,
    private stickyBannerService: StickyBannerService,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    @Inject('localStorage') private localStorage: any
  ) {}
  ngOnInit() {
    if (isPlatformServer(this.platformId)) return;
    this.cookieItem = new StickyBannerCookie(this.cookieService.get(COOKIE_CONSTANTS.BOTTOM_STICKY));
    this.resolvePopUp();
    let periodicity = 0;
    switch (this.cookieItem.show()) {
      // case 'subscribe':
      //   periodicity = 0;
      //   this.resolveSubOrBanner(periodicity);
      //   break;
      case 'banner':
        periodicity = 100;
        this.resolveSubOrBanner(periodicity);
        break;
      //case 'random':
      // idle, that case in ngAfterViewInit
    }
  }

  async ngAfterViewInit() {
    if (isPlatformServer(this.platformId)) return;
    // if (this.cookieItem.show() === 'random') {
    //   const periodicity = await this.stickyBannerService.getStickyBanner();
    //   this.resolveSubOrBanner(periodicity);
    // }
  }

  resolvePopUp() {
    const show = this.cookieItem.show();
    if (show !== 'none') {
      setTimeout(() => {
        this.show = true;
      }, 1000);
    }
  }

  /**
   * @param value chance for show banner
   */
  resolveSubOrBanner(value: number) {
    this.contentType = 'banner';
    // if (value === 0) this.contentType = 'subscription';
    // else if (value === 100) this.contentType = 'banner';
    // else {
    //   const seed = Math.random() * 100;
    //   this.contentType = value >= seed ? 'banner' : 'subscription';
    // }
  }

  onHide() {
    this.show = false;
  }

  onClose() {
    console.log('bottom ', this.contentType)
    switch (this.contentType) {
      case 'subscription':
        this.cookieItem.save('subscribe');
        ga('send', 'event', 'mail_popup', 'mail_popup_close');
        dataLayer.push({
          'event': 'mail_popup_close', 'category': 'newsletter'
        });
        break;
      case 'poll':
        ga('send', 'event', 'popup', 'inschool_close');
        dataLayer.push({
          'event': 'event', 'eventCategory': 'popup', 'eventAction': 'inschool_close'
        });
        break;
      case 'banner':
        this.cookieItem.save('banner');
        break;
    }
    this.onHide();
    // localStorage.setItem('inschool_sticky_dialog_shown_', 'true');
    this.cookieService.set(COOKIE_CONSTANTS.BOTTOM_STICKY, this.cookieItem.toString(), 14);
  }
}
