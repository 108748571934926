import {ApplicationRef, enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {enableDebugTools} from '@angular/platform-browser';
import {environment} from './environments/environment';
import {sentryInit} from '@thebell/sentry';
import {Router, NavigationEnd} from '@angular/router';

// Инициализация Sentry
sentryInit({dsn: environment.sentryDSN, tracingOrigins: environment.sentryTracingOrigins});


// Включение режима продакшн, если это продакшн сборка
if (environment.production) {
  enableProdMode();
}

let isScroll25Percent = false;
let isScroll50Percent = false;
let isScroll75Percent = false;
let isScroll100Percent = false;

// Функция для проверки внешних ссылок
function isExternalLink(element: HTMLElement): string | false {
  // Проверяем, является ли сам элемент ссылкой
  if (element.tagName === 'A') {
    const link = element as HTMLAnchorElement;
    // В данном примере, считаем все ссылки с другими доменами внешними
    const currentDomain = window.location.hostname;
    const linkDomain = link.hostname;
    if (linkDomain !== currentDomain) {
      return link.href;
    }
  } else if (element.tagName === 'THETRUESTORY-WIDGET-TOP') {
    // Заглушка для виджета trueStory, в котором не получается получать ссылку из-за Shadow DOM
    return 'true-story-widget-click';
  }

  // Проверяем дочерние элементы, включая наследников, например, <img> внутри <a>
  let currentElement: HTMLElement | null = element;
  while (currentElement) {
    if (currentElement.tagName === 'A') {
      const link = currentElement as HTMLAnchorElement;
      // В данном примере, считаем все ссылки с другими доменами внешними
      const currentDomain = window.location.hostname;
      const linkDomain = link.hostname;
      if (linkDomain !== currentDomain) {
        return link.href;
      }
    }
    currentElement = currentElement.parentElement;
  }
  return false;
}

// Функция для определения процента пролистанной части страницы
function getScrollPercentage() {
  const scrollTop = window.scrollY;
  const scrollHeight = document.documentElement.scrollHeight;
  const clientHeight = document.documentElement.clientHeight;
  return (scrollTop / (scrollHeight - clientHeight)) * 100;
}

// Сброс флагов прокрутки
function resetScrollFlags() {
  isScroll25Percent = false;
  isScroll50Percent = false;
  isScroll75Percent = false;
  isScroll100Percent = false;
}

document.addEventListener('DOMContentLoaded', () => {
  //console.log('ДОБАВИЛИ СЛУШАТЕЛЬ')
  platformBrowserDynamic([{provide: 'localStorage', useValue: window.localStorage}])
    .bootstrapModule(AppModule)
    .then((ref) => {
      const applicationRef = ref.injector.get(ApplicationRef);
      const appComponent = applicationRef.components[0];
      enableDebugTools(appComponent);

      // Получаем экземпляр Router после того, как приложение было запущено
      const router: Router = ref.injector.get(Router);

      // Добавляем слушатель события маршрутизации
      router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Сброс флагов прокрутки при переходе на новую страницу
          resetScrollFlags();
        }
      });
    })
    .catch((err) => console.error(err));

  document.addEventListener('click', (event) => {
    const targetElement = event.target as HTMLElement;
    const externalLink = isExternalLink(targetElement);
    if (externalLink) {
      //console.log(dataLayer);
      dataLayer.push({event: 'outbound_click', category: 'outbound_link', label: externalLink});
      //console.log(dataLayer);
    }
  });

  // Добавляем слушатель скролла
  window.addEventListener('scroll', () => {
    const scrollPercentage = getScrollPercentage();
    if (!isScroll25Percent && scrollPercentage > 25) {
      //console.log(dataLayer);
      dataLayer.push({event: 'page_scroll', category: 'scroll', label: '25%'});
      //console.log('25% scrolled');
      //console.log(dataLayer);
      isScroll25Percent = true;
    }
    if (!isScroll50Percent && scrollPercentage > 50) {
      dataLayer.push({event: 'page_scroll', category: 'scroll', label: '50%'});
      //console.log('50% scrolled');
      isScroll50Percent = true;
    }
    if (!isScroll75Percent && scrollPercentage > 75) {
      dataLayer.push({event: 'page_scroll', category: 'scroll', label: '75%'});
      //console.log('75% scrolled');
      isScroll75Percent = true;
    }
    if (!isScroll100Percent && scrollPercentage === 100) {
      dataLayer.push({event: 'page_scroll', category: 'scroll', label: '100%'});
      //console.log('100% scrolled');
      isScroll100Percent = true;
    }
  });
});
