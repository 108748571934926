import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ColorService} from '@thebell/common/services/utils/color';
import {HelperService} from '@thebell/common/services/utils/helper';
import {WIDGET_DATA} from '@thebell/common/injection-tokens/widget-data';
import {News} from '@thebell/common/models/widget-style-data';
import {Asset, AssetRoles} from '@thebell/common/models/asset';
import {GaEventsService} from '@thebell/common/services/others/ga-events';
import {additionalCategory} from '@thebell/common/models/additional-category';
import {AdditionalCategoryService} from '@thebell/common/services/utils/additional-category/additinal-category';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {ImageVersion} from '@thebell/data-transfer-objects';

@Component({
  selector: 'app-widget-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsComponent implements OnInit {
  @Input() data: {data: News; basis: string; lineMaxBasis: string; srcData: any; is_special: number; small?: boolean};

  categoryClass = 'category';
  textClass = 'text';
  timeClass = 'time';
  image: string | Asset | Asset[] = '';
  imageType = '';
  title = '';
  category = '';
  categorySlug = '';
  slug = '';
  type = '';
  date: string;
  basis: number;
  small = false;
  isVstrechiBellclub = false;
  additionalCategory: additionalCategory;
  advertising = false;
  advertising_image: Asset | string | null;
  resizable = true;

  constructor(
    @Inject(WIDGET_DATA)
    private dataInject: {
      data: News;
      basis: string;
      lineMaxBasis: string;
      srcData: any;
      is_special: number;
      small: boolean;
    },
    private helperService: HelperService,
    private colorService: ColorService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private ga: GaEventsService,
    private screen: DetectedScreenChangeService,
    private adCatService: AdditionalCategoryService
  ) {}

  inAdmin = () => this.helperService.inAdmin();

  ngOnInit(): void {
    this.data = this.data || this.dataInject;
    this.basis = +this.data.basis;
    this.categoryClass += ' category-' + this.data.basis;
    this.textClass += ' text-' + this.data.basis;
    this.timeClass += ' time-' + this.data.basis;
    this.category = this.data.data.category?.title ?? '';
    this.categorySlug = this.data.data.category?.slug;
    this.title = this.data.data.title;
    this.date = this.data.data.date;
    this.slug = this.data.data?.slug;
    this.type = this.data.data.type;
    this.small = this.data.small;
    this.advertising = this.data.data.advertising;
    this.advertising_image = this.data.data.advertising_image;
    this.additionalCategory = this.data.data.additionalCategory;
    if (this.data.data.background_image) this.resizable = false; // Пока с ассетами затуп
    if (
      ((this.data.data.background_assets && this.data.data.background_assets.length !== 0) ||
        this.data.data.background_image) &&
      !(!this.data.lineMaxBasis && this.basis <= 25) &&
      (!this.data.lineMaxBasis || parseInt(this.data.lineMaxBasis, 10) > 25) &&
      !this.small
    ) {
      this.image = this.data.data.background_assets || this.data.data.background_image;
      this.imageType = 'background-shadow';
      this.textClass += ' width-image';
      this.categoryClass += ' width-image';
      this.timeClass += ' width-image';
    } else if (this.data.data.background && !this.small) {
      this.image = this.data.data.background;
      this.imageType = 'background-color';
      if ((this.colorService.colorIsRed(this.image) || this.colorService.colorIsBlue(this.image)) && this.basis > 25) {
        this.textClass += ' white';
        this.categoryClass += ' white';
        this.timeClass += ' white';
      }
    } else {
      this.image = '#FFFFFF';
      this.imageType = 'background-color';
    }
    this.isVstrechiBellclub = this.router.url.startsWith('/vstrechi-bellclub') || this.type === 'page';

    const mainImageAsBackground = this.data?.data?.main_image_as_background || false;
    const assets = this.data?.srcData?.data?.item?.assets || [];
    const role = mainImageAsBackground ? AssetRoles.main_image : AssetRoles.widget_NewsComponent_background_image;
    const assetBackgroungImage = assets.find((asset) => asset.role === role);

    if (
      assetBackgroungImage &&
      assetBackgroungImage.version === ImageVersion.UPLOADED_BY_NEST &&
      this.imageType !== 'background-color'
    ) {
      this.image = [assetBackgroungImage];
    }

    this.cdr.markForCheck();
  }

  getAdditionClass(category: additionalCategory) {
    if (this.screen.getScreenType() === 'mobile') {
      return category + ' mobile';
    }
    if (this.data.basis === '50') return category + ' b50';
    return category + ' b33';
  }

  getImgPath(category: additionalCategory) {
    return '/assets/images/exclusives/' + this.adCatService.getAdCatSpec(category).svg;
  }

  onClick() {
    if (this.data.is_special) {
      this.ga.mainPageWidgetClickSpecial(this.data.srcData.slug, this.data.data.slug, this.basis);
    } else if (this.helperService.isMainPage(this.router.url)) {
      ga('send', 'event', 'main_page', 'main_page_widget_click', `${this.basis}%`);
      dataLayer.push({
        'event': 'event', 'eventCategory': 'main_page', 'eventAction': 'main_page_widget_click', 'eventLabel': `${this.basis}%`
      });
    }
  }

  // Дополнительные категории показываются только на виджетах с картинкой
  additionalCategoryResolver(cat: additionalCategory): 'tag' | 'asset' | '' {
    // проверка 2 на то, что в поле ассет, а не цвет
    if (this.additionalCategory && (typeof this.image !== 'string' || !this.image.includes('#'))) {
      if (cat === additionalCategory.exclusive) return 'tag';
      else return 'asset';
    }
    return '';
  }

  get advertisingIsAvailable() {
    return this.advertising && this.advertising_image && !this.colorService.valueIsColor(this.image as string);
  }

  getLink(category: additionalCategory): string {
    return this.adCatService.getAdCatSpec(category).link;
  }

  getText(category: additionalCategory): string {
    return this.adCatService.getAdCatSpec(category).name;
  }
}
